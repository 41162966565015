import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import Typography from "@mui/material/Typography";

import {
  useGetCategoriesQuery,
  useGetCategoryTreesQuery,
} from "../../Store/api/datastore/categories/categoriesApi";
import {
  getCategoryOptions,
  getCategoryValues,
  getSubCategoryOptions,
  hasValidData,
} from "./CategoriesDropdown.utils";
import { CategoryOption, CategoryType } from "./interfaces";
import { DashboardDispatch } from "../../../../../views/Private/Home";
import { useGetTransactionByIdQuery } from "../../Store/api/transactions/transactionsApi";
import { useParams } from "react-router-dom";
import { useGetClientByIdQuery } from "../../Store/api/clients/clientsApi";
import { Chip } from "@mui/material";

interface CategoriesDropdownProps {
  selectedCategoryIds: string[];
  handleCategoriesSelect: (categories: CategoryOption[], type: CategoryType) => void;
  categoriesError?: string;
  selectedSubCategoryIds?: string[];
  subCategoriesError?: string;
  withSubcategories: boolean;
  initial: boolean;
  setInitial: (value: boolean) => void;
}

export const CategoriesDropdown = ({
  selectedCategoryIds = [],
  handleCategoriesSelect,
  selectedSubCategoryIds = [],
  withSubcategories,
  initial,
  setInitial,
}: CategoriesDropdownProps) => {
  const dashboardDispatch = useContext(DashboardDispatch);
  const { t } = useTranslation();

  const params = useParams<{ transactionId: string }>();

  const transactionId = params.transactionId;
  const transactionByIdQuery = useGetTransactionByIdQuery({
    transactionId: transactionId ? transactionId : "",
  });
  const transaction = transactionByIdQuery.data;

  const clientByIdQuery = useGetClientByIdQuery({
    clientId: transaction ? transaction.client_id : "",
  });
  const client = clientByIdQuery.currentData;

  const categoriesQuery = useGetCategoriesQuery();
  const categoryTreesQuery = useGetCategoryTreesQuery();

  const categories = categoriesQuery.data;
  const categoryTrees = categoryTreesQuery.data;

  const categoryOptions = getCategoryOptions(
    categories,
    hasValidData(selectedCategoryIds) ? selectedCategoryIds : []
  );

  const categoryValues =
    hasValidData(selectedCategoryIds) && categories
      ? getCategoryValues(selectedCategoryIds, categories)
      : [];

  const subCategoryOptions = useMemo(
    () =>
      selectedSubCategoryIds
        ? getSubCategoryOptions(
            selectedCategoryIds,
            selectedSubCategoryIds,
            categoryTrees ? categoryTrees : [],
            categories ? categories : []
          )
        : [],
    [categories, categoryTrees, selectedCategoryIds, selectedSubCategoryIds]
  );

  const [subCategoryValues, setSubCategoryValues] = useState<CategoryOption[]>([]);
  // useEffect(() => {
  //   if (selectedSubCategoryIds && hasValidData(selectedSubCategoryIds) && categories) {
  //     const initialSubCategoryValues = getCategoryValues(selectedSubCategoryIds, categories);
  //     setSubCategoryValues(initialSubCategoryValues);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selectedSubCategoryIds, categories]);

  // Helper function to compare arrays for equality
  function arrayEquality(arr1: any[], arr2: string | any[]) {
    if (arr1.length !== arr2.length) return false;
    return arr1.every((value, index) => value === arr2[index]);
  }

  useEffect(() => {
    if (selectedSubCategoryIds && hasValidData(selectedSubCategoryIds) && categories) {
      const initialSubCategoryValues = getCategoryValues(selectedSubCategoryIds, categories);
      if (!arrayEquality(initialSubCategoryValues, subCategoryValues)) {
        setSubCategoryValues(initialSubCategoryValues);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSubCategoryIds, categories]);

  useEffect(() => {
    if (!client) return;

    const registered = client.subscription.product_id === "company" ? "self" : "advisor";

    // we need this data to seperate company (self) or advisor registration it is.
    // accordingly process flow tree will change in StatusprocessFlowTable component
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        registered,
        registeredLoading: false,
      },
    });
  }, [client, dashboardDispatch, transaction?.client_id]);

  return (
    <>
      {withSubcategories && (
        <>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
            <FormControl fullWidth size="small">
              <Autocomplete
                multiple
                id="categories"
                freeSolo
                size="small"
                options={categoryOptions}
                //getOptionLabel={(option) => option.label}
                value={categoryValues || []}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <Typography style={{ whiteSpace: "normal" }} sx={{ fontSize: 14 }}>
                          {option.label}
                        </Typography>
                      }
                      {...getTagProps({ index })}
                      style={{ height: "100%" }}
                    />
                  ))
                }
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      required
                      label={t("categories_select.categories")}
                      inputProps={{ ...params.inputProps, readOnly: true }}
                      error={!initial && categoryValues.length === 0}
                      helperText={
                        !initial && categoryValues.length === 0 && t("misc.required_field")
                      }
                    />
                  );
                }}
                onChange={(__e, newValue) => {
                  setInitial(false);
                  const selectedOptions = newValue as CategoryOption[];
                  handleCategoriesSelect(selectedOptions, CategoryType.CATEGORY);
                }}
              />

              <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                {t("misc.multi_select")}
              </Typography>
            </FormControl>
          </Stack>
          <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }} sx={{ mt: 2 }}>
            <FormControl fullWidth size="small">
              <Autocomplete
                disabled={selectedCategoryIds.length === 0}
                multiple
                id="sub-categories"
                freeSolo
                size="small"
                value={subCategoryValues || []}
                options={subCategoryOptions}
                groupBy={(option) => (option.cat ? option.cat : "")}
                renderInput={(params) => {
                  return (
                    <TextField
                      {...params}
                      label="Subbranchen"
                      inputProps={{ ...params.inputProps, readOnly: true }}
                    />
                  );
                }}
                renderGroup={(params) => {
                  return (
                    <li key={uuidv4()}>
                      <Typography>{params.group}</Typography>
                      <ul>{params.children}</ul>
                    </li>
                  );
                }}
                onChange={(_e, newValue) => {
                  const selectedOptions = newValue as CategoryOption[];
                  handleCategoriesSelect(selectedOptions, CategoryType.SUB_CATEGORY);
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      label={
                        <Typography style={{ whiteSpace: "normal" }} sx={{ fontSize: 14 }}>
                          {option.label}
                        </Typography>
                      }
                      {...getTagProps({ index })}
                      style={{ height: "100%" }}
                      onDelete={() => {
                        const updatedValues = subCategoryValues.filter((item) => item !== option);
                        setSubCategoryValues(updatedValues);
                        handleCategoriesSelect(updatedValues, CategoryType.SUB_CATEGORY);
                      }}
                    />
                  ))
                }
              />
              <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                {t("misc.multi_select")}
              </Typography>
            </FormControl>
          </Stack>
        </>
      )}
      {!withSubcategories && (
        <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
          <FormControl fullWidth size="small">
            <Autocomplete
              multiple
              id="categories"
              freeSolo
              size="small"
              options={categoryOptions}
              //getOptionLabel={(option) => option.label}
              value={categoryValues || []}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    label={
                      <Typography style={{ whiteSpace: "normal" }} sx={{ fontSize: 14 }}>
                        {option.label}
                      </Typography>
                    }
                    {...getTagProps({ index })}
                    style={{ height: "100%" }}
                  />
                ))
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    required
                    label={t("categories_select.categories")}
                    error={!initial && categoryValues.length === 0}
                    helperText={!initial && categoryValues.length === 0 && t("misc.required_field")}
                    inputProps={{ ...params.inputProps, readOnly: true }}
                  />
                );
              }}
              onChange={(_e, newValue) => {
                setInitial(false);
                const selectedOptions = newValue as CategoryOption[];
                handleCategoriesSelect(selectedOptions, CategoryType.CATEGORY);
              }}
            />
            <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
              {t("misc.multi_select")}
            </Typography>
          </FormControl>
        </Stack>
      )}
    </>
  );
};
