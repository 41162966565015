import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { Card, LoadingIndicator } from "../";
import { Section } from "../Section/Section";
import { thousandSeparator, getValueFromMetadata } from "../../common";
import { Client } from "../../Store/api";
interface DetailsCompanyCardProps {
  clientData: Client;
  handleClientDataChange: (
    event:
      | React.ChangeEvent<{ value: string; name?: string | undefined }>
      | SelectChangeEvent<string>,
    field: string,
    isMetadata?: boolean
  ) => void;
  handleTradeRegistrationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  initial: boolean;
  setInitial: (value: boolean) => void;
}

export const DetailsCompanyCard = React.forwardRef(
  (
    {
      clientData,
      handleClientDataChange,
      handleTradeRegistrationChange,
      loading,
      initial,
      setInitial,
    }: DetailsCompanyCardProps,
    ref
  ) => {
    const { t } = useTranslation();

    // Country options for select "Land"
    // const countries: { [key: string]: string } = t("details_a3.company_card.countries", {
    //   returnObjects: true,
    // });
    const countries: { [key: string]: string } = t("company/common:countries_data", {
      returnObjects: true,
    });

    interface CountriesArrProps {
      code: string;
      name: string;
    }

    const countriesArr: CountriesArrProps[] = Object.keys(countries).map((code) => ({
      code,
      name: countries[code],
    }));

    const [inputWebsite, setInputdWebsite] = useState("");
    const [isInvalidUrl, setInvalidUrl] = useState(false);
    const [invalidFounderYear, setInvalidFounderYear] = useState(false);
    const isInvalidTradeRegistration =
      getValueFromMetadata("profile", "trade_registration", clientData?.metadata)?.includes(
        "HRB-"
      ) ||
      getValueFromMetadata("profile", "trade_registration", clientData?.metadata)?.includes("HR-");

    //checking the valid input website
    function isValidUrl(val: any) {
      const pattern = new RegExp(
        "^www\\.([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.(com|de|net|org|eu)(\\.([a-z]{2}))?$",
        "i"
      );
      return pattern.test(val);
    }
    useEffect(() => {
      const checkUrl = isValidUrl(inputWebsite);
      if (inputWebsite.length === 0) {
        setInvalidUrl(true);
      } else {
        setInvalidUrl(checkUrl);
      }
    }, [inputWebsite]);

    useEffect(() => {
      const checkUrl = isValidUrl(inputWebsite);
      if (inputWebsite.length === 0) {
        setInvalidUrl(true);
      } else {
        setInvalidUrl(checkUrl);
      }
    }, [inputWebsite]);

    //checking if the Founder year is valid with only positive numbers not later than the current year
    const handleFounderYearInput = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val = e.target.value;
      const yearVal = val.replace(/[^0-9]/g, "");

      const currentDate = new Date();
      const recentYear = currentDate.getFullYear();

      if (Number(yearVal) > recentYear) {
        setInvalidFounderYear(true);
      } else setInvalidFounderYear(false);

      e.target.value = yearVal;
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      handleFounderYearInput(e);
    };

    return (
      <Section title={t("details_a3.company_card.headline")} ref={ref}>
        <Card>
          {loading && <LoadingIndicator type={"COMPONENT"} />}
          {!loading && (
            <>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mb: 2 }}
              >
                <TextField
                  label={t("details_a3.company_card.company_name")}
                  name="name"
                  id="company_card_name"
                  value={clientData?.name || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "name")}
                  inputProps={{
                    autoComplete: "new-company",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("details_a3.company_card.trade_registration")}
                  name="trade_registration"
                  id="company_card_trade_registration"
                  value={
                    getValueFromMetadata("profile", "trade_registration", clientData?.metadata) ||
                    ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => {
                    handleTradeRegistrationChange(e as React.ChangeEvent<HTMLInputElement>);
                    setInitial(false);
                  }}
                  helperText={
                    !isInvalidTradeRegistration && !initial
                      ? t("error.wrong_trade_registration_format")
                      : undefined
                  }
                  inputProps={{
                    autoComplete: "new-trade_registration",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ my: 2 }}
              >
                <TextField
                  label={t("details_a3.company_card.street")}
                  name="line_1"
                  id="company_card_line_1"
                  value={clientData?.address?.line_1 || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "address")}
                  inputProps={{
                    autoComplete: "new-street",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                />
                <TextField
                  label={t("details_a3.company_card.postal_code")}
                  name="postal"
                  id="company_card_postal"
                  value={
                    clientData?.address?.country === "DEU"
                      ? (clientData?.address?.postal || "").substring(0, 5)
                      : clientData?.address?.postal || ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "address")}
                  inputProps={{
                    autoComplete: "new-postal",
                    form: {
                      autoComplete: "off",
                    },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: clientData?.address?.country === "DEU" ? 5 : undefined,
                    onInput: (e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "");
                    },
                  }}
                />
              </Stack>
              <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                <TextField
                  label={t("details_a3.company_card.city")}
                  name="city"
                  id="company_card_city"
                  value={clientData?.address?.city || ""}
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "address")}
                  inputProps={{
                    autoComplete: "new-city",
                    form: {
                      autoComplete: "off",
                    },
                    onInput: (e) => {
                      e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z]/g, "");
                    },
                  }}
                />
                <FormControl fullWidth size="small">
                  <InputLabel id="country">{t("details_a3.company_card.country")}</InputLabel>
                  <Select
                    labelId="country"
                    id="company_card_country"
                    name="country"
                    value={clientData?.address?.country || ""}
                    label={t("details_a3.company_card.country")}
                    onChange={(e) => handleClientDataChange(e, "address")}
                    MenuProps={{
                      PaperProps: {
                        style: { marginTop: "0.1%", maxHeight: "85vh" },
                      },
                    }}
                  >
                    {clientData?.address?.country &&
                      countriesArr.map((country) => (
                        <MenuItem key={country.code} value={country.code}>
                          {country.name}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Stack>

              <Stack
                direction={{ xs: "column", md: "row" }}
                spacing={{ xs: 2, md: 4 }}
                sx={{ mt: 2 }}
              >
                <TextField
                  label={t("details_a3.company_card.founding_year")}
                  name="foundation"
                  id="company_card_foundation"
                  value={getValueFromMetadata("profile", "foundation", clientData?.metadata) || ""}
                  size="small"
                  fullWidth
                  onInput={(e: React.ChangeEvent<HTMLInputElement>) => handleFounderYearInput(e)}
                  onChange={(e) => handleClientDataChange(e, "profile", true)}
                  inputProps={{
                    autoComplete: "new-founding_year",
                    form: {
                      autoComplete: "off",
                    },
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 4,
                  }}
                  onBlur={handleOnBlur}
                  helperText={
                    invalidFounderYear ? t("details_a3.company_card.founding_year_invalid") : ""
                  }
                />
                <TextField
                  label={t("details_a3.company_card.number_employees")}
                  name="number_employees"
                  id="company_card_number_employees"
                  value={
                    thousandSeparator(
                      getValueFromMetadata("susa", "number_employees", clientData?.metadata)
                    ) || ""
                  }
                  size="small"
                  fullWidth
                  onChange={(e) => handleClientDataChange(e, "susa", true)}
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                  }}
                />
              </Stack>
              <Stack
                direction={{ xs: "column", md: "row" }}
                sx={{ mt: 2 }}
                spacing={{ xs: 2, md: 4 }}
              >
                <TextField
                  label={t("details_a3.company_card.website")}
                  name="website"
                  id="company_card_website"
                  value={getValueFromMetadata("profile", "website", clientData?.metadata) || ""}
                  size="small"
                  fullWidth
                  error={!isInvalidUrl}
                  placeholder="www.example.com"
                  onChange={(e) => handleClientDataChange(e, "profile", true)}
                  inputProps={{
                    autoComplete: "new-website",
                    form: {
                      autoComplete: "off",
                    },
                  }}
                  onBlur={(e) => {
                    const urlVal = e.target.value;
                    setInputdWebsite(urlVal);
                  }}
                  helperText={!isInvalidUrl ? t("details_a3.company_card.website_invalid_url") : ""}
                />
                <div style={{ width: "100%" }}> </div>
              </Stack>
            </>
          )}
        </Card>
      </Section>
    );
  }
);
