import { styled } from "@mui/material/styles";

const StyledFadeIn = styled("div")(() => {
  return {
    animation: "fadeIn 1s forwards",
    animationIterationCount: 1,
    opacity: 0,
    width: "100%",
    height: "100%",
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
  };
});

export const FadeIn = ({ children }) => {
  return <StyledFadeIn>{children}</StyledFadeIn>;
};
