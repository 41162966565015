import { Auth } from "aws-amplify";
import { useCallback, useEffect, useMemo } from "react";

import { useUsers } from "./useUsers";

import Tconfig from "../../../../config";
import { useAppSelector } from "../Store/hooks/useAppSelector";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";
import {
  fetchAccountData,
  getFromDataStore,
  setCurrentUser,
} from "../Store/currentUser/currentUserSlice";

export const useAuthState = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();
  const { updateUser } = useUsers();

  const authenticated = useMemo(() => currentUser.authenticated, [currentUser.authenticated]);
  const authenticating = useMemo(() => currentUser.authenticating, [currentUser.authenticating]);
  const readyState = useMemo(() => {
    if (currentUser.state === "READY" && currentUser.selectedClient.state === "READY") {
      return "READY";
    }
    return "UNKNOWN";
  }, [currentUser.selectedClient.state, currentUser.state]);

  useEffect(() => {
    if (currentUser.state === "ERROR") {
      throw new Error("Error user validation...");
    }
  }, [currentUser.state]);

  const authCallback = useCallback(
    (user) => {
      dispatch(
        setCurrentUser({
          username: user.username,
          temporaryPassword: "",
          authenticated: true,
          authenticating: false,
          forceChange: false,
          attributes: {
            // __typename: TypeNameEnums.USER_ATTRIBUTES,
            customer_id: user.attributes["custom:account-id"],
            user_id: user.attributes["custom:user-id"],
            name: user.attributes.name,
            sub: user.attributes.sub,
          },
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    if (currentUser.authenticating) return;
    Auth.currentAuthenticatedUser()
      .then(authCallback)
      .catch(() => {
        return dispatch(
          setCurrentUser({
            authenticating: false,
            // __typename: UserStateActionTypes.CURRENT_USER,
            username: "",
            authenticated: false,
            state: "UNKNOWN",
          })
        );
      });
  }, [authCallback, currentUser.authenticating, dispatch]);

  useEffect(() => {
    if (currentUser.authenticated && currentUser.state === "UNKNOWN") {
      dispatch(fetchAccountData());
      dispatch(getFromDataStore());
    }
  }, [currentUser.authenticated, currentUser.state, dispatch]);

  useEffect(() => {
    if (currentUser.selectedClient.state === "READY") return;

    // if the app is 'platform', then no need selected_client for the moment. Otherwise it throws an error, since clients is empty.
    if (
      currentUser.selectedClient.state === "UNKNOWN" &&
      Tconfig.defaults.PRODUCT_ID === "platform"
    ) {
      dispatch(
        setCurrentUser({
          selectedClient: {
            client_id: "",
            state: "READY",
          },
        })
      );
      return;
    }

    // if the app is other then 'platform', then we need selected client
    if (currentUser.selectedClient.state === "UNKNOWN") {
      const userSessionScope =
        currentUser?.user?.metadata &&
        currentUser?.user?.metadata.find((item) => item.scope === "session");

      const userId = currentUser?.user?.user_id;
      const clientId = currentUser?.user?.client_id;

      if (clientId) {
        dispatch(
          setCurrentUser({
            selectedClient: {
              client_id: clientId,
              state: "READY",
            },
          })
        );
      } else if (userSessionScope) {
        dispatch(
          setCurrentUser({
            selectedClient: {
              client_id: userSessionScope?.data?.selected_client,
              state: "READY",
            },
          })
        );
      }

      if (userSessionScope === undefined && currentUser?.user?.user_id) {
        let metaDataObj = {};

        if (clientId) {
          metaDataObj = {
            metadata: [
              {
                scope: "session",
                data: {
                  selected_client: clientId,
                },
              },
            ],
          };
        } else {
          metaDataObj = {
            metadata: [
              {
                scope: "session",
                data: {
                  selected_client: currentUser?.clients[0]?.client_id,
                },
              },
            ],
          };
        }

        updateUser(userId, metaDataObj)
          .then(() => {
            dispatch(
              setCurrentUser({
                selectedClient: {
                  client_id: clientId ? clientId : currentUser?.clients[0]?.client_id,
                  state: "READY",
                },
              })
            );
          })
          .catch(() => {});
      }
    }
  }, [
    currentUser?.clients,
    currentUser.selectedClient.state,
    currentUser?.user?.client_id,
    currentUser?.user?.metadata,
    currentUser?.user?.user_id,
    dispatch,
    updateUser,
  ]);

  return {
    authenticating,
    authenticated,
    readyState,
  };
};

/** ***  CUSTOMER STATE *******
 * "UNKNOWN"
 * "PENDING"
 * "ERROR"
 * "READY"
 */

/** *** SELECTED CLIENT STATE ********+
 * "UNKNOWN"
 * "SELECTABLE"
 * "INITIAL"
 * "PENDING"
 * "DRAFT"
 * "READY"
 */
