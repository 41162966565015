import { API } from "aws-amplify";
import queryString from "query-string";

import TConfig from "../../../../config";

/**
 *
 * @param email string
 * @return isEmailValid boolean
 */

export const useValidateEmail = () => {
  return (email) => {
    const query = queryString.stringify({ email });
    return API.get(TConfig.webapp.SIGNUP_API_ID, `${TConfig.webapp.EMAILS_API_ENDPOINT}?${query}`)
      .then((response) => response.email.status === "ok")
      .catch((e) => false);
  };
};
