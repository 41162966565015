import { useTranslation } from "react-i18next";

import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { useTheme } from "@mui/material/styles";

import config from "../../config";

export const Footer = () => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <>
      <Typography
        variant="subtitle1"
        display="inline-block"
        color={theme.palette.common.white}
        sx={{ px: 1, fontSize: "14px" }}
      >
        &#169; {t("footer.trademark")}
      </Typography>
      {"|"}
      <Link
        href={config.shared_links.IMPRINT}
        target="_blank"
        rel="noreferrer"
        variant="subtitle1"
        color={theme.palette.common.white}
        sx={{ px: 1, fontSize: "14px" }}
      >
        {t("footer.imprint")}
      </Link>
      {"|"}
      <Link
        href={config.shared_links.PRIVACY_URL}
        target="_blank"
        rel="noreferrer"
        variant="subtitle1"
        color={theme.palette.common.white}
        sx={{ px: 1, fontSize: "14px" }}
      >
        {t("footer.gdpr")}
      </Link>
      {"|"}
      <Link
        href={config.shared_links.TERMS_URL}
        target="_blank"
        rel="noreferrer"
        variant="subtitle1"
        color={theme.palette.common.white}
        sx={{ px: 1, fontSize: "14px" }}
      >
        {t("footer.conditions")}
      </Link>
    </>
  );
};
