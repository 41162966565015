export const font_faces = `
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url('/fonts/lato-v23-latin-100.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-100.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-100.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-100.woff') format('woff'),
       url('/fonts/lato-v23-latin-100.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url('/fonts/lato-v23-latin-300.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-300.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-300.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-300.woff') format('woff'),
       url('/fonts/lato-v23-latin-300.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url('/fonts/lato-v23-latin-300italic.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-300italic.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-300italic.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-300italic.woff') format('woff'),
       url('/fonts/lato-v23-latin-300italic.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url('/fonts/lato-v23-latin-regular.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-regular.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-regular.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-regular.woff') format('woff'),
       url('/fonts/lato-v23-latin-regular.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url('/fonts/lato-v23-latin-italic.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-italic.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-italic.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-italic.woff') format('woff'),
       url('/fonts/lato-v23-latin-italic.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url('/fonts/lato-v23-latin-700.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-700.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-700.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-700.woff') format('woff'),
       url('/fonts/lato-v23-latin-700.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url('/fonts/lato-v23-latin-700italic.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-700italic.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-700italic.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-700italic.woff') format('woff'),
       url('/fonts/lato-v23-latin-700italic.ttf') format('truetype')
}
@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url('/fonts/lato-v23-latin-900italic.eot');
  src: local(''),
       url('/fonts/lato-v23-latin-900italic.eot?#iefix') format('embedded-opentype'),
       url('/fonts/lato-v23-latin-900italic.woff2') format('woff2'),
       url('/fonts/lato-v23-latin-900italic.woff') format('woff'),
       url('/fonts/lato-v23-latin-900italic.ttf') format('truetype')
}
`;
