import { CurrentUserState } from "./interfaces";

export const initialState: CurrentUserState = {
  username: "",
  temporaryPassword: "",
  authenticating: false,
  authenticated: false,
  forceChange: false,
  errors: [],
  attributes: {
    // USER_ATTRIBUTES,
    customer_id: "",
    user_id: "",
    name: "", // initials
    sub: "", // username
  },
  advisors: [],
  customer: null,
  customers: [],
  clients: [],
  user: null,
  transactions: [],
  selectedClient: {
    client_id: "",
    state: "UNKNOWN",
  },
  state: "UNKNOWN",
  loading: false,
  errorState: {
    //AUTH_ERROR,
    errors: [],
  },
  datastore: {
    mappings: [],
    categoriesTree: [],
    categories: [],
    countries: [],
    region: [],
    sources: [],
    investor: [],
    fileTypes: [],
    fileTypesTree: [],
    transactionTree: [],
    transactionSteps: [],
    legal_form: [],
    fiscal_year: [],
    mandantTree: [],
    userRoles: [],
    period: [],
    companyStake: [],
    types: [],
    investorPhase: [],
    phases: [],
    transactionStructure: [],
    stake: [],
    ebitda: [],
    ebitda_bracket: [],
    invest: [],
    invest_bracket: [],
    revenue: [],
    revenue_bracket: [],
    owner_type: [],
    competitorType: [],
    priority: [],
  },

  // advisor, investor
  current_main_content: "initial_content",
  // advisor
  advisor_approved: false,
  // company
  newSusaUploaded: false,
  // investor
  investor_approved: false,
};
