import { styled } from "@mui/material/styles";

export const StyledDashboardLayout = styled("main")(({ theme }) => {
  return {
    display: "grid",
    width: "100vw",
    minHeight: "100vh",
    gridTemplateAreas: `
      "topbar"
      "content"
    `,
    gridTemplateRows: "90px 1fr",
    [theme.breakpoints.up("md")]: {
      gridTemplateRows: "110px 1fr",
    },
  };
});
