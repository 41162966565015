import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useGetTransactionByIdQuery } from "../../Store/api/transactions/transactionsApi";
import { useTransactions } from "../../hooks";
import { StyledForm } from "./Messenger.styles";
import { Collapse, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MessengerFormValues, Comment } from "./interfaces";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/system";
import MessagesComponent from "./MessegesComponent";
import { addMessage, deleteMessage } from "./MessengerUtils";

interface MessengerProps {
  name: string;
  handleChange: any;
  transactionId: string;
  productId: "platform" | "advisor" | "company" | "lawyer";
  parentExpanded: string;
  fileId: string;
}

const initialFormValues: MessengerFormValues = {
  message: "",
  messages: undefined,
};

export const Messenger = ({
  name,
  handleChange,
  transactionId,
  productId,
  parentExpanded,
  fileId,
}: MessengerProps) => {
  const { t } = useTranslation(["common"]);

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [divider, setDivider] = useState(-1000);
  const transactionByIdQuery = useGetTransactionByIdQuery({ transactionId });
  const transaction = transactionByIdQuery.data;
  const methods = useForm<MessengerFormValues>({ mode: "all", defaultValues: initialFormValues });
  const { handleSubmit, setValue, watch, control } = methods;
  const messages = watch("messages");
  const message = watch("message");
  const [expanded, setExpanded] = useState("0");
  const { updateTransaction } = useTransactions();

  useEffect(() => {
    const possibleComments = transaction?.files?.find((data) => data.file_id === fileId)
      ?.metadata?.[0]?.data;
    if (possibleComments && Array.isArray(possibleComments)) {
      setValue("messages", possibleComments);
    } else {
      return;
    }
  }, [setValue, transaction, fileId]);

  const userId: string = useMemo(() => {
    if (productId === "platform") {
      return currentUser?.user?.user_id || "";
    } else {
      return currentUser?.attributes?.user_id || "";
    }
  }, [currentUser, productId]);

  const newMessageCount: string = useMemo(() => {
    if (messages !== undefined) {
      const count = messages.length - (divider + 1);
      if (divider === -100 && messages.length === 0) {
        return "Keine Anmerkungen";
      } else if (divider === -100 || divider === -10) {
        return `0 neue Anmerkungen`;
      } else if (count === 1) {
        return "1 neue Anmerkung";
      } else if (divider !== -1000) {
        return `${count} neue Anmerkungen`;
      }
    }
    return "Loading";
  }, [divider, messages]);

  useEffect(() => {
    if (divider === -1000 && Array.isArray(messages)) {
      let newDivider: number = 0;
      let updatedMessages: Comment[] = [];
      if (messages.length > 0) {
        updatedMessages = messages.map((message: Comment) => {
          if (message.viewedBy.includes(userId)) {
            newDivider = newDivider + 1;
            return message;
          } else {
            return {
              ...message,
              viewedBy: [...message.viewedBy, userId],
            };
          }
        });
      }

      setDivider(newDivider === 0 ? -100 : newDivider - 1);
      if (newDivider !== messages.length) {
        const ObjectToUpdate = {
          files: [
            {
              file_id: fileId,
              metadata: [
                {
                  scope: "activity",
                  data: updatedMessages,
                },
              ],
            },
          ],
        };
        updateTransaction(transactionId, ObjectToUpdate);

        if (newDivider !== messages.length) {
          setValue("messages", updatedMessages);
        }
      }
    }
  }, [messages, divider, handleChange, setValue, userId, fileId, updateTransaction, transactionId]);

  const handleSendMessage = async () => {
    if (message !== "") {
      setIsSubmitting(true);
      const { comment, comments } = await addMessage(
        userId,
        transactionByIdQuery,
        currentUser,
        productId,
        message,
        fileId,
        transactionId,
        updateTransaction
      );

      setIsSubmitting(false);
      setDivider(-10);
      setValue("messages", Array.isArray(comments) ? [...comments, comment] : [comment]);
      setValue("message", "");
    }
  };
  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleDeleteMessage = async (messageId: string) => {
    setIsDeleting(true);

    const { newMessages } = await deleteMessage(
      transactionByIdQuery,
      fileId,
      messageId,
      updateTransaction,
      transactionId
    );
    newMessages ? setValue("messages", newMessages) : setValue("messages", []);
    setDivider(-10);
    setIsDeleting(false);
  };

  return (
    <>
      <FormProvider {...methods}>
        <StyledForm name="messenger" onSubmit={handleSubmit(handleSendMessage)}>
          <Box
            color="primary"
            sx={{
              backgroundColor: "#fefefe",
              width: "100%",
              "&:hover": {
                cursor: "pointer",
              },
            }}
            id={name}
            onClick={(e) => {
              e.preventDefault();
              if (name === expanded) {
                setExpanded("0");
              } else {
                setExpanded(name);
              }
            }}
          >
            <Stack
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                color: "#004e92",
              }}
            >
              <Typography margin={"15px"} className="textLabel" fontWeight={"bold"}>
                {newMessageCount}
              </Typography>
              {expanded === name ? (
                <ExpandLessIcon
                  sx={{ margin: "0px 15px 0px 0px" }}
                  fontSize="large"
                  color={"inherit"}
                />
              ) : (
                <ExpandMoreIcon
                  sx={{ margin: "0px 15px 0px 0px" }}
                  fontSize="large"
                  color={"inherit"}
                />
              )}
            </Stack>
          </Box>
          <Collapse
            orientation="vertical"
            collapsedSize={0}
            in={expanded === name}
            unmountOnExit
            sx={{
              backgroundColor: "#fefefe",
              padding: "0px 16px",
            }}
          >
            {messages !== undefined && (
              <MessagesComponent
                messages={messages}
                divider={divider}
                isDeleting={isDeleting}
                isSubmitting={isSubmitting}
                userId={userId}
                productId={productId}
                handleDeleteMessage={handleDeleteMessage}
              />
            )}
            <>
              <Controller
                name="message"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    sx={{ marginBottom: 1, marginTop: 3 }}
                    disabled={isSubmitting || isDeleting}
                    multiline
                    size="small"
                    onChange={onChange}
                    onKeyDown={(e) => handleEnter(e)}
                    value={value}
                    fullWidth
                    label={t("elements.messenger.placeholder")}
                    variant="outlined"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            disabled={isSubmitting || isDeleting}
                            edge="end"
                            onClick={handleSendMessage}
                          >
                            <SendIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </>
          </Collapse>
        </StyledForm>
      </FormProvider>
    </>
  );
};
