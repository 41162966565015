import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useInvestmentTeaser = () => {
  const getPuplicFile = (transactionId) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.INVESTMENT_TEASER_FILE_API_ENDPOINT}/${transactionId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  const addInvite = (transactionId, customerId, callbackUrl, locale) => {
    return API.post(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.INVESTMENT_TEASER_INVITE_API_ENDPOINT}/${transactionId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
        body: {
          customer_id: customerId,
          callback_url: callbackUrl,
          locale: locale,
        },
      }
    );
  };

  const getInvite = (transactionId) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.INVESTMENT_TEASER_INVITE_API_ENDPOINT}/${transactionId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  return { getPuplicFile, addInvite, getInvite };
};