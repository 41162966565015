import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from 'react-redux';
import { useFileUrl } from "./useFileUrl";
import { LoadingIndicator } from "../LoadingIndicator";
import { Logo } from "../Logo";
import { Button } from "@mui/material";
import { loadLanguage } from '../../Store/languageChange/languageSlice';
import { useTransactions } from '../../hooks/useTransactions';

export const InvestmetTeaserView = () => {
  const { transactionId } = useParams();
  const { t } = useTranslation(["platform/common"]);
  const dispatch = useDispatch();
  const { isLoading, fileUrls } = useFileUrl(transactionId);
  const [height, setHeight] = useState(window.innerHeight);
  const [currentLanguage, setCurrentLanguage] = useState(null); // State to keep track of the current language
  const [transactionDetails, setTransactionDetails] = useState({}); // State to store transaction details
  const { getTransactionById } = useTransactions();

  useEffect(() => {
    dispatch(loadLanguage({ transactionId })); // Load language from localStorage or default
  }, [dispatch, transactionId]);

  useEffect(() => {
    if (fileUrls) {
      const languages = Object.keys(fileUrls);
      if (languages.length > 0) {
        setCurrentLanguage(languages[0]); // Set the default language based on fileUrls keys for links in my case
      }
    }
  }, [fileUrls]);

  useEffect(() => {
    // Fetch the transaction details to get the name
    const fetchTransactionDetails = async () => {
      try {
        const response = await getTransactionById(transactionId);
        setTransactionDetails(response.transaction);
      } catch (error) {
        // console.error('Error fetching transaction details:', error);
      }
    };

    fetchTransactionDetails();
  }, [transactionId, getTransactionById]); // Omit getTransactionById from dependencies

  const handlePreview = () => {
    const email = "hk@beteiligungsboerse.eu"; // Hardcoded email at the moment
    const clientName = transactionDetails.client_name || 'Client'; // I put here default to 'Client' if client_name is not available
    const subject = encodeURIComponent(`Interesse an ${clientName}`);
    const body = encodeURIComponent(`Sehr geehrte Damen und Herren,

ich interessiere mich für die von Ihnen angebotene Investitionsmöglichkeit. 

Bitte senden Sie mir ein NDA zu, damit ich Zugang zu den Unternehmensdaten erhalten kann.

Vielen Dank und mit freundlichen Grüßen`);

    const mailtoLink = `mailto:${email}?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator type={"PROGRESS"} />}
      {!isLoading && currentLanguage && (
        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Logo />
            <Button
              variant="contained"
              color="secondary"
              sx={{ marginTop: 2, marginBottom: 2, marginRight: 2 }}
              onClick={handlePreview}
            >
              {t("platform/common:content.investor_matching.investor_status.interested")}
            </Button>
          </div>
          <object
            data={fileUrls[currentLanguage]} // Use the correct language-specific file URL
            type="application/pdf"
            title="PDF Viewer"
            width="100%"
            height={height}
          />
        </div>
      )}
    </>
  );
};
