import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import { LoginError } from "./LoginError";
import { useResetPassword } from "../hooks";
import { ErrorBoundary } from "./ErrorBoundary";
import { FadeIn } from "./Transitions";
import { ContainerWrapper } from "./ContainerWrapper";
import { Logo } from "./Logo";
import { LoadingIndicator } from "./LoadingIndicator";

import { SimpleLayout } from "../../../../libs/Layout";
import { useAppSelector } from "../Store/hooks/useAppSelector";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../Store/currentUser/currentUserSlice";

const ResetPassword = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatchUser = useAppDispatch();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const { t } = useTranslation();
  const [resetPassword, loading] = useResetPassword();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  // to toggle the password and show it
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // to toggle the repeat-password and show it
  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  // to prevent default, after clicking visibility icon on password TextField
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  function handleReset(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    const passwordRepeat = data.get("passwordRepeat");
    const code = data.get("code");

    if (currentUser.errors.length > 0) {
      dispatchUser(setCurrentUser({ errors: [] }));
    }
    const params = {
      password,
      passwordRepeat,
      email,
      code,
    };

    resetPassword(params);
  }

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <ContainerWrapper width={"sm"}>
          <FadeIn>
            {loading && <LoadingIndicator type={"COMPONENT"} />}
            <Box
              component="form"
              onSubmit={handleReset}
              sx={{
                p: { xs: 2, sm: 3 },
                backgroundColor: theme.palette.common.white,
              }}
            >
              <Stack direction="row" justifyContent="center" sx={{ mb: 5 }}>
                <Logo />
              </Stack>
              <Typography variant="body1" align="center" sx={{ mb: 3 }}>
                {t("reset.info_text")}
              </Typography>

              <TextField
                margin={isMobile ? "dense" : "normal"}
                label={t("reset.code.label")}
                name="code"
                id="repeat_pass_code"
                size={isMobile ? "small" : "medium"}
                required
                fullWidth
                autoFocus
                // value=""
                // error={!isEmailValid}
                // helperText={!isEmailValid ? "Check your E-mail" : null}
              />

              <TextField
                margin={isMobile ? "dense" : "normal"}
                label={t("reset.email.label")}
                name="email"
                id="repeat_pass_email"
                type="email"
                size={isMobile ? "small" : "medium"}
                required
                fullWidth
                // value=""
                // error={!isEmailValid}
                // helperText={!isEmailValid ? "Check your E-mail" : null}
              />

              <TextField
                margin={isMobile ? "dense" : "normal"}
                label={t("reset.password.label")}
                name="password"
                id="repeat_pass_password"
                type={showPassword ? "text" : "password"}
                size={isMobile ? "small" : "medium"}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize={isMobile ? "small" : "medium"} />
                        ) : (
                          <Visibility fontSize={isMobile ? "small" : "medium"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? "Check your E-mail" : null}
              />

              <TextField
                margin={isMobile ? "dense" : "normal"}
                label={t("reset.repeat_password.label")}
                name="passwordRepeat"
                id="repeat_pass_passwordRepeat"
                type={showRepeatPassword ? "text" : "password"}
                size={isMobile ? "small" : "medium"}
                required
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showRepeatPassword ? (
                          <VisibilityOff fontSize={isMobile ? "small" : "medium"} />
                        ) : (
                          <Visibility fontSize={isMobile ? "small" : "medium"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                // error={!isEmailValid}
                // helperText={!isEmailValid ? "Check your E-mail" : null}
              />

              {currentUser.errors.length > 0 && <LoginError />}

              <Button
                type="submit"
                color="primary"
                size={isMobile ? "small" : "medium"}
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("reset.submit")}
              </Button>

              <Stack alignItems="center">
                <Link href="/" variant="body2" color="error">
                  {t("misc.back")}
                </Link>
              </Stack>
            </Box>
          </FadeIn>
        </ContainerWrapper>
      </ErrorBoundary>
    </SimpleLayout>
  );
};

export default ResetPassword;
