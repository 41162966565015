import { styled } from "@mui/material/styles";

export const StyledSimplePage = styled("main")(() => {
  return {
    display: "grid",
    gridTemplateRows: "1fr min-content",
    width: "100vw",
    minHeight: "100vh",
    backgroundImage: "url(/assets/bg_dashboard.webp)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
  };
});

export const StyledSimpleContent = styled("main")(() => {
  return {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };
});

export const StyledSimpleFooter = styled("footer")(({ theme }) => {
  return {
    backgroundColor: "transparent",
    textAlign: "center",
    padding: "10px 0 40px 0 ",
    color: theme.palette.common.white,
  };
});
