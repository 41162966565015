import React, { useMemo, useState, useEffect, useRef } from "react";
import { useAsyncDebounce } from "react-table";
import { matchSorter } from "match-sorter"; // A great library for fuzzy filtering/sorting items
import MenuItem from "@mui/material/MenuItem";
import { StyledTextField, StyledSelect } from "./styled.tableComp";
import { useTranslation } from "react-i18next";
import { useCustomFilters } from "./useCustomFilters";
import { Tooltip, Typography, ClickAwayListener } from "@mui/material";
import {
  getCategoryIds,
  getCategoryNameByValue,
  getSubCategoryOptions,
} from "../CategoriesDropdown/CategoriesDropdown.utils";

// Define a default UI for global filtering

export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const { t } = useTranslation();
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  let text = "";
  if (count === 0) {
    text = t("de/common:document_table.result0");
  } else if (count === 1) {
    text = `${count} ${t("de/common:document_table.result")}`;
  } else {
    text = `${count} ${t("de/common:document_table.results")}`;
  }

  return (
    <span>
      {t("de/common:document_table.search")}:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={text}
        style={{
          fontSize: "15px",
          border: "0",
          padding: "10px",
        }}
      />
    </span>
  );
};

// Define a default UI for column filtering
export const DefaultColumnFilter = ({ column: { filterValue, setFilter }, placeholder }) => {
  return (
    <StyledTextField
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={placeholder}
      sx={{ width: "100%", marginTop: "10px" }}
    />
  );
};

// This is a custom filter UI for selecting a unique option from a list
export const SelectColumnFilter = ({
  column: {
    filterValue,
    setFilter,
    preFilteredRows,
    id,
    categoryOptions = false,
    arrOptions = false,
  },
}) => {
  const { filterRevenue, filterEbitda, filterInvest } = useCustomFilters(id);

  const options = useMemo(() => {
    const options = new Set();
    if (categoryOptions) {
      categoryOptions.forEach((cat) => {
        options.add(cat.label);
      });
    } else if (arrOptions) {
      arrOptions.forEach((opt) => {
        options.add(opt);
      });
    } else {
      preFilteredRows.forEach((row) => {
        options.add(row.values[id]);
      });
    }
    return [...options.values()];
  }, [id, categoryOptions, arrOptions, preFilteredRows]);

  return (
    <StyledSelect
      multiple={false}
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        id === "revenue"
          ? setFilter(filterRevenue(e.target.value || ""))
          : id === "ebitda"
          ? setFilter(filterEbitda(e.target.value || ""))
          : id === "invest"
          ? setFilter(filterInvest(e.target.value || ""))
          : setFilter(e.target.value);
      }}
      sx={{ maxWidth: "100%", marginTop: "10px", display: "flex" }}
    >
      <MenuItem value="">Alle</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export const CategoriesColumnFilter = ({
  column: { filterValue, setFilter, id, categoryOptions = false },
}) => {
  const { filterCategories } = useCustomFilters(id);

  const options = useMemo(() => {
    const options = new Set();
    if (categoryOptions) {
      categoryOptions.forEach((cat) => {
        options.add(cat.label);
      });
    }
    return [...options.values()];
  }, [categoryOptions]);

  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectChange = (e) => {
    const filtered = filterCategories(e.target.value);
    setFilter(filtered);
    if (e.target.value.length > 0) {
      setTooltipOpen(true);
    } else {
      setTooltipOpen(false);
    }
    setOpen(false);
  };

  const handleMouseEnter = () => {
    if (filterValue && filterValue.length > 0) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          open={tooltipOpen}
          title={
            filterValue !== undefined && filterValue.length > 0
              ? filterValue.map((value, index) => (
                  <Typography key={index} sx={{ margin: "10px 0", display: "block" }}>
                    {value}
                  </Typography>
                ))
              : ""
          }
          placement={"right"}
          arrow
        >
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <StyledSelect
              multiple={true}
              size="small"
              value={filterValue === undefined ? [] : filterValue}
              onChange={handleSelectChange}
              sx={{ maxWidth: "100%", minWidth: "100%", marginTop: "10px", display: "flex" }}
              renderValue={(selected) => {
                return selected && selected[0] !== undefined ? (
                  <Typography noWrap>{selected[0]}</Typography>
                ) : (
                  ""
                );
              }}
              MenuProps={{
                anchorEl: anchorEl,
                open: open,
                onClose: () => setOpen(false),
                PaperProps: {
                  onMouseLeave: handleMouseLeave,
                },
                PopperProps: {
                  disablePortal: true,
                },
                MenuListProps: {
                  style: {
                    maxHeight: "100%",
                    overflowY: "auto",
                  },
                },
              }}
              onClick={handleClick}
            >
              {options.map((option, i) => (
                <MenuItem key={i} value={option}>
                  <Typography noWrap>{option}</Typography>
                </MenuItem>
              ))}
            </StyledSelect>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};
export const SubCategoriesColumnFilter = ({
  columns,
  column: { filterValue, setFilter, id, categories, categoriesTree },
}) => {
  const categoryFilterValue = columns.find((col) => col.id === "categories")?.filterValue;
  const { filterCategories } = useCustomFilters(id);

  const categoriesWithIds = getCategoryIds(categoryFilterValue || [], categories);
  const categoryIds = categoriesWithIds.map((cat) => cat.value);
  const ids = useMemo(() => {
    return categoryIds.length > 0
      ? categoryIds
      : [
          "ICAT001",
          "ICAT002",
          "ICAT003",
          "ICAT004",
          "ICAT005",
          "ICAT006",
          "ICAT007",
          "ICAT008",
          "ICAT009",
          "ICAT010",
          "ICAT011",
          "ICAT012",
          "ICAT013",
          "ICAT014",
          "ICAT015",
          "ICAT016",
        ];
  }, [categoryIds]);

  const subCategoryOptions = useMemo(() => {
    const options = getSubCategoryOptions(ids, filterValue || [], categoriesTree, categories);
    return options;
  }, [ids, filterValue, categoriesTree, categories]);

  const [open, setOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prevOpen) => !prevOpen);
  };

  const handleSelectChange = (e) => {
    const newFilter = filterCategories(e.target.value);
    setFilter(newFilter);
    if (e.target.value.length > 0) {
      setTooltipOpen(true);
    } else {
      setTooltipOpen(false);
    }
    setOpen(false);
  };

  const handleMouseEnter = () => {
    if (filterValue && filterValue.length > 0) {
      setTooltipOpen(true);
    }
  };

  const handleMouseLeave = () => {
    setTooltipOpen(false);
    setOpen(false);
  };

  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <div>
        <Tooltip
          open={tooltipOpen}
          title={
            filterValue !== undefined && filterValue.length > 0
              ? filterValue.map((value, index) => (
                  <Typography key={index} sx={{ margin: "10px 0", display: "block" }}>
                    {value}
                  </Typography>
                ))
              : ""
          }
          placement={"right"}
          arrow
        >
          <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
            <StyledSelect
              multiple={true}
              size="small"
              value={filterValue === undefined ? [] : filterValue}
              onChange={handleSelectChange}
              sx={{ maxWidth: "100%", minWidth: "100%", marginTop: "10px", display: "flex" }}
              renderValue={(selected) => {
                return selected && selected[0] !== undefined ? (
                  <Typography noWrap>{selected[0]}</Typography>
                ) : (
                  ""
                );
              }}
              MenuProps={{
                anchorEl: anchorEl,
                open: open,
                onClose: handleMouseLeave,
                PaperProps: {
                  onMouseLeave: handleMouseLeave,
                  style: {
                    maxHeight: 800,
                    maxWidth: 800,
                  },
                },
                PopperProps: {
                  disablePortal: true,
                },
                MenuListProps: {
                  style: {
                    maxHeight: "100%",
                    overflowY: "auto",
                  },
                },
              }}
              onClick={handleClick}
            >
              {ids.map((id) => {
                const categoryLabel = getCategoryNameByValue(id, categories);
                const groupHeader = { value: id, cat: categoryLabel, label: categoryLabel };
                const filteredSubCategories = [
                  groupHeader,
                  ...subCategoryOptions.filter((opt) => opt.cat === categoryLabel),
                ];
                return filteredSubCategories.map((option, i) => (
                  <MenuItem
                    key={i + option.cat}
                    value={option.label}
                    disabled={i === 0 ? true : false}
                  >
                    {option.label}
                  </MenuItem>
                ));
              })}
            </StyledSelect>
          </div>
        </Tooltip>
      </div>
    </ClickAwayListener>
  );
};

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
