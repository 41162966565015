import React, { KeyboardEvent, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { useGetTransactionByIdQuery } from "../../Store/api/transactions/transactionsApi";
import {
  Hour,
  MessageBottomContainer,
  MessageBubble,
  MessageContainer,
  MessageHeader,
  MessageText,
  MessengerContainer,
  Name,
  StyledForm,
} from "./Messenger.styles";
import { Collapse, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import PersonRemoveOutlinedIcon from "@mui/icons-material/Delete";
import SendIcon from "@mui/icons-material/Send";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { MessengerFormValues, Comment } from "./interfaces";
import { FormProvider, useForm, Controller } from "react-hook-form";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { canBeDeleted } from "./MessengerUtils";
import { useTranslation } from "react-i18next";
import { Box, Stack } from "@mui/system";

interface MessengerProps {
  name: string;
  keyName: string;
  handleChange: any;
  transactionId: string;
  comments: Comment[];
  productId: "platform" | "advisor" | "company" | "lawyer";
  parentExpanded: string;
}

const initialFormValues: MessengerFormValues = {
  message: "",
  messages: [],
};

export const Messenger = ({
  name,
  keyName,
  handleChange,
  comments,
  transactionId,
  productId,
  parentExpanded,
}: MessengerProps) => {
  const { t } = useTranslation(["common"]);

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [isDeleting, setIsDeleting] = useState(false);
  const [divider, setDivider] = useState(-1000);
  const transactionByIdQuery = useGetTransactionByIdQuery({ transactionId });
  const transaction = transactionByIdQuery.data;
  const methods = useForm<MessengerFormValues>({ mode: "all", defaultValues: initialFormValues });
  const { handleSubmit, setValue, watch, control, formState } = methods;
  const { isSubmitting } = formState;
  const messages = watch("messages");
  const message = watch("message");
  const [expanded, setExpanded] = useState("0");

  const parentExpandedMemo = useMemo(() => {
    return parentExpanded;
  }, [parentExpanded]);

  const userId: string = useMemo(() => {
    if (productId === "platform") {
      return currentUser?.user?.user_id || "";
    } else {
      return currentUser?.attributes?.user_id || "";
    }
  }, [currentUser, productId]);

  const newMessageCount: string = useMemo(() => {
    const count = messages.length - (divider + 1);
    if (divider === -100 && messages.length === 0) {
      return "Keine Anmerkungen";
    } else if (divider === -100 || divider === -10) {
      return `0 neue Anmerkungen`;
    } else if (count === 1) {
      return "1 neue Anmerkung";
    } else if (divider !== -1000) {
      return `${count} neue Anmerkungen`;
    } else {
      return "Loading";
    }
  }, [divider, messages]);

  useEffect(() => {
    if (parentExpandedMemo === name && divider === -1000) {
      let newDivider: number = 0;
      let updatedMessages: Comment[] = [];
      if (messages.length > 0) {
        updatedMessages = messages.map((message: Comment) => {
          if (message.viewedBy.includes(userId)) {
            newDivider = newDivider + 1;
            return message;
          } else {
            return {
              ...message,
              viewedBy: [...message.viewedBy, userId],
            };
          }
        });
      }
      setDivider(newDivider === 0 ? -100 : newDivider - 1);
      if (newDivider !== messages.length) {
        handleChange(name, keyName, "comments", updatedMessages);
        setValue("messages", updatedMessages);
      }
    }
  }, [parentExpandedMemo, messages, divider, handleChange, setValue, name, keyName, userId]);

  const handleSendMessage = async () => {
    if (message !== "") {
      const comment: Comment = {
        id: uuidv4(),
        timestamp: new Date().getTime(),
        user: userId,
        userName: `${currentUser?.user?.first_name} ${currentUser?.user?.last_name}`,
        orgName: `${currentUser?.customer?.name}`,
        role: productId,
        private: true,
        msg: message,
        viewedBy: [userId],
      };

      handleChange(name, keyName, "comments", comment);
      setDivider(-10);
      setValue("messages", Array.isArray(comments) ? [...comments, comment] : [comment]);
      setValue("message", "");
    }
  };
  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === "Enter" && e.shiftKey === false) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  const handleDeleteMessage = async (messageId: string) => {
    setIsDeleting(true);
    const newMessages = messages.filter((message) => message.id !== messageId);
    handleChange(name, keyName, "comments", newMessages);
    setDivider(-10);
    setIsDeleting(false);
  };

  useEffect(() => {
    const possibleComments = comments;

    if (possibleComments && Array.isArray(possibleComments)) {
      setValue("messages", possibleComments);
    } else {
      setValue("messages", []);
    }
  }, [setValue, transaction, comments]);

  return (
    <>
      {transaction && (
        <FormProvider {...methods}>
          <StyledForm name="messenger" onSubmit={handleSubmit(handleSendMessage)}>
            <Box
              color="primary"
              sx={{
                backgroundColor: "#fefefe",
                width: "100%",
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              id={name}
              onClick={(e) => {
                e.preventDefault();
                if (name === expanded) {
                  setExpanded("0");
                } else {
                  setExpanded(name);
                }
              }}
            >
              <Stack
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  color: "#004e92",
                }}
              >
                {/* <Typography className="textLabel" fontWeight={"bold"}> */}
                {/*   {t("elements.messenger.header")} */}
                {/* </Typography> */}
                <Typography margin={"15px"} className="textLabel" fontWeight={"bold"}>
                  {newMessageCount}
                </Typography>
                {expanded === name ? (
                  <ExpandLessIcon
                    sx={{ margin: "0px 15px 0px 0px" }}
                    fontSize="large"
                    color={"inherit"}
                  />
                ) : (
                  <ExpandMoreIcon
                    sx={{ margin: "0px 15px 0px 0px" }}
                    fontSize="large"
                    color={"inherit"}
                  />
                )}
              </Stack>
            </Box>
            <Collapse
              orientation="vertical"
              collapsedSize={0}
              in={expanded === name}
              unmountOnExit
              sx={{
                backgroundColor: "#fefefe",
                padding: "0px 16px",
              }}
            >
              {messages.length > 0 && (
                <MessengerContainer className={messages.length === 0 ? "empty" : ""}>
                  {messages.map((message: any, i: number) => {
                    return (
                      <>
                        {i === 0 && divider === -1 && (
                          <Typography textAlign={"center"} width={"100%"} color={"white"}>
                            {"Neue Anmerkungen"}
                          </Typography>
                        )}
                        <MessageContainer
                          key={message.id}
                          align={userId === message.user ? "flex-end" : "flex-start"}
                        >
                          <MessageBubble className={userId !== message.user ? message.role : ""}>
                            <MessageHeader>
                              <Name>
                                {message.userName} - {message.orgName ? message.orgName : ""}
                              </Name>
                              <Hour>{moment(message.timestamp).format("DD.MM.YY - HH:mm")}</Hour>
                            </MessageHeader>
                            <MessageBottomContainer>
                              <MessageText
                                disabled
                                multiline
                                value={message.msg}
                                fullWidth
                                variant="standard"
                              />
                              {canBeDeleted(userId, message.user, productId, message.timestamp) && (
                                <IconButton
                                  color={"primary"}
                                  disabled={isDeleting || isSubmitting}
                                  size="small"
                                  onClick={() => handleDeleteMessage(message.id)}
                                >
                                  <PersonRemoveOutlinedIcon />
                                </IconButton>
                              )}
                            </MessageBottomContainer>
                          </MessageBubble>
                        </MessageContainer>
                        {i === divider && divider + 1 !== messages.length && (
                          <Typography
                            textAlign={"center"}
                            width={"100%"}
                            fontWeight={"bold"}
                            color={"#004e92"}
                            sx={{ borderBottom: "1px solid #004e9280" }}
                          >
                            {"Neue Anmerkungen"}
                          </Typography>
                        )}
                      </>
                    );
                  })}
                </MessengerContainer>
              )}
              <>
                <Controller
                  name="message"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      sx={{ marginBottom: 1, marginTop: 3 }}
                      disabled={isSubmitting || isDeleting}
                      multiline
                      size="small"
                      onChange={onChange}
                      onKeyDown={(e) => handleEnter(e)}
                      value={value}
                      fullWidth
                      label={t("elements.messenger.placeholder")}
                      variant="outlined"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              disabled={isSubmitting || isDeleting}
                              edge="end"
                              onClick={handleSendMessage}
                            >
                              <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                />
              </>
            </Collapse>
          </StyledForm>
        </FormProvider>
      )}
    </>
  );
};
