import { Trans } from "react-i18next";

import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

import { Alert } from "./Alert";
import { useAppSelector } from "../Store/hooks/useAppSelector";

/**
 * Using error.code & error.name, returns proper and translated content for the
 * error.
 */
const TranslatedErrorMessage = ({ errors, email }) => {
  return errors.map((error) => {
    switch (error.code) {
      case "ExpiredCodeException":
      case "PasswordResetRequiredException":
        return (
          <Trans
            i18nKey={`error.${error.name}`}
            values={{ ...error, message: { email: email || "" } }}
          >
            <Link href="/forgot">get reset code</Link>
          </Trans>
        );
      case "CodeMismatchException":
        return (
          <Trans
            i18nKey={`error.${error.name}`}
            values={{ ...error, message: { email: email || "" } }}
          >
            check your inbox and spam folder
          </Trans>
        );
      case "InvalidPasswordException":
        return <Trans i18nKey={`change.validation_rule`} />;
      default:
        return (
          <>
            <Trans i18nKey={`error.${error.name}`} />
          </>
        );
    }
  });
};

/**
 * <LoginError /> offers a unified and translated error messages in:
 * login, reset, create, forgot password views.
 * It needs to be rendered conditionally to avoid async unmounted component error.
 * Ex: on the parent  component use it like this: { isThereAnyError && <LoginError />}
 */
export const LoginError = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  if (currentUser.errors.length === 0) return <></>;
  const errors = TranslatedErrorMessage(currentUser);

  return (
    <Stack direction="row" justifyContent="center">
      <Alert type={"ERROR"}>
        {errors.map((CustomError, key) => (
          <div key={key}>{CustomError}</div>
        ))}
      </Alert>
    </Stack>
  );
};

/** --- AUTH_RESPONSES ---
 * UNEXPECTED_ERROR = "UNEXPECTED_ERROR"
 * CODE_EXPIRED = "ExpiredCodeException"
 * RESET_REQUIRED = "PasswordResetRequiredException"
 * CODE_MISMATCH = "CodeMismatchException"
 * TOO_MANY_TRY = "LimitExceededException"
 * USER_NOT_FOUND = "UserNotFoundException"
 * USER_NOT_AUTHORIZED = "NotAuthorizedException"
 * INVALID_PASSWORD = "InvalidPasswordException"
 * INVALID_PARAMETER = "InvalidParameterException"
 * NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
 * NO_EMPTY = "EMPTY_STRING_NOT_ALLOWED"
 * EMAIL_REQUIRED = "STRING_MUST_BE_EMAIL"
 * PASSWORD_POLICY = "PASSWORD_MUST_FULFILL_POLICY"
 * PASSWORD_CONFIRM_MISMATCH = "PASSWORD_AND_REPEAT_MUST_BE_SAME"
 */
