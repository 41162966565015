import { styled } from "@mui/material/styles";
import { rgba } from "polished";

const StyledProgressIndicator = styled("section")(({ theme }) => {
  const bgColor = rgba("#E7E7E8", 0.5);
  const strokeColor = "#193C6C";
  return {
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 100,
    display: "flex",
    width: "100vw",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: bgColor,
    "& svg": {
      animation: "svgAnimation 2s linear infinite",
      maxWidth: "200px",
      [theme.breakpoints.up("md")]: {
        maxWidth: "270px",
      },
    },
    "& > div": {
      width: "200px",
      height: "200px",
      [theme.breakpoints.up("md")]: {
        width: "270px",
        height: "270px",
      },
    },
    "& circle": {
      animation: "circleAnimation 1.4s ease-in-out infinite both",
      display: "block",
      fill: "transparent",
      stroke: strokeColor,
      strokeLinecap: "round",
      strokeDasharray: 283,
      strokeDashoffset: 280,
      strokeWidth: "9px",
      transformOrigin: "50% 50%",
    },
    "@keyframes svgAnimation": {
      "0%": {
        transform: "rotateZ(0deg)",
      },
      "100%": {
        transform: "rotateZ(360deg)",
      },
    },
    "@keyframes circleAnimation": {
      "0%": {
        strokeDashoffset: 280,
        transform: "rotate(0)",
      },
      "25%": {
        strokeDashoffset: 280,
        transform: "rotate(0)",
      },
      "50%": {
        strokeDashoffset: 75,
        transform: "rotate(45deg)",
      },
      "75%": {
        strokeDashoffset: 75,
        transform: "rotate(45deg)",
      },
      "100%": {
        strokeDashoffset: 280,
        transform: "rotate(360deg)",
      },
    },
  };
});

export const ProgressIndicator = () => {
  return (
    <StyledProgressIndicator>
      <div>
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <circle cx="50" cy="50" r="45" />
        </svg>
      </div>
    </StyledProgressIndicator>
  );
};
