import { createApi } from "@reduxjs/toolkit/query/react";
import { FilesResponse, FoldersResponse, SheetItemsResponse, CommentsResponse } from "./interfaces"; //CommentsResponse,, SheetItemsRespons
import { awsBaseQuery } from "../../config/awsBaseQuery";
import { HttpMethod } from "../../config/interfaces";

export const dueDiligenceApi = createApi({
  reducerPath: "dueDiligence",
  baseQuery: awsBaseQuery(),
  tagTypes: ["Folders", "Files", "Comments", "SheetItems", "SheetItem"],
  endpoints: (builder) => ({
    getFolders: builder.query<FoldersResponse, { id: string }>({
      query: ({ id }) => ({
        path: `/due-diligence/folders/${id}`,
        method: HttpMethod.GET,
      }),
      providesTags: ["Folders"],
    }),
    getFile: builder.query<FilesResponse, { id: number; version: number }>({
      query: ({ id }) => ({
        path: `/due-diligence/files/${id}`,
        method: HttpMethod.GET,
      }),
      providesTags: ["Files"],
    }),
    getComments: builder.query<CommentsResponse, { id: number }>({
      query: ({ id }) => ({
        path: `/due-diligence/comments/${id}`,
        method: HttpMethod.GET,
      }),
      providesTags: ["Comments"],
    }),
    createComment: builder.mutation<void, { fileId: number; text: string }>({
      query: ({ fileId, text }) => ({
        path: `/due-diligence/comments/${fileId}`,
        method: HttpMethod.POST,
        data: {
          body: {
            text,
          },
        },
      }),
    }),
    // To display the very wide table
    getSheetItems: builder.query<SheetItemsResponse, { id: number }>({
      query: ({ id }) => ({
        path: `/due-diligence/isheet/${id}/items`,
        method: HttpMethod.GET,
      }),
      providesTags: ["SheetItems"],
    }),
    //edit sheet item
    getSheetItem: builder.query<any, { id: number; itemId: number }>({
      query: ({ id, itemId }) => ({
        path: `/due-diligence/isheet/${id}/items/${itemId}`,
        method: HttpMethod.GET,
      }),
      providesTags: ["SheetItem"],
    }),
  }),
});

export const {
  useGetFoldersQuery,
  useGetFileQuery,
  useGetCommentsQuery,
  useGetSheetItemsQuery,
  useLazyGetSheetItemQuery,
  useCreateCommentMutation,
} = dueDiligenceApi;
