import { useEffect, useState } from "react";
import { useTransactions } from "../../hooks/useTransactions";
import { useParams } from "react-router-dom";
import { Messenger } from "../DocViewerMessenger/Messenger";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { LoadingIndicator } from "../LoadingIndicator";
import axios from "axios";

export const DocumentViewer = () => {
  const params = useParams();
  const { getFileById } = useTransactions();
  const transactionId = params.transactionId;
  const fileId = params.documentId;
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId = currentUser?.customer?.subscription.product_id;
  const [pdfUrl, setPdfUrl] = useState("");
  const [pdfViewer, setPdfViewer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getPdfUrl = async () => {
      try {
        const file = await getFileById(fileId);
        if (file.file.content_type === "application/pdf") {
          setPdfViewer(true);
          const pdfUrl = file.file?.download_url;
          const results = await axios.get(pdfUrl, {
            responseType: "blob",
          });
          if (results?.data) {
            const res = new File([results?.data], file.file.file_name, {
              type: file.file.content_type,
            });
            const url = URL.createObjectURL(res);
            setPdfUrl(url);
          }
          setIsLoading(false);
        } else {
          setPdfUrl(file?.file.download_url);
          setIsLoading(false);
        }
      } catch (error) {}
    };

    getPdfUrl();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {isLoading && <LoadingIndicator type={"PROGRESS"} />}
      {!isLoading && (
        <div>
          {pdfUrl && pdfViewer && (
            <object
              title="PDF Viewer"
              type="application/pdf"
              data={pdfUrl}
              width="100%"
              height="1000px"
            />
          )}
          {pdfUrl && !pdfViewer && (
            <embed
              width="100%"
              height="1070px"
              src={`https://docs.google.com/viewer?url=${encodeURIComponent(pdfUrl)}&embedded=true`}
            />
          )}
          <Messenger
            transactionId={transactionId}
            productId={productId}
            fileId={fileId}
            style={{ marginLeft: "100px" }}
          />
        </div>
      )}
    </>
  );
};
