import axios from "axios";

import TAwsConfig from "../../../../awsConfig";

/**
 *
 * @return countries data from data_store
 */
export const getCountries = async () => {
  return axios
    .get(`${TAwsConfig.datastore.URL}/data/account/countries.json`)
    .then((resp) => resp.data.data.items)
    .catch(() => []);
};
