import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import { LoginError } from "./LoginError";
import { useChangePassword } from "../hooks";
import { Logo } from "./Logo";
import { ErrorBoundary } from "./ErrorBoundary";
import { FadeIn } from "./Transitions";
import { ContainerWrapper } from "./ContainerWrapper";
import { LoadingIndicator } from "./LoadingIndicator";
import { SimpleLayout } from "../../../../libs/Layout";
import { useAppSelector } from "../Store/hooks/useAppSelector";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../Store/currentUser/currentUserSlice";

const ChangePassword = () => {
  const { t } = useTranslation();
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatchUser = useAppDispatch();
  const [changePassword, loading] = useChangePassword();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  const [showPass, setShowPass] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  // to toggle the password and show it
  const handleClickShowPass = () => {
    setShowPass(!showPass);
  };

  // to toggle the password and show it
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  // to toggle the password and show it
  const handleClickShowRepeatPassword = () => {
    setShowRepeatPassword(!showRepeatPassword);
  };

  // to prevent default, after clicking visibility icon on password TextField
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleForceChange = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");
    const password = data.get("password");
    const repeatPassword = data.get("repeatPassword");
    const temporaryPassword = data.get("temporaryPassword");

    if (currentUser.errors.length > 0) {
      dispatchUser(setCurrentUser({ errors: [] }));
    }
    changePassword({
      email: email || currentUser.email,
      password,
      repeatPassword,
      temporaryPassword: temporaryPassword || "",
    });
  };

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <ContainerWrapper width={"sm"}>
          <FadeIn>
            {loading && <LoadingIndicator type={"COMPONENT"} />}
            <Box
              component="form"
              onSubmit={handleForceChange}
              sx={{
                p: { xs: 2, sm: 3 },
                backgroundColor: theme.palette.common.white,
              }}
            >
              <Stack direction="row" justifyContent="center" sx={{ mb: 5 }}>
                <Logo />
              </Stack>
              {currentUser.email === "" && (
                <TextField
                  margin={isMobile ? "dense" : "normal"}
                  label={t("change.email.label")}
                  name="email"
                  id="change_pass_email"
                  type="email"
                  size={isMobile ? "small" : "medium"}
                  autoFocus
                  required
                  fullWidth
                  disabled={loading}
                  // error={!isEmailValid}
                  // helperText={!isEmailValid ? "Check your E-mail" : null}
                />
              )}
              {currentUser.temporaryPassword === "" && (
                <TextField
                  margin={isMobile ? "dense" : "normal"}
                  label={t("change.temp_password.label")}
                  name="temporaryPassword"
                  id="change_pass_temporaryPassword"
                  type={showPass ? "text" : "password"}
                  size={isMobile ? "small" : "medium"}
                  required
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPass}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPass ? (
                            <VisibilityOff fontSize={isMobile ? "small" : "medium"} />
                          ) : (
                            <Visibility fontSize={isMobile ? "small" : "medium"} />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  disabled={loading}
                />
              )}

              <TextField
                margin={isMobile ? "dense" : "normal"}
                label={t("change.password.label")}
                name="password"
                id="change_pass_password"
                type={showPassword ? "text" : "password"}
                size={isMobile ? "small" : "medium"}
                required
                fullWidth
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityOff fontSize={isMobile ? "small" : "medium"} />
                        ) : (
                          <Visibility fontSize={isMobile ? "small" : "medium"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <TextField
                margin={isMobile ? "dense" : "normal"}
                label={t("change.repeat_password.label")}
                name="repeatPassword"
                id="change_pass_repeatPassword"
                type={showRepeatPassword ? "text" : "password"}
                size={isMobile ? "small" : "medium"}
                required
                fullWidth
                disabled={loading}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowRepeatPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showRepeatPassword ? (
                          <VisibilityOff fontSize={isMobile ? "small" : "medium"} />
                        ) : (
                          <Visibility fontSize={isMobile ? "small" : "medium"} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              {currentUser.errors.length > 0 && <LoginError />}

              <Button
                type="submit"
                color="primary"
                size={isMobile ? "small" : "medium"}
                fullWidth
                variant="contained"
                disabled={loading}
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("change.submit")}
              </Button>
            </Box>
          </FadeIn>
        </ContainerWrapper>
      </ErrorBoundary>
    </SimpleLayout>
  );
};

export default ChangePassword;
