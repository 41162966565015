import React, { useState } from "react";
import {
  ClickAwayListener,
  Popper,
  Paper,
  MenuList,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";

//ActionMenu
const ActionMenu = ({ buttons }: any) => {
  const [openMenu, setOpenMenu] = useState<boolean>(false),
    clickAwayHandler = () => {
      // the click happened outside, close the menu
      setOpenMenu(false);
      setAnchorEl("");
    },
    clickHandler = (e: any) => {
      if (e.currentTarget === anchorEl) {
        // the same menu button was clicked again, close the menu
        setOpenMenu(false);
        setAnchorEl("");
      } else {
        // menu still closed or another button was clicked, open menu
        setOpenMenu(true);
        setAnchorEl(e.currentTarget);
      }
    };
  const [anchorEl, setAnchorEl] = useState<any>("");

  return (
    <div>
      {
        <>
          <IconButton className="ActionMenuButton" onClick={clickHandler} size="medium">
            <MoreVertIcon className="ActionMenuButton" />
          </IconButton>
          {openMenu && (
            <ClickAwayListener onClickAway={clickAwayHandler}>
              <Popper open={openMenu} anchorEl={anchorEl} placement={"right-start"}>
                <Paper sx={{ width: "auto", maxWidth: "100%", padding: "0px" }}>
                  <MenuList
                    sx={{
                      width: "fit-content",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "0px",
                    }}
                  >
                    {buttons.map((button: any) => {
                      return (
                        <MenuItem sx={{ width: "fit-content", padding: "0 10px" }}>
                          <Tooltip title={button.title} placement={"right"} arrow>
                            {button.element}
                          </Tooltip>
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </Paper>
              </Popper>
            </ClickAwayListener>
          )}
        </>
      }
    </div>
  );
};

export default ActionMenu;
