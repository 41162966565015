import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import TAwsConfig from "../../../../../../../awsConfig";
import { CategoriesResponse, Category, CategoryTree, CategoryTreeResponse } from "./interfaces";

export const categoriesApi = createApi({
  reducerPath: "categories",
  baseQuery: fetchBaseQuery({ baseUrl: TAwsConfig.datastore.URL }),
  endpoints: (builder) => ({
    getCategories: builder.query<Category[], void>({
      query: () => "/data/industry/categories.json",
      transformResponse: (response: CategoriesResponse): Category[] => {
        return response.data.items;
      },
    }),
    getCategoryTrees: builder.query<CategoryTree[], void>({
      query: () => "/data/industry/categories.tree.json",
      transformResponse: (response: CategoryTreeResponse): CategoryTree[] => {
        return response.data.tree;
      },
    }),
  }),
});

export const { useGetCategoriesQuery, useGetCategoryTreesQuery } = categoriesApi;
