import React, { useMemo, useState } from "react";
import { useAsyncDebounce } from "react-table";
import { matchSorter } from "match-sorter"; // A great library for fuzzy filtering/sorting items

import MenuItem from "@mui/material/MenuItem";

import { StyledTextField, StyledSelect } from "./styled.tableComp";

import { useTranslation } from "react-i18next";

// to try ColumnFilterMethod
export const ColumnFilter = ({ column }) => {
  const { filterValue, setFilter } = column;
  return (
    <span>
      Suchen:{""}
      <input value={filterValue || ""} onChange={(e) => setFilter(e.target.value)} />
    </span>
  );
};

// Define a default UI for global filtering
export const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
  const { t } = useTranslation();
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  let text = "";
  if (count === 0) {
    text = t("de/common:document_table.result0");
  } else if (count === 1) {
    text = `${count} ${t("de/common:document_table.result")}`;
  } else {
    text = `${count} ${t("de/common:document_table.results")}`;
  }

  return (
    <span>
      {t("de/common:document_table.search")}:{" "}
      <input
        value={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={text}
        style={{
          fontSize: "15px",
          border: "0",
          padding: "10px",
        }}
      />
    </span>
  );
};

// Define a default UI for column filtering
export const DefaultColumnFilter = ({ column: { filterValue, setFilter }, placeholder }) => {
  //const count = preFilteredRows.length;
  //const datei = t("de/common:document_table.data_name");

  return (
    <StyledTextField
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={placeholder}
      sx={{ width: "100%", marginTop: "10px" }}
    />
  );
};

// This is a custom filter UI for selecting a unique option from a list
export const SelectColumnFilter = ({ column: { filterValue, setFilter, preFilteredRows, id } }) => {
  // Calculate the options for filtering
  // using the preFilteredRows
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);

  // Render a multi-select box
  return (
    <StyledSelect
      size="small"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      sx={{ width: "100%", marginTop: "10px" }}
    >
      <MenuItem value="">Alle</MenuItem>
      {options.map((option, i) => (
        <MenuItem key={i} value={option}>
          {option}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};

export const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [(row) => row.values[id]] });
};

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;
