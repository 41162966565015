// https://serverless-stack.com/chapters/upload-a-file-to-s3.html
import { Storage } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";

import { getCovid } from "./covidLib";

/**
 *
 * @param {string} fileUrn - The type of file (= defined in shared/types/urn.js)
 * @param {Object} file - The file object
 * @param {Object[]=} fileAttribs - Optional array of urn/value objects as attributes
 * @return {string} - the file id
 */
export async function s3Upload(fileUrn, file, fileAttribs = null) {
  // allocate new
  const filename = `${uuidv4()}`;

  // mandatory file metadata
  const fileMetadata = {
    urn: fileUrn,
    filename: encodeURIComponent(file.name),
    covid: getCovid(),
  };

  // add passed-in attributes to metadata
  if (fileAttribs && Array.isArray(fileAttribs)) {
    for (const attrib of fileAttribs) {
      if ("urn" in attrib && attrib.urn != null && "value" in attrib && attrib.value != null) {
        fileMetadata[attrib.urn] = attrib.value;
      }
    }
  }

  // see https://docs.amplify.aws/lib/storage/upload/q/platform/js/#upload-files
  await Storage.vault.put(filename, file, {
    contentType: file.type,
    metadata: fileMetadata,
  });

  return filename;
}
