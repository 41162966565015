import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useTheme } from "@mui/material/styles";

import { DownloadLink } from "./styled.downloadFileCard";
import { DocumentType } from "../../Store/api/documents/interfaces";

interface DownloadFileCardProps {
  type: DocumentType;
  fileName: string;
  handleDownloadDocument: (type: DocumentType) => void;
  hasError?: boolean;
  disabled?: boolean;
}

export const DownloadFileCard = ({
  type,
  fileName,
  handleDownloadDocument,
  hasError,
  disabled,
}: DownloadFileCardProps) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <DownloadLink
      onClick={() => handleDownloadDocument(type)}
      sx={{ pointerEvents: disabled ? "none" : "initial" }}
    >
      <Paper
        elevation={3}
        sx={{
          width: "140px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          backgroundColor: disabled ? theme.palette.grey.A200 : theme.palette.secondary.main,
          color: disabled ? theme.palette.grey.A400 : theme.palette.primary.main,

          transition: "all 0.4s",
          "&:hover": {
            color: theme.palette.secondary.main,
            backgroundColor: theme.palette.primary.main,
          },
          border: `${hasError ? "2px solid red" : "none"}`,
        }}
      >
        <DescriptionOutlinedIcon sx={{ fontSize: "50px" }} />
        <Typography variant="body1" sx={{ my: 1, fontWeight: "bold", fontSize: "13px" }}>
          {fileName}
        </Typography>
        {t("misc.download")}
      </Paper>
    </DownloadLink>
  );
};
