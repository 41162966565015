import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

export const DarkBlueButton = styled(Button)(({ theme }) => ({
  color: theme.palette.secondary.main,
  backgroundColor: theme.palette.primary.main,
  fontWeight: "bold",
  borderRadius: "8px",
  padding: "8px 25px",
}));

export const GreenButton = styled(Button)(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.secondary.main,
  fontWeight: "bold",
  borderRadius: "8px",
  padding: "8px 25px",
}));
