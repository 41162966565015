import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useCustomers = () => {
  // to get all customers
  const getAllCustomers = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CUSTOMER_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to get customer by id
  const getCustomerById = (customerId) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.CUSTOMER_API_ENDPOINT}/${customerId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  // to update a customer
  const updateCustomer = (customerId, data) => {
    return API.patch(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.CUSTOMER_API_ENDPOINT}/${customerId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
        body: data,
      }
    );
  };

  const deleteCustomer = (customerId) => {
    return API.del(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.CUSTOMER_API_ENDPOINT}/${customerId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  return {
    getAllCustomers,
    getCustomerById,
    updateCustomer,
    deleteCustomer,
  };
};
