import { useEffect, useState, useCallback, useRef } from "react";
import { useInvestmentTeaser } from "../../hooks/useInvestmentTeaser";
import axios from "axios";

export const useFileUrl = (transactionId) => {
  const [fileUrls, setFileUrls] = useState({});
  const [previewFileUrl, setPreviewFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { getPuplicFile } = useInvestmentTeaser();
  const mountedRef = useRef(true);
  const intervalRef = useRef(null); // Reference to store the interval ID

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedGetPuplicFile = useCallback(getPuplicFile, []);

  const fetchFile = useCallback(async (lang) => {
    if (!transactionId || !lang) return;
    setIsLoading(true);
    try {
      const fileData = await memoizedGetPuplicFile(transactionId);
      if (fileData.files && fileData.files.length > 0) {
        let filteredFiles = fileData.files.filter((file) =>
          file.metadata && file.metadata.some((m) => m.data.language === lang)
        );

        if (filteredFiles.length === 0) {
          filteredFiles = fileData.files;
        }

        const sortedFiles = filteredFiles.sort(
          (a, b) => parseInt(b.uploaded_at) - parseInt(a.uploaded_at)
        );

        if (sortedFiles.length > 0) {
          const mostRecentFile = sortedFiles[0];
          const result = await axios.get(mostRecentFile.download_url, {
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" },
          });
          if (result?.data) {
            const res = new File([result.data], mostRecentFile.file_name, {
              type: mostRecentFile.content_type,
            });
            const fileUrl = URL.createObjectURL(res);
            if (mountedRef.current) {
              setFileUrls((prevFileUrls) => ({
                ...prevFileUrls,
                [lang]: fileUrl,
              }));
            }
            // Clear the interval as a new file is successfully loaded
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }
          }
        }
      }
    } catch (error) {
      // console.error("Error fetching file:", error);
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [transactionId, memoizedGetPuplicFile]);

  const fetchPreviewFile = useCallback(async (lang) => {
    if (!transactionId || !lang) return;
    setIsLoading(true);
    try {
      const fileData = await memoizedGetPuplicFile(transactionId);
      if (fileData.files && fileData.files.length > 0) {
        let filteredFiles = fileData.files.filter((file) =>
          file.metadata && file.metadata.some((m) => m.data.language === lang)
        );

        if (filteredFiles.length === 0) {
          filteredFiles = fileData.files;
        }

        const sortedFiles = filteredFiles.sort(
          (a, b) => parseInt(b.uploaded_at) - parseInt(a.uploaded_at)
        );

        if (sortedFiles.length > 0) {
          const mostRecentFile = sortedFiles[0];
          const result = await axios.get(mostRecentFile.download_url, {
            responseType: "blob",
            headers: { "Cache-Control": "no-cache" },
          });
          if (result?.data) {
            const res = new File([result.data], mostRecentFile.file_name, {
              type: mostRecentFile.content_type,
            });
            const fileUrl = URL.createObjectURL(res);
            if (mountedRef.current) {
              setPreviewFileUrl(fileUrl);
            }
            // Clear the interval as a new preview file is successfully loaded
            if (intervalRef.current) {
              clearInterval(intervalRef.current);
            }
          }
        }
      }
    } catch (error) {
      // console.error("Error fetching file:", error);
    } finally {
      if (mountedRef.current) {
        setIsLoading(false);
      }
    }
  }, [transactionId, memoizedGetPuplicFile]);

  const startPolling = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current); // Clear any existing interval
    }

    intervalRef.current = setInterval(() => {
      fetchFile("en");
      fetchFile("de");
      fetchPreviewFile("en");
      fetchPreviewFile("de");
    }, 30000); // Poll every 30 seconds

  }, [fetchFile, fetchPreviewFile]);

  useEffect(() => {
    mountedRef.current = true;
    if (transactionId) {
      fetchFile("de");
      fetchPreviewFile("de");
      startPolling(); // Start the polling

      return () => {
        clearInterval(intervalRef.current); // Clear interval on unmount
        mountedRef.current = false;
      };
    }
  }, [transactionId, fetchFile, fetchPreviewFile, startPolling]);

  return { fileUrls, previewFileUrl, isLoading };
};
