import React from "react";
import { useTranslation } from "react-i18next";

import MenuItem from "@mui/material/MenuItem";

import { StyledTextField, StyledSelect } from "./styled.detailsCompanyInformationCard";

import { CurrencyFormatter } from "../../formatter/CurrencyFormatter/CurrencyFormatter";

import { PercentageFormatter } from "../../formatter/PercentageFormatter/PercentageFormatter";

// This TextFieldComponent is used in productslist table on 1st and 3rd columns in CompanyInformationCard componnet
export const TextFieldComponent = ({ val, handleChange }) => {
  return (
    <StyledTextField
      name={val.name}
      size="small"
      value={val.value || ""}
      variant="standard"
      sx={{ width: "100%" }}
      onChange={(e) => handleChange(e, val.index)}
      inputProps={{
        currency: "EUR",
        autoComplete: "new-min_cap",
        form: {
          autoComplete: "off",
        },
      }}
    ></StyledTextField>
  );
};

// This TextFieldComponent is used to popup value of umsatz in UnternehmensInfomation table

export const RevenueFieldComponent = ({ val, handleChange }) => {
  return (
    <StyledTextField
      name={val.name}
      size="small"
      value={val.value || ""}
      variant="standard"
      sx={{ width: "100%" }}
      onChange={(e) => handleChange(e, val.index)}
      InputProps={{
        inputComponent: CurrencyFormatter,
      }}
      inputProps={{
        currency: "EUR",
        autoComplete: "new-min_cap",
        form: {
          autoComplete: "off",
        },
        inputMode: "numeric",
      }}
    ></StyledTextField>
  );
};

export const SalesFieldComponent = ({ val, handleChange }) => {
  return (
    <StyledTextField
      name={val.name}
      size="small"
      value={val.value || ""}
      variant="standard"
      sx={{ width: "100%" }}
      onChange={(e) => handleChange(e, val.index)}
      InputProps={{
        inputComponent: PercentageFormatter,
      }}
      inputProps={{
        name: "revenue_volume",
        autoComplete: "val.value",
        inputMode: "numeric",
      }}
    ></StyledTextField>
  );
};

// This EditableSelect is used in productslist table on 2nd column in CompanyInformationCard componnet
export const EditableSelect = ({ val, options, handleChange }) => {
  const { t } = useTranslation();

  // revenue-types Object to assign text from locales
  const revenueTypesObject = t("transaction_tables.revenue_types", {
    returnObjects: true,
  });

  return (
    <StyledSelect
      name={val.name}
      disableUnderline
      size="small"
      variant="standard"
      value={val.value || ""}
      onChange={(e) => handleChange(e, val.index)}
      sx={{ width: "100%" }}
    >
      {options.map((option, i) => (
        <MenuItem key={i} value={option.value}>
          {revenueTypesObject[option.value]}
        </MenuItem>
      ))}
    </StyledSelect>
  );
};
