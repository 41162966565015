import moment from "moment";

export const canBeDeleted = (
  userId: string,
  messageUserId: string,
  productId: string,
  timestamp: number
) => {
  const days = moment().diff(timestamp, "days");
  return (["platform"].includes(productId) || userId === messageUserId) && days < 2;
};
