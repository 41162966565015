import { v4 as uuidv4 } from "uuid";
import { Autocomplete, Box, FormControl, Stack, Typography, useTheme } from "@mui/material";
import { Ownership } from "../../Store/api";
import { useTranslation } from "react-i18next";
import { OwnershipFormValues } from "./interfaces";
import { Controller, useForm } from "react-hook-form";
import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import { PercentageFormatter } from "../../hooks/formatter/PercentageFormatter/PercentageFormatter";
import { CustomTooltip } from "../CustomTooltip";
import IconButton from "@mui/material/IconButton";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import { TableWrapper } from "./styled.Ownerships";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useAppSelector } from "../../Store/hooks/useAppSelector";

export interface OwnershipsProps {
  ownerships: Ownership[];
  onChange: (ownerships: Ownership[]) => void;
}

const initialFormValues: OwnershipFormValues = {
  ownerships: [],
  ownership: {
    id: "",
    description: "",
    value: "",
    type: "",
    email: "",
  },
};

export const Ownerships = ({ ownerships, onChange }: OwnershipsProps) => {
  const theme = useTheme();
  const { t } = useTranslation(["common"]);
  const methods = useForm<OwnershipFormValues>({
    mode: "all",
    defaultValues: initialFormValues,
  });
  const {
    watch,
    setValue,
    control,
    setError,
    // formState: { errors },
  } = methods;
  const { id, description, value, type, email } = watch("ownership");

  //handle the Type of Company

  const [selectedType, setSelectedType] = useState("");
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const owner_types = currentUser.datastore.owner_type;
  const ownerTypes: { [key: string]: string } = t("elements.ownertype", { returnObjects: true });
  const ownerTypesArr = Object.keys(ownerTypes).map((code) => ({
    code,
    value: ownerTypes[code],
  }));

  const values =
    ownerships.length > 0
      ? ownerships.map((item) => (item.id !== id ? Number(item.value) : 0))
      : false;
  const ownerSum = Array.isArray(values)
    ? values.reduce((total: number, item: number) => Number(total) + Number(item))
    : false;

  const handleOwnership = () => {
    if (!description) {
      setError("ownership.description", { message: t("misc.required_field") });
    }

    if (!value) {
      setError("ownership.value", { message: t("misc.required_field") });
    }

    if (!type) {
      setError("ownership.type", { message: t("misc.required_field") });
    }

    if (!email) {
      setError("ownership.email", { message: t("misc.required_field") });
    }

    if (!!description && !!value && !!type && !!email) {
      if (id) {
        const newOwnerships = ownerships.map((ownership) => {
          if (ownership.id !== id) {
            return ownership;
          } else {
            return { id, description, value, type, email };
          }
        });
        onChange(newOwnerships);
      } else {
        const newOwnerships = [...ownerships, { id: uuidv4(), description, value, type, email }];
        onChange(newOwnerships);
      }
      setValue("ownership.id", "");
      setValue("ownership.description", "");
      setValue("ownership.value", "");
      setValue("ownership.type", "");
      setValue("ownership.email", "");
      setSelectedType("");
    }
  };

  const handleDeleteOwnership = (id: string) => {
    const updatedOwnerships = ownerships.filter((ownership) => ownership.id !== id);
    onChange(updatedOwnerships);
  };

  const handleEditOwnership = (id: string) => {
    const selectedOwnership = ownerships.find((ownership) => ownership.id === id);
    if (selectedOwnership) {
      setValue("ownership.id", selectedOwnership.id);
      setValue("ownership.description", selectedOwnership.description);
      setValue("ownership.value", selectedOwnership.value);
      setValue("ownership.type", selectedOwnership.type);
      setValue("ownership.email", selectedOwnership.email);
      const selected_owner_type = ownerTypesArr?.find(
        (type) => type.code === selectedOwnership.type
      )?.value;
      selected_owner_type && setSelectedType(selected_owner_type);
    }
  };

  return (
    <Stack direction="column" alignItems="stretch" width="100%">
      {
        <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 3 }}>
          <TableWrapper>
            <caption>{t("elements.ownerships.header")}</caption>

            <thead>
              <tr>
                <th style={{ width: "24%" }}>{t("elements.ownerships.description")}</th>
                <th style={{ width: "24%" }}>{t("elements.ownerships.value")}</th>
                <th style={{ width: "24%" }}>{t("elements.ownerships.type")}</th>
                <th style={{ width: "24%" }}>{t("elements.ownerships.email")}</th>
                <th>{t("elements.ownerships.action")}</th>
              </tr>
            </thead>

            <tbody>
              {ownerships.map((ownership, idx) => (
                <tr key={idx}>
                  <td style={{ width: "24%" }}>{ownership.description}</td>
                  <td style={{ width: "24%" }}>{ownership.value} %</td>
                  <td style={{ width: "24%" }}>
                    {ownerTypesArr.map((type) => (type.code === ownership?.type ? type.value : ""))}
                  </td>
                  <td style={{ width: "24%" }}>{ownership.email}</td>
                  <td
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <CustomTooltip title={t("elements.ownerships.delete")} placement="top" arrow>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleDeleteOwnership(ownership.id)}
                      >
                        <PersonRemoveOutlinedIcon />
                      </IconButton>
                    </CustomTooltip>
                    <CustomTooltip title={t("elements.ownerships.edit")} placement="top" arrow>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleEditOwnership(ownership.id)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </CustomTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </TableWrapper>
        </Stack>
      }

      <Typography
        variant="subtitle1"
        sx={{
          color: theme.palette.info.dark,
          fontWeight: "bold",
          mb: 1,
          marginTop: "20px",
        }}
      >
        {t("elements.ownerships.add")}
      </Typography>

      {
        <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 0 }}>
          <TableWrapper style={{ border: "0px" }}>
            <tbody>
              {
                <tr key={"ownership_form"}>
                  <td style={{ width: "24%", border: "0px" }}>
                    <FormControl fullWidth size="small">
                      <Controller
                        name="ownership.description"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            sx={{ width: "97%", marginX: "auto" }}
                            label={t("elements.ownerships.description")}
                            value={value}
                            error={!!error?.message}
                            helperText={error?.message}
                            size="small"
                            fullWidth
                            onChange={onChange}
                            disabled={ownerSum === 100 && !id}
                          />
                        )}
                      />
                    </FormControl>
                  </td>

                  <td style={{ width: "24%", border: "0px" }}>
                    <FormControl fullWidth size="small">
                      <Controller
                        name="ownership.value"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => {
                          return (
                            <TextField
                              sx={{ width: "97%", marginX: "auto" }}
                              label={t("elements.ownerships.value")}
                              InputProps={{
                                inputComponent: PercentageFormatter as any,
                              }}
                              inputProps={{
                                name: "ownership.value",
                                total: ownerSum || 0,
                                autoComplete: "ownership.value",
                                inputMode: "numeric",
                              }}
                              value={value}
                              error={!!error?.message}
                              helperText={error?.message}
                              size="small"
                              fullWidth
                              onChange={onChange}
                              disabled={ownerSum === 100 && !id}
                            />
                          );
                        }}
                      />
                    </FormControl>
                  </td>
                  <td style={{ width: "24%", border: "0px", paddingTop: "20px" }}>
                    <FormControl fullWidth size="small">
                      <Controller
                        name="ownership.type"
                        control={control}
                        render={({ field }) => (
                          <Autocomplete
                            id="elements.ownerships.type"
                            options={ownerTypesArr.map((type) => type.value)}
                            sx={{ width: "97%", marginX: "auto" }}
                            getOptionLabel={(option) => option}
                            value={selectedType === "" ? null : selectedType}
                            size="small"
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label={t("elements.ownerships.type")}
                                inputProps={{ ...params.inputProps, readOnly: true }}
                              />
                            )}
                            fullWidth
                            onChange={(_e, newVal) => {
                              const selectedType = ownerTypesArr.find(
                                (type) => type.value === newVal
                              );
                              setSelectedType(selectedType ? selectedType.value : "");

                              const typesData = owner_types.find(
                                (data: any) => data.value === selectedType?.code
                              );
                              field.onChange(typesData ? typesData.value : "");
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option === value || (value === "" && option === "")
                            }
                            disabled={ownerSum === 100 && !id}
                          />
                        )}
                      />
                      <Typography
                        variant="caption"
                        color="grey"
                        sx={{ fontSize: "10px ", marginLeft: "5px", textAlign: "left" }}
                      >
                        {t("misc.select")}
                      </Typography>
                    </FormControl>
                  </td>
                  <td style={{ width: "24%", border: "0px" }}>
                    <FormControl fullWidth size="small">
                      <Controller
                        name="ownership.email"
                        control={control}
                        render={({ field: { value, onChange }, fieldState: { error } }) => (
                          <TextField
                            sx={{ width: "97%", marginX: "auto" }}
                            label={t("elements.ownerships.email")}
                            value={value}
                            error={!!error?.message}
                            helperText={error?.message}
                            size="small"
                            fullWidth
                            onChange={onChange}
                            disabled={ownerSum === 100 && !id}
                          />
                        )}
                      />
                    </FormControl>
                  </td>
                  <td
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      border: "0px",
                      paddingTop: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        border: `1px solid ${theme.palette.grey[400]}`,
                        height: "40px",
                        width: "40px",
                        padding: "2px",
                        borderRadius: "4px",
                        "&:hover": {
                          borderColor: theme.palette.primary.main,
                        },
                      }}
                    >
                      {
                        <CustomTooltip
                          title={!!id ? t("misc.edit") : t("misc.add")}
                          placement="top"
                          arrow
                        >
                          <IconButton
                            color="primary"
                            size="small"
                            onClick={handleOwnership}
                            disabled={ownerSum === 100 && !id}
                          >
                            {!!id ? <AutorenewOutlinedIcon /> : <PersonAddOutlinedIcon />}
                          </IconButton>
                        </CustomTooltip>
                      }
                    </Box>
                  </td>
                </tr>
              }
            </tbody>
          </TableWrapper>
        </Stack>
      }
    </Stack>
  );
};
