import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import SaveAsIcon from "@mui/icons-material/SaveAs";

import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

import { Box, FormHelperText } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FadeIn, Alert, CustomTooltip } from "../";

import { TableWrapper } from "./styled.capitalSecondary.js";
import {
  useUpdateTransactionMutation,
  useGetTransactionByIdQuery,
} from "../../Store/api/transactions/transactionsApi";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { SecondaryDialog } from "../";

export const CapitalSecondaryDetails = ({ transactionId, setDetailsTransaction }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [updateTransaction] = useUpdateTransactionMutation();
  const transactionByIdQuery = useGetTransactionByIdQuery({ transactionId });
  const transaction = transactionByIdQuery.data;
  const dispatch = useDispatch();

  const [sellerProfile, setSellerProfile] = useState({
    first_name: "",
    last_name: "",
    street: "",
    city: "",
    postal_code: "",
    country: "",
    form_state: {
      initial: true,
      valid: false,
    },
    email: "",
  });

  const metadata = transaction?.metadata ? transaction.metadata : [];
  const secondaryScope = metadata?.find((item) => item.scope === "secondary");
  let sellerArr = useMemo(() => {
    return secondaryScope ? secondaryScope.data.adresses : [];
  }, [secondaryScope]);
  const [sellerProfiles, setSellerProfiles] = useState(sellerArr || false);

  const [sellerIdToDelete, setSellerIdToDelete] = useState(false);
  const [sellerIdToUpdate, setSellerIdToUpdate] = useState(false);

  const [isGermany, setIsGermany] = useState(true);
  // const defaultCountry = sellerProfile?.country;

  // eslint-disable-next-line no-unused-vars
  const [alertState, setAlertState] = useState({
    show: false,
    type: "",
    message: "",
  });

  // to show/hide secondary dialog for user delete event
  // eslint-disable-next-line no-unused-vars
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

  // Country options for select "Land"
  const countries = t("company/common:onboarding.countries", {
    returnObjects: true,
  });

  const countriesArr = Object.keys(countries).map((code) => ({
    code,
    name: countries[code],
  }));

  // handle change event in input fields
  const handleSellerDataChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    setSellerProfile({
      ...sellerProfile,
      [field]: value,
    });
  };

  //reset seller profile
  const resetSellerProfile = () => {
    setSellerProfile({
      first_name: "",
      last_name: "",
      street: "",
      city: "",
      postal_code: "",
      country: "",
      form_state: {
        initial: true,
        valid: false,
      },
      email: "",
    });
  };

  const handleDeleteUserWarningDialogOpen = (index) => {
    setDeleteUserDialogOpen(true);
    setSellerIdToDelete(index);
  };
  const handleDeleteUserDialogClose = () => {
    setDeleteUserDialogOpen(false);
    return;
  };

  const handleDeleteSeller = async () => {
    const result = await transactionByIdQuery.refetch();

    if (sellerIdToDelete !== false) {
      dispatch(
        setCurrentUser({
          loading: true,
        })
      );
      const profiles = sellerProfiles.filter((_, idx) => idx !== sellerIdToDelete);
      setDeleteUserDialogOpen(false);
      setSellerProfiles(profiles);
      if (setDetailsTransaction) {
        setDetailsTransaction({
          ...result.data,
          metadata: [
            ...result.data.metadata.filter((item) => item.scope !== "secondary"),
            {
              scope: "secondary",
              data: {
                adresses: profiles,
              },
            },
          ],
        });
      }

      const metadataIndex = result.data?.metadata.findIndex((data) => data.scope === "secondary");

      if (metadataIndex === -1) {
        const updatedTransaction = {
          ...result.data,
          metadata: [...result.data.metadata, { scope: "secondary", data: { adresses: profiles } }],
        };

        await updateTransaction({
          transactionId: transactionId,
          body: updatedTransaction,
        });
      } else {
        const updatedMetadata = [...result.data.metadata];
        updatedMetadata[metadataIndex] = {
          scope: "secondary",
          data: { adresses: Array.isArray(profiles) ? profiles : [] },
        };
        const updatedTransaction = {
          ...result.data,
          metadata: updatedMetadata,
        };

        await updateTransaction({
          transactionId: transactionId,
          body: updatedTransaction,
        });
      }

      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
      resetSellerProfile();
    }
    setSellerIdToDelete(false);
  };
  const isValidLengthString = (input, length = 1) => {
    if (input === undefined) return false;
    if (length < 0) return false;
    return input.trim().length >= length;
  };

  // to check whether user input fields are valid or not
  const isValidUserField = (name, initial = true) => {
    if (initial && sellerProfile.form_state.initial) return true;
    switch (name) {
      case "first_name":
        return isValidLengthString(sellerProfile[name], 1);
      case "last_name":
        return isValidLengthString(sellerProfile[name], 1);
      case "street":
        return isValidLengthString(sellerProfile[name], 1);
      case "city":
        return isValidLengthString(sellerProfile[name], 1);
      case "postal_code":
        return isValidLengthString(sellerProfile["postal_code"], 1);
      // case "postal_code":
      //   return checkPostalCodeNumber(sellerProfile[name]);
      case "country":
        return isValidLengthString(sellerProfile[name], 1);
      case "email":
        return isValidLengthString(sellerProfile[name], 1);
      default:
        return false;
    }
  };

  //add the new seller
  const handleAddSeller = async () => {
    try {
      const result = await transactionByIdQuery.refetch();

      if (sellerProfile.form_state.initial) {
        setSellerProfile({
          ...sellerProfile,
          form_state: {
            initial: false,
            valid: sellerProfile.form_state.valid,
          },
        });
      }

      const allFieldsAreValid =
        isValidUserField("first_name", false) &&
        isValidUserField("last_name", false) &&
        isValidUserField("street", false) &&
        isValidUserField("city", false) &&
        isValidUserField("postal_code", false) &&
        isValidUserField("country", false) &&
        isValidUserField("email", false);

      if (allFieldsAreValid !== sellerProfile.form_state.valid) {
        dispatch(
          setCurrentUser({
            loading: true,
          })
        );

        let profiles = [];
        if (sellerIdToUpdate !== false) {
          profiles = sellerProfiles;
          profiles = profiles.map((item, id) => (id === sellerIdToUpdate ? sellerProfile : item));
          setSellerIdToUpdate(false);
        } else {
          profiles = [...sellerProfiles, sellerProfile];
        }

        setSellerProfiles(profiles);

        if (setDetailsTransaction) {
          setDetailsTransaction({
            ...result.data,
            metadata: [
              ...result.data.metadata.filter((item) => item.scope !== "secondary"),
              {
                scope: "secondary",
                data: {
                  adresses: profiles,
                },
              },
            ],
          });
        }

        const metadataIndex = result.data?.metadata.findIndex((data) => data.scope === "secondary");

        if (metadataIndex === -1) {
          const updatedTransaction = {
            ...result.data,
            metadata: [
              ...result.data.metadata,
              { scope: "secondary", data: { adresses: profiles } },
            ],
          };

          await updateTransaction({
            transactionId: transactionId,
            body: updatedTransaction,
          });
        } else {
          const updatedMetadata = [...result.data.metadata];
          updatedMetadata[metadataIndex] = {
            scope: "secondary",
            data: { adresses: Array.isArray(profiles) ? profiles : [] },
          };
          const updatedTransaction = {
            ...result.data,
            metadata: updatedMetadata,
          };

          await updateTransaction({
            transactionId: transactionId,
            body: updatedTransaction,
          });
        }

        dispatch(
          setCurrentUser({
            loading: false,
          })
        );

        setAlertState({
          show: false,
          type: "",
          message: "",
        });

        resetSellerProfile();
      }
    } catch (e) {
      dispatch(
        setCurrentUser({
          loading: false,
        })
      );
    }
  };

  const handleEditSeller = (id) => {
    setSellerIdToUpdate(id);
    setSellerProfile(sellerProfiles[id]);
  };

  useEffect(() => {
    setSellerProfiles(sellerArr);
  }, [sellerArr]);

  return (
    <>
      <Stack direction="column">
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "stretch", md: "flex-start" }}
          spacing={{ xs: 2, md: 1 }}
          sx={{}}
        >
          <TextField
            label={t("details_a3.contact_card.first_name")}
            name="first_name"
            id="contact_card_first_name"
            value={sellerProfile.first_name || ""}
            size="small"
            required
            onChange={handleSellerDataChange}
            error={!isValidUserField("first_name")}
            helperText={!isValidUserField("first_name") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-first_name",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{ minWidth: "12%" }}
          />

          <TextField
            label={t("details_a3.contact_card.last_name")}
            name="last_name"
            id="contact_card_last_name"
            value={sellerProfile.last_name || ""}
            size="small"
            required
            onChange={handleSellerDataChange}
            error={!isValidUserField("last_name")}
            helperText={!isValidUserField("last_name") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-last_name",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{ minWidth: "12%" }}
          />
          <TextField
            label={"E-Mail"}
            name="email"
            id="contact_card_email"
            value={sellerProfile.email || ""}
            size="small"
            required
            onChange={handleSellerDataChange}
            error={!isValidUserField("email")}
            helperText={!isValidUserField("email") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-email",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{ minWidth: "12%" }}
          />
          <TextField
            label={t("details_a3.company_card.street")}
            name="street"
            id="company_card_street"
            value={sellerProfile.street || ""}
            size="small"
            required
            onChange={handleSellerDataChange}
            error={!isValidUserField("street")}
            helperText={!isValidUserField("street") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-street",
              form: {
                autoComplete: "off",
              },
            }}
            sx={{ minWidth: "15%", width: "100%" }}
          />
          <TextField
            label={t("details_a3.company_card.city")}
            name="city"
            id="company_card_city"
            value={sellerProfile.city || ""}
            size="small"
            required
            onChange={handleSellerDataChange}
            error={!isValidUserField("city")}
            helperText={!isValidUserField("city") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-city",
              form: {
                autoComplete: "off",
              },
              onInput: (e) => {
                e.currentTarget.value = e.currentTarget.value.replace(/[^A-Za-z]/g, "");
              },
            }}
            sx={{ width: "50%" }}
          />
          <TextField
            label={t("details_a3.company_card.postal_code")}
            name="postal_code"
            id="company_card_postal_code"
            value={
              sellerProfile?.country === "DEU"
                ? (sellerProfile.postal_code || "").substring(0, 5)
                : sellerProfile.postal_code || ""
            }
            size="small"
            required
            onChange={handleSellerDataChange}
            error={!isValidUserField("postal_code")}
            helperText={!isValidUserField("postal_code") && t("misc.required_field")}
            inputProps={{
              autoComplete: "new-postal_code",
              form: {
                autoComplete: "off",
              },
              maxLength: isGermany ? 5 : undefined,
              onInput: (e) => {
                e.currentTarget.value = e.currentTarget.value.replace(/\D/g, "");
              },
            }}
            sx={{ width: "50%" }}
          />
          <FormControl fullWidth size="small">
            <InputLabel error={!isValidUserField("country")} id="country">
              {t("details_a3.company_card.country")}
            </InputLabel>
            <Select
              label={t("details_a3.company_card.country")}
              name="country"
              labelId="country"
              size="small"
              required
              id="company_card_country"
              value={sellerProfile.country || ""}
              onChange={handleSellerDataChange}
              error={!isValidUserField("country")}
              inputProps={{
                autoComplete: "new-country",
                form: {
                  autoComplete: "off",
                },
              }}
              onBlur={(e) => (e.target.value === "DEU" ? setIsGermany(true) : setIsGermany(false))}
              sx={{ minWidth: "15%", width: "100%" }}
            >
              {countriesArr.map((country) => (
                <MenuItem key={country.code} value={country.code}>
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {!isValidUserField("country") && (
              <FormHelperText color="error">{t("misc.required_field")}</FormHelperText>
            )}
          </FormControl>
          <Box
            sx={{
              border: `1px solid ${theme.palette.grey[400]}`,
              height: "40px",
              width: "40px",
              borderRadius: "4px",
              "&:hover": {
                borderColor: theme.palette.primary.main,
              },
            }}
          >
            <CustomTooltip
              title={sellerIdToUpdate !== false ? t("misc.save") : t("misc.add")}
              placement="top"
              arrow
            >
              <IconButton color="primary" size="small" onClick={handleAddSeller}>
                {sellerIdToUpdate !== false ? <SaveAsIcon /> : <PersonAddOutlinedIcon />}
              </IconButton>
            </CustomTooltip>
          </Box>
        </Stack>

        {alertState.show && (
          <Stack sx={{ mt: 2 }}>
            <FadeIn>
              <Alert type={alertState.type}>{alertState.message}</Alert>
            </FadeIn>
          </Stack>
        )}

        <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 3 }}>
          <TableWrapper>
            <caption>{t("details_a3.interest_card.seller_list")}</caption>
            <thead>
              <tr>
                <th>{t("details_a3.contact_card.first_name")}</th>
                <th>{t("details_a3.contact_card.last_name")}</th>
                <th>{"E-Mail"}</th>
                <th>{t("details_a3.company_card.street")}</th>
                <th>{t("details_a3.company_card.city")}</th>
                <th>{t("details_a3.company_card.postal_code")}</th>
                <th>{t("details_a3.company_card.country")}</th>
                <th>{t("user_management_in_contact.list_users.action")}</th>
              </tr>
            </thead>
            <tbody>
              {sellerProfiles.length > 0 &&
                sellerProfiles.map(
                  (
                    { first_name, last_name, email, street, city, postal_code, country, metadata },
                    ind
                  ) => {
                    return (
                      <tr key={ind}>
                        <td style={{ width: "12.2%" }}>{first_name}</td>
                        <td style={{ width: "12.5%" }}>{last_name}</td>
                        <td style={{ width: "24%" }}>{email}</td>
                        <td style={{ width: "24%" }}>{street}</td>
                        <td style={{ width: "12%" }}>{city}</td>
                        <td style={{ width: "12.5%" }}>{postal_code}</td>
                        <td style={{ width: "20%" }}>
                          {countriesArr.map((land) => (land.code === country ? land.name : ""))}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <CustomTooltip title={t("misc.edit")} placement="top" arrow>
                            <IconButton
                              size="small"
                              aria-label="edit_seller"
                              color="primary"
                              onClick={() => handleEditSeller(ind)}
                            >
                              <EditOutlinedIcon />
                            </IconButton>
                          </CustomTooltip>

                          <CustomTooltip title={t("misc.delete")} placement="top" arrow>
                            <IconButton
                              color="primary"
                              size="small"
                              onClick={() => handleDeleteUserWarningDialogOpen(ind)}
                            >
                              <PersonRemoveOutlinedIcon />
                            </IconButton>
                          </CustomTooltip>
                        </td>
                      </tr>
                    );
                  }
                )}
              {sellerProfiles.length === 0 && (
                <tr>
                  <td style={{ textAlign: "left" }} colSpan={6}>
                    {t("details_a3.interest_card.no_seller")}
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
        </Stack>
      </Stack>

      {/* Secondary Dialog for user delete confirmation */}
      <SecondaryDialog
        type="warning"
        dialogTitle={t("top_bar.user_management_dialog.warning_dialog_title")}
        contentText={t("top_bar.user_management_dialog.warning_dialog_text")}
        secondaryDialogOpen={deleteUserDialogOpen}
        secondaryDialogClose={handleDeleteUserDialogClose}
        eventHandler={handleDeleteSeller}
        actionButtonText={t("misc.delete")}
      />
    </>
  );
};
