import React from "react";
import moment from "moment";
import { IconButton, Typography } from "@mui/material";
import { canBeDeleted } from "./MessengerUtils";
import PersonRemoveOutlinedIcon from "@mui/icons-material/Delete";

import {
  Hour,
  MessageBottomContainer,
  MessageBubble,
  MessageContainer,
  MessageHeader,
  MessageText,
  MessengerContainer,
  Name,
} from "./Messenger.styles";
import { Comment } from "./interfaces";

interface MessagesComponentProps {
  messages: Comment[];
  divider: number;
  userId: string;
  productId: string;
  isDeleting: boolean;
  isSubmitting: boolean;
  handleDeleteMessage: (id: string) => void;
}
const MessagesComponent: React.FC<MessagesComponentProps> = ({
  messages,
  divider,
  userId,
  productId,
  isDeleting,
  isSubmitting,
  handleDeleteMessage,
}) => {
  return (
    <>
      {messages.length > 0 && (
        <MessengerContainer className={messages.length === 0 ? "empty" : ""}>
          {messages.map((message: any, i: number) => {
            return (
              <React.Fragment key={message.id}>
                {i === 0 && divider === -1 && (
                  <Typography textAlign={"center"} width={"100%"} color={"white"}>
                    {"Neue Anmerkungen"}
                  </Typography>
                )}
                <MessageContainer
                  key={message.id}
                  align={userId === message.user ? "flex-end" : "flex-start"}
                >
                  <MessageBubble className={userId !== message.user ? message.role : ""}>
                    <MessageHeader>
                      <Name>
                        {message.userName} - {message.orgName ? message.orgName : ""}
                      </Name>
                      <Hour>{moment(message.timestamp).format("DD.MM.YY - HH:mm")}</Hour>
                    </MessageHeader>
                    <MessageBottomContainer>
                      <MessageText
                        disabled
                        multiline
                        value={message.msg}
                        fullWidth
                        variant="standard"
                      />
                      {canBeDeleted(userId, message.user, productId, message.timestamp) && (
                        <IconButton
                          color={"primary"}
                          disabled={isDeleting || isSubmitting}
                          size="small"
                          onClick={() => handleDeleteMessage(message.id)}
                        >
                          <PersonRemoveOutlinedIcon />
                        </IconButton>
                      )}
                    </MessageBottomContainer>
                  </MessageBubble>
                </MessageContainer>
                {i === divider && divider + 1 !== messages.length && (
                  <Typography
                    textAlign={"center"}
                    width={"100%"}
                    fontWeight={"bold"}
                    color={"#004e92"}
                    sx={{ borderBottom: "1px solid #004e9280" }}
                  >
                    {"Neue Anmerkungen"}
                  </Typography>
                )}
              </React.Fragment>
            );
          })}
        </MessengerContainer>
      )}
    </>
  );
};

export default MessagesComponent;
