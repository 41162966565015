import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (...event: any[]) => void;
  currency?: string;
  name: string;
  value: string;
}
export const CurrencyFormatter = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange, currency, name, value, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value.split(".").join(""),
          },
        });
      }}
      value={value}
      type="text"
      decimalSeparator=","
      thousandSeparator="."
      // suffix={` ${currency}`}
      allowNegative={false}
    />
  );
});
