import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { font_faces } from "../Theme/font_faces";

const Colors = {
  freshgreen: "#18d2a3",
  anthracite: "#3c3c3c",
  darkblue: "#010729",
  blue: "#004e92",
  blue_active: "#001f4f",
  greys: {
    first: "#DBDBDB",
    second: "#676767",
    third: "#444444",
    fourth: "#333333",
  },
};

export const theme = responsiveFontSizes(
  createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 481,
        md: 769,
        lg: 1025,
        xl: 1440,
      },
    },
    palette: {
      primary: {
        main: Colors.darkblue,
      },
      secondary: {
        main: Colors.freshgreen,
      },
      anthracite: {
        main: Colors.anthracite,
      },
      blue: {
        main: Colors.blue,
        active: Colors.blue_active,
      },
      greys: {
        first: {
          main: Colors.greys.first,
        },
        second: {
          main: Colors.greys.second,
        },
        third: {
          main: Colors.greys.third,
        },
        fourth: {
          main: Colors.greys.fourth,
        },
      },
    },

    typography: {
      fontFamily: "Lato, sans-serif",
      fontSize: 14,
    },

    components: {
      MuiCssBaseline: {
        styleOverrides: font_faces,
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            color: "#d32f2f",
          },
        },
      },
    },
  })
);
