import { styled, useTheme } from "@mui/material/styles";
import { rgba } from "polished";

const useStyledAlertTheme = (type) => {
  const theme = useTheme();

  switch (type) {
    case "SUCCESS":
      return [rgba(theme.palette.success.main, 0.1), theme.palette.success.main];
    case "ERROR":
      return [rgba(theme.palette.error.main, 0.1), theme.palette.error.main];
    case "WARNING":
      return [rgba(theme.palette.warning.main, 0.75), theme.palette.warning.main];
    case "INFO":
      return [rgba(theme.palette.info.main, 0.1), theme.palette.info.main];
    case "BASIC":
    default:
      return [theme.palette.grey[300], theme.palette.grey[600]];
  }
};

export const StyledAlert = styled("div")(({ type }) => {
  const [background] = useStyledAlertTheme(type);
  return {
    width: "100%",
    borderRadius: "10px",
    animation: "fadeIn 2s",
    display: "flex",
    alignItems: "center",
    backgroundColor: background,
    "@keyframes fadeIn": {
      "0%": {
        opacity: 0,
      },
      "100%": {
        opacity: 1,
      },
    },
  };
});

export const StyledAlertIcon = styled("div")(({ type }) => {
  const [, color] = useStyledAlertTheme(type);
  return {
    padding: "0.5rem",
    borderRadius: "10px 0 0 10px",
    color: color,
    stroke: color,
    display: "flex",
    alignSelf: "stretch",
    alignItems: "center",
    "& path": {
      color: color,
      stroke: color,
    },
  };
});

export const StyledAlertText = styled("div")(() => {
  return {
    padding: "0.5rem",
  };
});
