export function useCustomFilters(id) {
  const filterRevenue = (value) => {
    if (value === "0") {
      return ["0", "1M", "2M", "5M", "10M", "15M", "20M", "30M", "50M", "100M"];
    } else if (value === "1M") {
      return ["1M", "2M", "5M", "10M", "15M", "20M", "30M", "50M", "100M"];
    } else if (value === "2M") {
      return ["2M", "5M", "10M", "15M", "20M", "30M", "50M", "100M"];
    } else if (value === "5M") {
      return ["5M", "10M", "15M", "20M", "30M", "50M", "100M"];
    } else if (value === "10M") {
      return ["10M", "15M", "20M", "30M", "50M", "100M"];
    } else if (value === "15M") {
      return ["15M", "20M", "30M", "50M", "100M"];
    } else if (value === "20M") {
      return ["20M", "30M", "50M", "100M"];
    } else if (value === "30M") {
      return ["30M", "50M", "100M"];
    } else if (value === "50M") {
      return ["50M", "100M"];
    } else if (value === "100M") {
      return ["100M"];
    }
  };

  const filterEbitda = (value) => {
    if (value === "") {
      return [];
    } else if (value === "negative") {
      return ["negative"];
    } else if (value === "0-100k") {
      return ["0", "100k"];
    } else if (value === "100k-500k") {
      return ["100k", "250k", "500k"];
    } else if (value === "500k-2M") {
      return ["500k", "1M", "2M"];
    } else if (value === "2M-10M") {
      return ["2M", "5M", "10M"];
    }
  };
  const filterInvest = (range) => {
    if (id === "invest") {
      if (range === "0-100k") {
        return [0, 100000];
      } else if (range === "100k-500k") {
        return [100000, 500000];
      } else if (range === "500k-1M") {
        return [500000, 1000000];
      } else if (range === "1M-5M") {
        return [1000000, 5000000];
      } else if (range === "5M-10M") {
        return [5000000, 10000000];
      } else if (range === ">10M") {
        return [10000000];
      }
    }
  };

  const filterCategories = (categories) => {
    return categories.filter((cat) => cat !== undefined);
    // if (cat[0] === undefined) {
    //   return [cat[1]]
    // } else {
    //   return [...cat[0], cat[1]]
    // }
  };

  return {
    filterRevenue,
    filterEbitda,
    filterInvest,
    filterCategories,
  };
}
