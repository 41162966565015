let existingInvite = null;

//console.log("Triggered inviteState.js"); 

export const setExistingInvite = (invite) => {
  //console.log("setExistingInvite called with:", invite); 
  if (invite === null || invite === undefined) {
    existingInvite = null;
  } else {
    existingInvite = invite;
    //console.log("existingInviteState updated to:", existingInvite);
  }
};

export const getExistingInvite = () => existingInvite;
