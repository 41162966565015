import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Card, CategoriesDropdown } from "../../";
import { FieldSet } from "./styled.CustomInvestorForm";
import { Section } from "../../Section/Section";
import { CurrencyFormatter } from "../../../formatter/CurrencyFormatter/CurrencyFormatter";

import validator from "validator";
import useCustomInvestorForm from "./useCustomInvestorForm";
import { useAppSelector } from "../../../Store/hooks/useAppSelector";
import { useTranslation } from "react-i18next";
import { useValidateEmail } from "../../../hooks";

import { InvestorPeriod, InvestorStake } from "../../../Store/api";
import { CustomInvestor } from "../../../Store/interfaces";
import { Controller, FormProvider } from "react-hook-form";

import { ContributionKeyType } from "../../../Store/interfaces";

interface CustomInvestorProps {
  addCustomInvestor: (investor: CustomInvestor) => void;
}

const CustomInvestorForm = ({ addCustomInvestor }: CustomInvestorProps) => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const theme = useTheme();
  const {
    methods,
    submitForm,
    open,
    toggleOpen,
    convertToObjValue,
    handleAutoCompleteSelect,
    handleCategorySelect,
    handleContribution,
    initial,
    setInitial,
  } = useCustomInvestorForm(addCustomInvestor);
  const { watch, handleSubmit, control } = methods;
  const { t } = useTranslation(["investor/common"]);
  const { isEmail } = validator; // it will validate email from validator npm package
  const validateEmail = useValidateEmail(); // it will validate email from DB

  //const name = watch("name")
  // const website = watch("website")
  //const city = watch("city")
  // const revenue = watch("revenue")
  // const ebitda = watch("ebitda")
  // const invest = watch("invest")
  const phases = watch("phases");
  const categories = watch("categories");
  const sub_categories = watch("sub_categories");
  const email = watch("email");
  const stake = watch("stake");
  const types = watch("types");
  const period = watch("period");
  const contribution = watch("contribution");

  return (
    <div style={{ margin: "20px 20px" }}>
      <Button
        color="secondary"
        variant="contained"
        onClick={() => toggleOpen()}
        sx={{
          px: 4,
          fontWeight: "bold",
        }}
      >
        {t("platform/common:content.investor_matching.action.add_investor")}
      </Button>

      {currentUser && (
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{
            width: "70%",
            margin: "0px auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Card sx={{ padding: "10px" }}>
            <FormProvider {...methods}>
              <Section isDialog={true} headlineColor="black">
                <Button
                  sx={{ width: "fit-content", alignSelf: "flex-end" }}
                  onClick={() => toggleOpen()}
                >
                  {t("misc.close")}
                </Button>
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                  {/*** NAME ***/}
                  <Controller
                    name="name"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        required
                        onChange={onChange}
                        value={value}
                        label={t("investor/common:content.company.company_name")}
                        size="small"
                        fullWidth
                        helperText={value === "" && t("misc.required_field")}
                      />
                    )}
                  />

                  {/*** EMAIL ***/}
                  <Controller
                    name="email"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.contact.email")}
                        required
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                        error={(!isEmail(email) || !validateEmail(email)) && email !== ""}
                        helperText={
                          email !== "" && !isEmail(email)
                            ? t("error.invalid_email")
                            : email !== "" && !validateEmail(email)
                            ? t("error.email_exists")
                            : email === ""
                            ? t("misc.required_field")
                            : ""
                        }
                      />
                    )}
                  />
                  {/*** WEBSITE ***/}
                  <Controller
                    name="website"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.contact.website")}
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                </Stack>

                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ my: 2 }}
                >
                  <Controller
                    name="line_1"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.company.street")}
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="postal"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.company.postal_code")}
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="city"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.company.city")}
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                      />
                    )}
                  />
                  <Controller
                    name="country"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <Select
                        value={value}
                        label={t("investor/common:content.company.country")}
                        onChange={onChange}
                        size="small"
                        fullWidth
                      >
                        {currentUser.datastore.countries.map((country: { value: string }) => (
                          <MenuItem key={country.value} value={country.value}>
                            {country.value}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Stack>
                <CategoriesDropdown
                  handleCategoriesSelect={handleCategorySelect}
                  selectedCategoryIds={categories}
                  selectedSubCategoryIds={sub_categories}
                  withSubcategories={true}
                  initial={initial}
                  setInitial={setInitial}
                />
                <Stack direction={{ xs: "column", md: "row" }} spacing={{ xs: 2, md: 4 }}>
                  {/*** INVEST ***/}
                  <Controller
                    name="invest"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.investment.invest")}
                        InputProps={{
                          inputComponent: CurrencyFormatter as any,
                        }}
                        inputProps={{
                          currency: "EUR",
                          prefix: t("investor/common:content.investment.prefix_investment") + " ",
                          name: "invest",
                          inputMode: "numeric",
                        }}
                        value={value}
                        size="small"
                        fullWidth
                        onChange={onChange}
                      />
                    )}
                  />

                  {/*** REVENUE ***/}
                  <Controller
                    name="revenue"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.investment.revenue")}
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                        select
                      >
                        {currentUser.datastore.revenue &&
                          currentUser.datastore.revenue.map(
                            (
                              { value, summary }: { value: string; summary: string },
                              index: number
                            ) => (
                              <MenuItem value={value} key={index}>
                                {summary}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    )}
                  />
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ mt: 2 }}
                >
                  {/*** EBITDA ***/}
                  <Controller
                    name="ebitda"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField
                        label={t("investor/common:content.investment.ebitda")}
                        onChange={onChange}
                        value={value}
                        size="small"
                        fullWidth
                        select
                      >
                        {currentUser.datastore.ebitda &&
                          currentUser.datastore.ebitda.map(
                            (
                              { value, summary }: { value: string; summary: string },
                              index: number
                            ) => (
                              <MenuItem value={value} key={index}>
                                {summary}
                              </MenuItem>
                            )
                          )}
                      </TextField>
                    )}
                  />

                  {/*** STAKE ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="stake"
                      options={currentUser.datastore.stake}
                      getOptionLabel={(option: InvestorStake) => option.summary}
                      value={convertToObjValue(stake, "stake") || []}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("investor/common:content.investment.stake")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(_e, value) => handleAutoCompleteSelect(value, "stake")}
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ mt: 2 }}
                >
                  {/*** PERIOD ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="period"
                      options={currentUser.datastore.period}
                      getOptionLabel={(option: InvestorPeriod) => option.summary}
                      value={convertToObjValue(period, "period")}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("investor/common:content.investment.period")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(_e, value) => handleAutoCompleteSelect(value, "period")}
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>

                  {/*** PERIOD ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="types"
                      options={currentUser.datastore.types}
                      getOptionLabel={(option: InvestorPeriod) => option.summary}
                      value={convertToObjValue(types, "types")}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("investor/common:content.investment.types")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(_e, value) => handleAutoCompleteSelect(value, "types")}
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>
                  {/*** PHASES ***/}
                  <FormControl fullWidth size="small">
                    <Autocomplete
                      multiple
                      id="phases"
                      options={currentUser.datastore.phases}
                      getOptionLabel={(option: InvestorPeriod) => option.summary}
                      value={convertToObjValue(phases, "phases")}
                      size="small"
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={t("investor/common:content.investment.phases")}
                          inputProps={{ ...params.inputProps, readOnly: true }}
                        />
                      )}
                      onChange={(_e, value) => handleAutoCompleteSelect(value, "phases")}
                      isOptionEqualToValue={(option, value) => option.summary === value.summary}
                    />
                    <Typography variant="caption" color="grey" sx={{ fontSize: "10px" }}>
                      {t("misc.multi_select")}
                    </Typography>
                  </FormControl>
                </Stack>
                <Stack
                  direction={{ xs: "column", md: "row" }}
                  spacing={{ xs: 2, md: 4 }}
                  sx={{ mt: 2 }}
                >
                  <FieldSet
                    sx={{
                      pb: 1,
                      display: "flex",
                      direction: { xs: "column", md: "row" },
                      justifyContent: "space-evenly",
                      alignItems: "center",
                    }}
                  >
                    <>
                      <legend>{t("investor/common:content.investment.contribution.legend")}</legend>
                      {contribution &&
                        Object.keys(contribution)
                          .map((value) => value as ContributionKeyType)
                          .map((key: ContributionKeyType) => {
                            return (
                              <FormControlLabel
                                name="contribution"
                                key={key}
                                control={
                                  <Checkbox
                                    value={key}
                                    color="primary"
                                    size="small"
                                    checked={contribution[key]}
                                  />
                                }
                                label={
                                  <Typography variant="body1">
                                    {t(`investor/common:content.investment.contribution.${key}`)}
                                  </Typography>
                                }
                                onChange={() => handleContribution(key)}
                              />
                            );
                          })}
                    </>
                  </FieldSet>
                </Stack>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    width: "fit-content",
                    alignSelf: "center",
                    px: 4,
                    fontWeight: "bold",
                    color: theme.palette.secondary.main,
                  }}
                  type="submit"
                  onClick={handleSubmit(submitForm)}
                >
                  {t("platform/common:content.investor_matching.action.add_investor")}{" "}
                </Button>
              </Section>
            </FormProvider>
          </Card>
        </Modal>
      )}
    </div>
  );
};

export default CustomInvestorForm;
