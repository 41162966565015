import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { darken } from "@mui/system";

export const CustomTooltip = ({ title, placement, arrow, children }) => {
  const CustomTooltipWrapper = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: darken(theme.palette.primary.main, 0.4),
      color: theme.palette.common.white,
      boxShadow: theme.shadows[2],
      fontSize: 12,
      fontWeight: "bold",
      padding: "7px",
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: darken(theme.palette.primary.main, 0.4),
    },
  }));

  return (
    <CustomTooltipWrapper title={title} placement={placement} arrow={arrow}>
      {children}
    </CustomTooltipWrapper>
  );
};
