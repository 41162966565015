import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useClients = () => {
  // to get all users
  const getClients = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to get client by id
  const getClient = (clientId) => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to create a new client
  const createClient = (customer_id, name, address) => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: {
        customer_id,
        name,
        address,
      },
    });
  };

  // to update client
  const updateClient = (clientId, data) => {
    return API.patch(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
        body: data,
      }
    );
  };

  // to delete a client
  const deleteClient = (clientId) => {
    return API.del(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  return {
    getClients,
    getClient,
    createClient,
    updateClient,
    deleteClient,
  };
};
