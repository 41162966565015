import React from "react";
import Stack from "@mui/material/Stack";
import { TableWrapperOne } from "./styled.commercial.table1";
import { CustomTooltip } from "../../..";
import IconButton from "@mui/material/IconButton";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { useTranslation } from "react-i18next";

export const Competitors = ({ compatitors, handleDeleteCompany, handleEditUser, NotEditable }) => {
  const { t } = useTranslation(["company/due_dilligence"]);

  return (
    <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 3 }}>
      <div style={{ width: "100%", overflowX: "auto" }}>
        <TableWrapperOne>
          <caption>{t(`company/due_dilligence:dd_commercial.competitor_list`)}</caption>
          <thead>
            <tr>
              <th>{t(`company/due_dilligence:dd_commercial.company_name`)}</th>
              <th>{t(`company/due_dilligence:dd_commercial.country`)}</th>
              <th>{t(`company/due_dilligence:dd_commercial.website`)}</th>
              <th>{t(`company/due_dilligence:dd_commercial.comment`)}</th>
              <th>{t(`company/due_dilligence:dd_commercial.type`)}</th>
              {!NotEditable && <th>{t("user_management_in_contact.list_users.action")}</th>}
            </tr>
          </thead>
          <tbody>
            {compatitors?.map((item, index) => (
              <tr key={index}>
                <td>{item.company_name}</td>
                <td>{item.country}</td>
                <td>{item.website}</td>
                <td>{item.comment}</td>
                <td>{item.type}</td>
                {!NotEditable && (
                  <td>
                    <CustomTooltip
                      title={t("transaction_tables.deal_documents.delete_file")}
                      placement="top"
                      arrow
                    >
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleDeleteCompany(item.id)}
                      >
                        <PersonRemoveOutlinedIcon />
                      </IconButton>
                    </CustomTooltip>
                    <CustomTooltip title={t("misc.edit")} placement="top" arrow>
                      <IconButton
                        color="primary"
                        size="small"
                        onClick={() => handleEditUser(item.id)}
                      >
                        <EditOutlinedIcon />
                      </IconButton>
                    </CustomTooltip>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </TableWrapperOne>
      </div>
    </Stack>
  );
};
