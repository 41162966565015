import { API } from "aws-amplify";
import { getCovid } from "../common";
import TConfig from "../../../../config";
import { useTransactions } from "./useTransactions";

export const useDocuments = () => {
  const { getFileById } = useTransactions();

  const getDocumentPlatform = async (params) => {
    const { type, customer_id, client_id, transaction_id, custom_company_contract } = params;

    if (type === "contract_company" && custom_company_contract) {
      const response = await getFileById(custom_company_contract);
      return response.file;
    }

    return await API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.DOCUMENTS_API_ENDPOINT}/${type}/${customer_id}`,
      {
        queryStringParameters: {
          transaction_id: transaction_id,
          client_id: client_id,
          cov_id: getCovid(),
        },
      }
    );
  };

  const getDocumentCompany = async (params) => {
    const { type, client_id, transaction_id, custom_company_contract } = params;

    if (type === "contract_company" && custom_company_contract) {
      const response = await getFileById(custom_company_contract);
      return response.file;
    }

    return await API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.DOCUMENTS_API_ENDPOINT}/${type}`,
      {
        queryStringParameters: {
          transaction_id: transaction_id,
          client_id: client_id,
          cov_id: getCovid(),
        },
      }
    );
  };

  const getDocumentAdvisor = (params) => {
    const { type, customer_id, client_id, transaction_id } = params;
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.DOCUMENTS_API_ENDPOINT}/${type}/${customer_id}`,
      {
        queryStringParameters: {
          transaction_id: transaction_id,
          client_id: client_id,
          cov_id: getCovid(),
        },
      }
    );
  };

  return {
    getDocumentCompany,
    getDocumentPlatform,
    getDocumentAdvisor,
  };
};
