import { TableBody } from "@mui/material";
import { InvestorTableRow } from "./InvestorTableRow";

const InvestorTableBody = (props) => {
  const {
    page,
    options,
    prepareRow,
    getTableBodyProps,
    expandCategories,
    handleExpanded,
    disabled,
    renderActions,
  } = props;

  return (
    <TableBody {...getTableBodyProps()}>
      {page.map((row, i) => {
        return (
          <InvestorTableRow
            renderActions={renderActions}
            key={row.original.id}
            row={row}
            options={options}
            prepareRow={prepareRow}
            expandCategories={expandCategories}
            handleExpanded={handleExpanded}
            disabled={disabled}
          />
        );
      })}
    </TableBody>
  );
};
export default InvestorTableBody;
