import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import TAwsConfig from "../../../../../../../awsConfig";
import { CountriesResponse, Country } from "./interfaces";

export const countriesApi = createApi({
  reducerPath: "countries",
  baseQuery: fetchBaseQuery({ baseUrl: TAwsConfig.datastore.URL }),
  endpoints: (builder) => ({
    getCountries: builder.query<Country[], void>({
      query: () => "/data/account/countries.json",
      transformResponse: (response: CountriesResponse): Country[] => {
        return response.data.items;
      },
    }),
  }),
});

export const { useGetCountriesQuery } = countriesApi;
