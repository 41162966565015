import { State } from "../accounts/shared/interfaces";
import {
  StateMetadata,
  CommentsMetadata,
  SecondaryMetadata,
  TransactionInvestorsStateMetadata,
} from "../metadata/interfaces";

export interface TransactionResponse {
  transaction: Transaction;
}
export interface Transaction {
  transaction_id: string;
  client_id: string;
  customer_id: string;
  supervisor: Partial<TransactionSupervisor>;
  advisor?: Partial<TransactionAdvisor>;
  invest?: Partial<TransactionInvest>;
  captable?: Partial<TransactionCaptable>;
  market?: Partial<TransactionMarket>;
  files?: TransactionFile[];
  investors?: TransactionInvestors[];
  metadata: (StateMetadata | CommentsMetadata | SecondaryMetadata)[];
  state: State;
  customer_name: string;
  customer_contact: TransactionCustomerContact;
  client_name: string;
  client_contact: TransactionClientContact;
}

export interface TransactionInvestors {
  customer_id: string;
  customer_name: string;
  metadata: TransactionInvestorsStateMetadata[];
  state: {
    stages: {
      investor: { completed: boolean };
      nominate: { completed: boolean; timestamp: number };
      opportunity: { completed: boolean };
      prospect: { completed: boolean };
    };
    status: {
      current_stage: string;
      last_stage: string;
      last_stage_timestamp: number;
      last_step: string;
      last_step_timestamp: number;
      next_steps: string[];
    };
    steps: {
      approved: { completed: boolean };
      confirmed: { completed: boolean };
      interested: { completed: boolean };
      invited: { completed: boolean; timestamp: number };
      proposed: { completed: boolean; timestamp: number };
      selected: { completed: boolean };
      signed: { completed: boolean };
    };
  };
}

export interface TransactionFile {
  content_type: string;
  file_format: string;
  file_id: string;
  file_name: string;
  file_type: string;
  file_urn: string;
  susa_date: string;
  transaction_id: string;
  uploaded_at: string;
  uploaded_by: string;
  version: number;
  metadata: any[];
}

export interface TransactionSupervisor {
  customer_id: string;
  user_id: string;
  user_name: string;
  email: string;
  user_telephone: string;
}

export interface TransactionAdvisor {
  customer_id: string;
  customer_name: string;
  user_id: string;
  user_name: string;
  email: string;
  user_telephone: string;
}

export interface TransactionInvest {
  min_cap: string;
  max_cap: string;
  stake: string[];
  type: string[];
  self_ev: string;
  phase: string;
  period: string;
  structure: InvestStructure;
}

export enum InvestStructure {
  Capital = "capital",
  Secondary = "secondary",
  CapitalSecondary = "capital_secondary",
  Empty = "",
}

export interface TransactionCaptable {
  largest_sh_pct: string;
  largest_sh_name: string;
}

export interface TransactionCustomerContact {
  first_name: string;
  last_name: string;
  telephone: string;
  email: string;
}

export interface TransactionClientContact {
  email: string;
  first_name: string;
  last_name: string;
  telephone: string;
  pipedrive_deal_id?: string;
}

export interface TransactionMarket {
  uvp: string;
  comp: string;
  econ: string;
  notes: string;
}
