import React from "react";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";

export const PrimaryButton = ({ onClick = () => undefined, title = "", disabled = false }) => {
  const theme = useTheme();

  return (
    <Button
      variant="contained"
      color="secondary"
      size="medium"
      disabled={disabled}
      sx={{
        px: { xs: 3, md: 6 },
        py: 1,
        mr: 1,
        mb: 5,
        mt: 2,
        fontWeight: "bold",
        color: theme.palette.primary.main,
        borderRadius: "4px",
      }}
      onClick={onClick}
    >
      {title}
    </Button>
  );
};
