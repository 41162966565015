import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { Comment } from "./interfaces";

export const canBeDeleted = (
  userId: string,
  messageUserId: string,
  productId: string,
  timestamp: number
) => {
  const days = moment().diff(timestamp, "days");
  return (["platform"].includes(productId) || userId === messageUserId) && days < 2;
};

export const addMessage = async (
  userId: string,
  transactionByIdQuery: any,
  currentUser: any,
  productId: "platform" | "advisor" | "company" | "lawyer",
  message: string,
  fileId: string,
  transactionId: string,
  updateTransaction: any
) => {
  const transaction = await transactionByIdQuery.refetch();
  const comments: Comment[] =
    transaction?.data?.files?.find((data: any) => data.file_id === fileId)?.metadata?.[0]?.data ??
    [];
  const comment: Comment = {
    id: uuidv4(),
    timestamp: new Date().getTime(),
    user: userId,
    userName: `${currentUser?.user?.first_name} ${currentUser?.user?.last_name}`,
    orgName: `${currentUser?.customer?.name}`,
    role: productId,
    private: true,
    msg: message,
    viewedBy: [userId],
  };
  const ObjectToUpdate = {
    files: [
      {
        file_id: fileId,
        metadata: [
          {
            scope: "activity",
            data: Array.isArray(comments) ? [...comments, comment] : [comment],
          },
        ],
      },
    ],
  };
  await updateTransaction(transactionId, ObjectToUpdate);
  return { comment, comments };
};

export const deleteMessage = async (
  transactionByIdQuery: any,
  fileId: string,
  messageId: string,
  updateTransaction: any,
  transactionId: string
) => {
  const transaction = await transactionByIdQuery.refetch();
  const comments: Comment[] =
    transaction.data?.files?.find((data: any) => data.file_id === fileId)?.metadata?.[0]?.data ??
    [];
  const newMessages = comments.filter((message: { id: string }) => message.id !== messageId);

  const ObjectToUpdate = {
    files: [
      {
        file_id: fileId,
        metadata: [
          {
            scope: "activity",
            data: newMessages,
          },
        ],
      },
    ],
  };

  await updateTransaction(transactionId, ObjectToUpdate);
  return { newMessages };
};
