import { combineReducers } from "@reduxjs/toolkit";
import { countriesApi } from "./api/datastore/countries/countriesApi";
import { categoriesApi } from "./api/datastore/categories/categoriesApi";
import { transactionsApi } from "./api/transactions/transactionsApi";
import { clientsApi } from "./api/clients/clientsApi";
import { dueDiligenceApi } from "./api/dueDiligence/dueDiligenceApi";
import currentUserReducer from "./currentUser/currentUserSlice";
import languageReducer from './languageChange/languageSlice';

const rootReducer = combineReducers({
  currentUser: currentUserReducer,
  [countriesApi.reducerPath]: countriesApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [transactionsApi.reducerPath]: transactionsApi.reducer,
  [clientsApi.reducerPath]: clientsApi.reducer,
  [dueDiligenceApi.reducerPath]: dueDiligenceApi.reducer,
  language: languageReducer,
});

export default rootReducer;
