import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useLogin } from "./useLogin";
import { useAppSelector } from "../Store/hooks/useAppSelector";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../Store/currentUser/currentUserSlice";

const initialPasswordState = {
  email: "__INITIAL__",
  password: "__INITIAL__",
  repeatPassword: "__INITIAL__",
  temporaryPassword: "__INITIAL__",
  loading: false,
};

export const useChangePassword = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();
  const [login] = useLogin();
  const [params, setParams] = useState(initialPasswordState);

  const changePassCallback = useCallback(
    (isMounted) => {
      const { email, password, loading, temporaryPassword } = params;
      if (loading || email === "__INITIAL__") return;
      setParams({ ...initialPasswordState, loading: true });

      Auth.signIn(email, temporaryPassword)
        .then((cognitoUser) => {
          return Auth.completeNewPassword(cognitoUser, password, null);
        })
        .then(() => {
          isMounted && login({ email, password });
        })
        .catch((e) => {
          const err = {
            // __typename: AUTH_ERROR,
            code: e.code,
            name: e.code,
          };

          if (e.code === "NotAuthorizedException") {
            // In Change Password process, USER_NOT_AUTHORIZED has only one meaning
            // that given password is not valid. SO convert it to more meaningful error code
            err.code = "CodeMismatchException";
            err.name = "CodeMismatchException";
          }
          isMounted && setParams({ ...params, loading: false });
          dispatch(setCurrentUser({ errors: [err] }));
        });
    },
    [dispatch, params, login]
  );

  useEffect(() => {
    let mounted = true;
    if (params.email === "__INITIAL__" || params.loading) return;
    if (currentUser.errors.length > 0) return;
    changePassCallback(mounted);
    setParams({ ...params, loading: true });

    return () => {
      mounted = false;
    };
  }, [changePassCallback, currentUser.errors.length, params]);

  /** * [useChangePassword hook returns a callback and a isReady state] **/
  return [
    ({ email, password, repeatPassword, temporaryPassword }) => {
      if (password !== repeatPassword) {
        const err = {
          // __typename: AUTH_ERROR,
          code: "PASSWORD_AND_REPEAT_MUST_BE_SAME",
          name: "invalid_password_repeat",
        };

        dispatch(
          setCurrentUser({
            email: email,
            errors: [err],
          })
        );
        setParams({ ...params, loading: false });
        return;
      }
      setParams({
        loading: false,
        email,
        password,
        repeatPassword,
        temporaryPassword:
          temporaryPassword === "" ? currentUser.temporaryPassword : temporaryPassword,
      });
    },
    params.loading,
  ];
};

/** --- AUTH_RESPONSES ---
 * UNEXPECTED_ERROR = "UNEXPECTED_ERROR"
 * CODE_EXPIRED = "ExpiredCodeException"
 * RESET_REQUIRED = "PasswordResetRequiredException"
 * CODE_MISMATCH = "CodeMismatchException"
 * TOO_MANY_TRY = "LimitExceededException"
 * USER_NOT_FOUND = "UserNotFoundException"
 * USER_NOT_AUTHORIZED = "NotAuthorizedException"
 * INVALID_PASSWORD = "InvalidPasswordException"
 * INVALID_PARAMETER = "InvalidParameterException"
 * NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
 * NO_EMPTY = "EMPTY_STRING_NOT_ALLOWED"
 * EMAIL_REQUIRED = "STRING_MUST_BE_EMAIL"
 * PASSWORD_POLICY = "PASSWORD_MUST_FULFILL_POLICY"
 * PASSWORD_CONFIRM_MISMATCH = "PASSWORD_AND_REPEAT_MUST_BE_SAME"
 */
