import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import Backend from "i18next-http-backend";
// import LanguageDetector from "i18next-browser-languagedetector";

import TAwsConfig from "./awsConfig";

i18n
  // load translation using http -> see /public/locales
  // (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false, // logs info level to console output.
    lng: "de", // language to use (overrides language detection)
    fallbackLng: "en", // language to use if translations in user language are not available. OR use 'false', will not trigger to load the fallbackLng at all
    defaultNS: "common", // default namespace used if not passed to the translation function
    ns: "common", // string or array of namespaces to load
    fallbackNS: "common", // string or array of namespaces to lookup key if not found in given namespace.
    returnNull: false, // allows null values as valid translation
    saveMissing: false, // calls save missing key function on backend if key not found
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: [
        "p",
        "b",
        "br",
        "span",
        "strong",
        "small",
        "i",
        "h3",
        "h4",
        "ul",
        "ol",
        "li",
        "a",
        "sup",
      ],
    },
    backend: {
      loadPath: TAwsConfig.locales.URL + "/{{lng}}/{{ns}}.json",
      crossDomain: true,
    },
  });

export default i18n;
