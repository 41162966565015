import { StyledTableRow, StyledTableCell } from "./styled.tableComp";
import { Box, IconButton, List, ListItem, Typography } from "@mui/material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const InvestorTableRow = ({
  row,
  options,
  prepareRow,
  expandCategories,
  handleExpanded,
  disabled,
  renderActions,
}) => {
  prepareRow(row);
  const og = row.original;
  const expansionTargets = [
    "categories",
    "sub_categories",
    "period",
    "phases",
    "stake",
    "types",
    "contribution",
  ];
  const needsExpanding = expansionTargets
    .map((target) => {
      return og[target].length > 1 && options[target];
    })
    .includes(true);
  return (
    <>
      <StyledTableRow {...row.getRowProps()}>
        {row.cells.map((cell) => {
          const key = cell.column.id;
          if (expansionTargets.includes(key)) {
            return (
              <StyledTableCell sx={{ width: "10%", maxWidth: "100px" }} {...cell.getCellProps()}>
                <List>
                  {row.original[key].map((cat, i) => {
                    if (expandCategories === og.id || i === 0) {
                      return (
                        <ListItem key={i} sx={{ padding: "0px" }}>
                          <Typography noWrap style={{ fontSize: "13px" }}>
                            {cat}
                          </Typography>
                        </ListItem>
                      );
                    } else return "";
                  })}
                </List>
              </StyledTableCell>
            );
          } else {
            return (
              <StyledTableCell
                value={key === "invest" ? parseInt(og[key], 10) : og[key]}
                sx={{ width: "10%", maxWidth: "100px" }}
                {...cell.getCellProps()}
              >
                <Typography
                  value={key === "invest" ? parseInt(og[key], 10) : og[key]}
                  sx={{ padding: "8px 0 8px 0", fontSize: "13px" }}
                  noWrap
                >
                  {og[key]}
                </Typography>
              </StyledTableCell>
            );
          }
        })}
        <StyledTableCell sx={{ width: "10%", maxWidth: "100px" }}>
          <Box sx={{ display: "flex", justifyContent: `${"flex-end"}` }}>
            {needsExpanding && (
              <IconButton
                size="medium"
                onClick={() => {
                  handleExpanded(og.id);
                }}
              >
                {expandCategories === og.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            )}
            {renderActions(row.original, disabled)}
          </Box>
        </StyledTableCell>
      </StyledTableRow>
    </>
  );
};
