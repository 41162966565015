import React, { Ref, useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";
import { darken } from "@mui/system";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import SummarizeOutlinedIcon from "@mui/icons-material/SummarizeOutlined";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import BalanceIcon from "@mui/icons-material/Balance";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import BusinessIcon from "@mui/icons-material/Business";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import DescriptionIcon from "@mui/icons-material/Description";

import { FadeIn, LoadingIndicator } from "../../components";

import { DashboardDispatch, DashbordContext } from "../../../../../views/Private/Home";
import { SubMenuDetails, SubMenuFinancial, Wrapper } from "./styled.sidebarSecondary";
import { useNavigate, useParams } from "react-router-dom";

import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { useAppSelector } from "../../Store/hooks/useAppSelector";

import TConfig from "../../../../../config";
import { ProcessSteps } from "../../Store/api";
export type ProductId = "lawyer" | "advisor" | string | undefined;
// custom Toolt
// @ts-ignore
const CustomTooltipWrapper = styled(({ className, ...props }) => (
  // @ts-ignore
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: darken(theme.palette.primary.main, 0.4),
    color: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    fontWeight: "bold",
    padding: "7px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: darken(theme.palette.primary.main, 0.4),
  },
}));

export const SidebarSecondary = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);
  const params = useParams();
  const transactionId = params.transactionId;
  const currentStepParam = params.step;
  const dispatch = useAppDispatch();
  const [subMenuDD, setSubMenuDD] = useState(false);
  const [subMenuFinancial, setSubMenuFinancial] = useState(false);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const productId: ProductId = currentUser?.customer?.subscription?.product_id;

  const steps =
    TConfig.defaults.PRODUCT_ID === "platform" ||
    (TConfig.defaults.PRODUCT_ID === "advisor" && productId === "advisor")
      ? [
          { label: "Überblick", stepId: ProcessSteps.OVERVIEW },
          { label: "Status", stepId: ProcessSteps.STATUS },
          { label: "Details", stepId: ProcessSteps.DETAILS },
          { label: "Due Diligence", stepId: ProcessSteps.DUE_DILIGENCE },
          { label: "Investor Matching", stepId: ProcessSteps.INVESTOR_MATCHING },
        ]
      : TConfig.defaults.PRODUCT_ID === "investor"
      ? [
          { label: "Überblick", stepId: ProcessSteps.OVERVIEW },
          { label: "Status", stepId: ProcessSteps.STATUS },
          { label: "Details", stepId: ProcessSteps.DETAILS },
          // { label: "Investment docs", stepId: ProcessSteps.INVESTMENT_DOCUMENTS },
        ]
      : TConfig.defaults.PRODUCT_ID === "advisor" && productId === "lawyer"
      ? [{ label: "Due Diligence", stepId: ProcessSteps.DUE_DILIGENCE }]
      : [
          { label: "Details", stepId: ProcessSteps.DETAILS },
          { label: "Due Diligence", stepId: ProcessSteps.DUE_DILIGENCE },
          { label: "Investor Matching", stepId: ProcessSteps.INVESTOR_MATCHING },
          // { label: "Investment docs", stepId: ProcessSteps.INVESTMENT_DOCUMENTS },
        ];

  const dashboardContext: any = useContext(DashbordContext);
  const dashboardDispatch = useContext(DashboardDispatch);

  // details sub menu items from locales
  const subMenuDetailsSteps: { label: string; ref: Ref<any> }[] = t("details_sub_menu", {
    returnObjects: true,
  });

  const subMenuDDSteps: { label: string; ref: Ref<any>; stepId: any }[] = t("dd_sub_menu", {
    returnObjects: true,
  });

  const subMenuFinancialSteps: { label: string; ref: Ref<any>; stepId: any }[] = t(
    "dd_financial_sub_menu",
    {
      returnObjects: true,
    }
  );

  const subMenuInvestorMatching: { label: string; ref: Ref<any>; stepId: any }[] = t(
    "investor_status",
    {
      returnObjects: true,
    }
  );

  const animateScroll = (ref: Ref<any>) => {
    dashboardDispatch({
      type: "UPDATE_DATA",
      payload: {
        scrollTo: ref,
      },
    });
  };

  const getStepClassNames = (
    step: ProcessSteps,
    currentStepParam?: string,
    transactionId?: string
  ) => {
    if (step === "DUE_DILIGENCE" && subMenuDD) return "completed addBg";
    if (step !== "DUE_DILIGENCE" && subMenuDD) return "completed";
    if (currentStepParam && step === currentStepParam.replaceAll("-", "_").toUpperCase()) {
      return "completed addBg";
    } else {
      if (step === ProcessSteps.OVERVIEW && !currentStepParam && !transactionId) {
        return `completed addBg`;
      }

      if (step === ProcessSteps.OVERVIEW && currentStepParam) {
        return `completed`;
      }

      if (!currentStepParam || (step !== "DETAILS" && dashboardContext.dashboard_showStatus)) {
        return "completed disabled";
      }

      return "completed";
    }
  };

  const handleRedirect = (step: ProcessSteps, transactionId?: string) => {
    if (!transactionId) return;

    switch (step) {
      case ProcessSteps.OVERVIEW: {
        navigate("/");
        setSubMenuDD(false);
        dispatch(
          setCurrentUser({
            current_main_content: "advisor_content",
          })
        );
        dashboardDispatch({
          type: "UPDATE_DATA",
          payload: {
            client_content: {
              ...dashboardContext.client_content,
              currentStep: ProcessSteps.OVERVIEW,
              showStatus: false,
              showDetails: false,
            },
            dashboard_showStatus: false,
            scrollTo: "",
            detailsContentLoading: true,
          },
        });
        break;
      }
      case ProcessSteps.STATUS:
        navigate(`/${transactionId}/status`);
        setSubMenuFinancial(false);
        setSubMenuDD(false);
        break;
      case ProcessSteps.DETAILS:
        navigate(`/${transactionId}/details`);
        setSubMenuDD(false);
        setSubMenuFinancial(false);
        break;
      case ProcessSteps.INVESTOR_MATCHING:
        navigate(`/${transactionId}/investor-matching`);
        setSubMenuDD(false);
        setSubMenuFinancial(false);
        break;
      case ProcessSteps.INVESTOR_STATUS:
        navigate(`/${transactionId}/investor-status`);
        setSubMenuDD(false);
        setSubMenuFinancial(false);
        break;
      case ProcessSteps.DUE_DILIGENCE:
        setSubMenuFinancial(false);
        setSubMenuDD(true);
        break;
      case ProcessSteps.DD_LEGAL:
        setSubMenuFinancial(false);
        navigate(`/${transactionId}/due-diligence-legal`);
        break;
      case ProcessSteps.DD_FINANCIAL:
        setSubMenuFinancial(true);
        navigate(`/${transactionId}/due-diligence-financial`);
        break;
      case ProcessSteps.DD_DASHBOARD:
        navigate(`/${transactionId}/due-diligence-dashboard`);
        break;
      case ProcessSteps.DD_PLANUNG:
        navigate(`/${transactionId}/due-diligence-planung`);
        break;
      case ProcessSteps.DD_BERICHTE:
        navigate(`/${transactionId}/due-diligence-berichte`);
        break;
      case ProcessSteps.DD_TAX:
        setSubMenuFinancial(false);
        navigate(`/${transactionId}/due-diligence-tax`);
        break;
      case ProcessSteps.DD_COMMERCIAL:
        setSubMenuFinancial(false);
        navigate(`/${transactionId}/due-diligence-commercial`);
        break;
      case ProcessSteps.INVESTMENT_DOCUMENTS:
        navigate(`/${transactionId}/investment-documents`);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (currentStepParam?.includes("due-diligence")) {
      setSubMenuDD(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Wrapper>
      <FadeIn>
        {isTablet &&
          steps.map(({ label, stepId }) => (
            // @ts-ignore
            <CustomTooltipWrapper key={label} title={label} placement="right" arrow>
              <Box
                className={getStepClassNames(stepId, currentStepParam)}
                onClick={() => handleRedirect(stepId, transactionId)}
              >
                {stepId === ProcessSteps.OVERVIEW && <GridViewOutlinedIcon />}
                {stepId === ProcessSteps.STATUS && <GridViewOutlinedIcon />}
                {stepId !== ProcessSteps.OVERVIEW && stepId !== ProcessSteps.STATUS && (
                  <ManageSearchIcon />
                )}

                <Typography variant="subtitle1" color="primary" className="textLabel">
                  {label}
                </Typography>
              </Box>
            </CustomTooltipWrapper>
          ))}
        {!isTablet &&
          steps.map(({ label, stepId }, index) => {
            return (
              <Box key={index}>
                <Box
                  className={getStepClassNames(stepId, currentStepParam)}
                  onClick={() => handleRedirect(stepId, transactionId)}
                >
                  {stepId === ProcessSteps.OVERVIEW && <GridViewOutlinedIcon />}
                  {stepId === ProcessSteps.STATUS && <SummarizeOutlinedIcon />}
                  {stepId !== ProcessSteps.OVERVIEW && stepId !== ProcessSteps.STATUS && (
                    <ManageSearchIcon />
                  )}
                  <Typography variant="subtitle1" color="primary" className="textLabel">
                    {label}
                  </Typography>
                </Box>
                {!isTablet && currentStepParam === "details" && stepId === "DETAILS" && (
                  <SubMenuDetails>
                    {subMenuDetailsSteps.map((item, ind) => {
                      if (dashboardContext.detailsContentLoading) {
                        return (
                          <div key={ind} className="subMenuItem noBg">
                            <LoadingIndicator type={"TEXT"} placeholder={"LOREMIPSUMTEXTHERE"} />
                          </div>
                        );
                      }
                      return (
                        <div
                          key={ind}
                          className="subMenuItem"
                          onClick={() => {
                            if (subMenuDD) {
                              setSubMenuDD(false);
                            }
                            animateScroll(item.ref);
                          }}
                        >
                          {item.label}
                        </div>
                      );
                    })}
                  </SubMenuDetails>
                )}

                {!isTablet && subMenuDD && index !== 0 && stepId === "DUE_DILIGENCE" && (
                  <SubMenuDetails>
                    {subMenuDDSteps.map((item, index) => {
                      if (
                        item.label !== "Financial" &&
                        item.label !== "Investment Dossier" &&
                        // item.label !== "Commercial" &&
                        item.label !== "Tax"
                      ) {
                        return (
                          <>
                            <Box
                              key={index}
                              onClick={() => {
                                handleRedirect(item.stepId, transactionId);
                              }}
                              className={
                                currentStepParam === `due-diligence-${item.label.toLowerCase()}`
                                  ? "completed addBg "
                                  : "completed noBg" +
                                    ((currentStepParam === "due-diligence-dashboard" ||
                                      currentStepParam === "due-diligence-berichte" ||
                                      currentStepParam === "due-diligence-planung") &&
                                    item.label === "Financial"
                                      ? " completed addBg"
                                      : "completed noBg")
                              }
                            >
                              {item.label === "Legal" && <BalanceIcon fontSize="small" />}
                              {item.label === "Financial" && (
                                <AccountBalanceIcon fontSize="small" />
                              )}
                              {item.label === "Commercial" && <BusinessIcon fontSize="small" />}
                              {item.label === "Tax" && <MonetizationOnIcon fontSize="small" />}
                              <Typography variant="subtitle1" color="primary" className="textLabel">
                                {item.label}
                              </Typography>
                            </Box>
                            {item.label === "Financial" &&
                              !isTablet &&
                              subMenuFinancial &&
                              index !== 0 && (
                                <SubMenuFinancial>
                                  {subMenuFinancialSteps.map((item, index) => {
                                    if (dashboardContext.detailsContentLoading) {
                                      return (
                                        <div key={index} className="subMenuItem noBg">
                                          <LoadingIndicator
                                            type={"TEXT"}
                                            placeholder={"LOREMIPSUMTEXTHERE"}
                                          />
                                        </div>
                                      );
                                    }
                                    return (
                                      <Box
                                        key={index}
                                        onClick={() => {
                                          handleRedirect(item.stepId, transactionId);
                                        }}
                                        className={
                                          currentStepParam ===
                                          `due-diligence-${item.label.toLowerCase()}`
                                            ? "completed addBg "
                                            : "completed noBg"
                                        }
                                      >
                                        {item.label === "Dashboard" && (
                                          <DashboardIcon fontSize="small" />
                                        )}
                                        {item.label === "Planung" && (
                                          <AssignmentIcon fontSize="small" />
                                        )}
                                        {item.label === "Berichte" && (
                                          <DescriptionIcon fontSize="small" />
                                        )}
                                        <Typography
                                          variant="subtitle1"
                                          color="primary"
                                          className="textLabel"
                                        >
                                          {item.label}
                                        </Typography>
                                      </Box>
                                    );
                                  })}
                                </SubMenuFinancial>
                              )}
                          </>
                        );
                      }
                      return undefined;
                    })}
                  </SubMenuDetails>
                )}

                {!isTablet &&
                  ((currentStepParam === "investor-matching" && stepId === "INVESTOR_MATCHING") ||
                    (currentStepParam === "investor-status" && stepId === "INVESTOR_MATCHING")) && (
                    <SubMenuDetails>
                      {subMenuInvestorMatching.map((item, index) => {
                        return (
                          <Box
                            key={index}
                            onClick={() => {
                              handleRedirect(item.stepId, transactionId);
                            }}
                            className={
                              currentStepParam === "investor-status"
                                ? "completed addBg"
                                : "completed noBg"
                            }
                          >
                            {item.label === "Investor Status" && (
                              <SummarizeOutlinedIcon fontSize="small" />
                            )}

                            <Typography variant="subtitle1" color="primary" className="textLabel">
                              {item.label}
                            </Typography>
                          </Box>
                        );
                      })}
                    </SubMenuDetails>
                  )}
              </Box>
            );
          })}
      </FadeIn>
    </Wrapper>
  );
};
