import { FileType } from "../../../Store/api";
import { Comment } from "../../DDMessenger/interfaces";

export interface DDAccordion {
  document_present: boolean;
  not_relevant: boolean;
  comments: Comment[];
  files: FileType[];
  completed: boolean;
}

export interface DDFormValues {
  "1.0": {
    "1.1": DDAccordion;
    "1.2": DDAccordion;
    "1.3": DDAccordion;
  };
  "2.0": {
    "2.1": DDAccordion;
    "2.2": DDAccordion;
    "2.3": DDAccordion;
  };
  "3.0": {
    "3.1": DDAccordion;
    "3.2": DDAccordion;
    "3.3": DDAccordion;
    "3.4": DDAccordion;
    "3.5": DDAccordion;
    "3.6": DDAccordion;
    "3.7": DDAccordion;
    "3.8": DDAccordion;
    "3.9": DDAccordion;
    "3.10": DDAccordion;
    "3.11": DDAccordion;
    "3.12": DDAccordion;
    "3.13": DDAccordion;
    "3.14": DDAccordion;
    "3.15": DDAccordion;
    "3.16": DDAccordion;
    "3.17": DDAccordion;
    "3.18": DDAccordion;
    "3.19": DDAccordion;
    "3.20": DDAccordion;
  };
  "4.0": {
    "4.1": DDAccordion;
    "4.2": DDAccordion;
    "4.3": DDAccordion;
    "4.4": DDAccordion;
    "4.5": DDAccordion;
    "4.6": DDAccordion;
    "4.7": DDAccordion;
    "4.8": DDAccordion;
    "4.9": DDAccordion;
  };
  "5.0": {
    "5.1": DDAccordion;
    "5.2": DDAccordion;
    "5.3": DDAccordion;
    "5.4": DDAccordion;
    "5.5": DDAccordion;
  };
  "6.0": {
    "6.1": DDAccordion;
    "6.2": DDAccordion;
    "6.3": DDAccordion;
    "6.4": DDAccordion;
    "6.5": DDAccordion;
  };
  "7.0": {
    "7.1": DDAccordion;
    "7.2": DDAccordion;
    "7.3": DDAccordion;
    "7.4": DDAccordion;
    "7.5": DDAccordion;
    "7.6": DDAccordion;
    "7.7": DDAccordion;
    "7.8": DDAccordion;
    "7.9": DDAccordion;
    "7.10": DDAccordion;
    "7.11": DDAccordion;
    "7.12": DDAccordion;
    "7.13": DDAccordion;
    "7.14": DDAccordion;
    "7.15": DDAccordion;
    "7.16": DDAccordion;
    "7.17": DDAccordion;
    "7.18": DDAccordion;
  };
  "8.0": {
    "8.1": DDAccordion;
  };
  "9.0": {
    "9.1": DDAccordion;
    "9.2": DDAccordion;
    "9.3": DDAccordion;
    "9.4": DDAccordion;
    "9.5": DDAccordion;
    "9.6": DDAccordion;
    "9.7": DDAccordion;
    "9.8": DDAccordion;
    "9.9": DDAccordion;
  };
  "10.0": {
    "10.1": DDAccordion;
    "10.2": DDAccordion;
    "10.3": DDAccordion;
    "10.4": DDAccordion;
    "10.5": DDAccordion;
    "10.6": DDAccordion;
    "10.7": DDAccordion;
    "10.8": DDAccordion;
    "10.9": DDAccordion;
    "10.10": DDAccordion;
    "10.11": DDAccordion;
    "10.12": DDAccordion;
  };
  "11.0": {
    "11.1": DDAccordion;
  };
  "12.0": {
    "12.1": DDAccordion;
    "12.2": DDAccordion;
    "12.3": DDAccordion;
    "12.4": DDAccordion;
  };
  "13.0": {
    "13.1": DDAccordion;
    "13.2": DDAccordion;
    "13.3": DDAccordion;
  };
  "14.0": {
    "14.1": DDAccordion;
    "14.2": DDAccordion;
    "14.3": DDAccordion;
    "14.4": DDAccordion;
    "14.5": DDAccordion;
    "14.6": DDAccordion;
    "14.7": DDAccordion;
    "14.8": DDAccordion;
    "14.9": DDAccordion;
    "14.10": DDAccordion;
  };
  "15.0": {
    "15.1": DDAccordion;
    "15.2": DDAccordion;
    "15.3": DDAccordion;
    "15.4": DDAccordion;
    "15.5": DDAccordion;
    "15.6": DDAccordion;
  };
  "16.0": {
    "16.1": DDAccordion;
    "16.2": DDAccordion;
    "16.3": DDAccordion;
    "16.4": DDAccordion;
    "16.5": DDAccordion;
  };
  "17.0": {
    "17.1": DDAccordion;
    "17.2": DDAccordion;
  };
}

const initialDDAccordionRecord: DDAccordion = {
  document_present: false,
  not_relevant: false,
  comments: [],
  files: [],
  completed: false,
};

export const initialDDValues: DDFormValues = {
  "1.0": {
    "1.1": initialDDAccordionRecord,
    "1.2": initialDDAccordionRecord,
    "1.3": initialDDAccordionRecord,
  },
  "2.0": {
    "2.1": initialDDAccordionRecord,
    "2.2": initialDDAccordionRecord,
    "2.3": initialDDAccordionRecord,
  },
  "3.0": {
    "3.1": initialDDAccordionRecord,
    "3.2": initialDDAccordionRecord,
    "3.3": initialDDAccordionRecord,
    "3.4": initialDDAccordionRecord,
    "3.5": initialDDAccordionRecord,
    "3.6": initialDDAccordionRecord,
    "3.7": initialDDAccordionRecord,
    "3.8": initialDDAccordionRecord,
    "3.9": initialDDAccordionRecord,
    "3.10": initialDDAccordionRecord,
    "3.11": initialDDAccordionRecord,
    "3.12": initialDDAccordionRecord,
    "3.13": initialDDAccordionRecord,
    "3.14": initialDDAccordionRecord,
    "3.15": initialDDAccordionRecord,
    "3.16": initialDDAccordionRecord,
    "3.17": initialDDAccordionRecord,
    "3.18": initialDDAccordionRecord,
    "3.19": initialDDAccordionRecord,
    "3.20": initialDDAccordionRecord,
  },
  "4.0": {
    "4.1": initialDDAccordionRecord,
    "4.2": initialDDAccordionRecord,
    "4.3": initialDDAccordionRecord,
    "4.4": initialDDAccordionRecord,
    "4.5": initialDDAccordionRecord,
    "4.6": initialDDAccordionRecord,
    "4.7": initialDDAccordionRecord,
    "4.8": initialDDAccordionRecord,
    "4.9": initialDDAccordionRecord,
  },
  "5.0": {
    "5.1": initialDDAccordionRecord,
    "5.2": initialDDAccordionRecord,
    "5.3": initialDDAccordionRecord,
    "5.4": initialDDAccordionRecord,
    "5.5": initialDDAccordionRecord,
  },
  "6.0": {
    "6.1": initialDDAccordionRecord,
    "6.2": initialDDAccordionRecord,
    "6.3": initialDDAccordionRecord,
    "6.4": initialDDAccordionRecord,
    "6.5": initialDDAccordionRecord,
  },
  "7.0": {
    "7.1": initialDDAccordionRecord,
    "7.2": initialDDAccordionRecord,
    "7.3": initialDDAccordionRecord,
    "7.4": initialDDAccordionRecord,
    "7.5": initialDDAccordionRecord,
    "7.6": initialDDAccordionRecord,
    "7.7": initialDDAccordionRecord,
    "7.8": initialDDAccordionRecord,
    "7.9": initialDDAccordionRecord,
    "7.10": initialDDAccordionRecord,
    "7.11": initialDDAccordionRecord,
    "7.12": initialDDAccordionRecord,
    "7.13": initialDDAccordionRecord,
    "7.14": initialDDAccordionRecord,
    "7.15": initialDDAccordionRecord,
    "7.16": initialDDAccordionRecord,
    "7.17": initialDDAccordionRecord,
    "7.18": initialDDAccordionRecord,
  },
  "8.0": {
    "8.1": initialDDAccordionRecord,
  },
  "9.0": {
    "9.1": initialDDAccordionRecord,
    "9.2": initialDDAccordionRecord,
    "9.3": initialDDAccordionRecord,
    "9.4": initialDDAccordionRecord,
    "9.5": initialDDAccordionRecord,
    "9.6": initialDDAccordionRecord,
    "9.7": initialDDAccordionRecord,
    "9.8": initialDDAccordionRecord,
    "9.9": initialDDAccordionRecord,
  },
  "10.0": {
    "10.1": initialDDAccordionRecord,
    "10.2": initialDDAccordionRecord,
    "10.3": initialDDAccordionRecord,
    "10.4": initialDDAccordionRecord,
    "10.5": initialDDAccordionRecord,
    "10.6": initialDDAccordionRecord,
    "10.7": initialDDAccordionRecord,
    "10.8": initialDDAccordionRecord,
    "10.9": initialDDAccordionRecord,
    "10.10": initialDDAccordionRecord,
    "10.11": initialDDAccordionRecord,
    "10.12": initialDDAccordionRecord,
  },
  "11.0": {
    "11.1": initialDDAccordionRecord,
  },
  "12.0": {
    "12.1": initialDDAccordionRecord,
    "12.2": initialDDAccordionRecord,
    "12.3": initialDDAccordionRecord,
    "12.4": initialDDAccordionRecord,
  },
  "13.0": {
    "13.1": initialDDAccordionRecord,
    "13.2": initialDDAccordionRecord,
    "13.3": initialDDAccordionRecord,
  },
  "14.0": {
    "14.1": initialDDAccordionRecord,
    "14.2": initialDDAccordionRecord,
    "14.3": initialDDAccordionRecord,
    "14.4": initialDDAccordionRecord,
    "14.5": initialDDAccordionRecord,
    "14.6": initialDDAccordionRecord,
    "14.7": initialDDAccordionRecord,
    "14.8": initialDDAccordionRecord,
    "14.9": initialDDAccordionRecord,
    "14.10": initialDDAccordionRecord,
  },
  "15.0": {
    "15.1": initialDDAccordionRecord,
    "15.2": initialDDAccordionRecord,
    "15.3": initialDDAccordionRecord,
    "15.4": initialDDAccordionRecord,
    "15.5": initialDDAccordionRecord,
    "15.6": initialDDAccordionRecord,
  },
  "16.0": {
    "16.1": initialDDAccordionRecord,
    "16.2": initialDDAccordionRecord,
    "16.3": initialDDAccordionRecord,
    "16.4": initialDDAccordionRecord,
    "16.5": initialDDAccordionRecord,
  },
  "17.0": {
    "17.1": initialDDAccordionRecord,
    "17.2": initialDDAccordionRecord,
  },
};
