import React from "react";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";

interface SectionsProps {
  children?: React.ReactElement | React.ReactElement[];
  title?: string;
  headlineColor?: string;
  isDialog?: boolean;
}

export const Section = React.forwardRef(
  ({ children, title, headlineColor = "light", isDialog = false }: SectionsProps, ref) => {
    const theme = useTheme();
    return (
      <Box
        sx={{
          width: "100%",
          padding: "10px",
          mb: isDialog ? 0 : { xs: 1, md: 2 },
        }}
        ref={ref}
      >
        {title && (
          <Typography
            variant="h5"
            align="left"
            sx={{
              fontWeight: "bold",
              mb: 2,
              fontSize: { xs: "1rem", md: "1.3rem" },
              color:
                headlineColor === "light" ? theme.palette.common.white : theme.palette.common.black,
            }}
          >
            {title}
          </Typography>
        )}
        <Stack direction={"column"} alignItems={{ md: "stretch" }} spacing={4}>
          {children}
        </Stack>
      </Box>
    );
  }
);
