import { Auth } from "aws-amplify";
import isEmail from "validator/lib/isEmail";
import { setCurrentUser } from "../Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";

export const useForgotPassword = () => {
  const dispatch = useAppDispatch();

  return (email) => {
    if (!isEmail(email)) {
      dispatch(
        setCurrentUser({
          errors: [
            {
              // __typename: TypeNameEnums.AUTH_ERROR,
              code: "STRING_MUST_BE_EMAIL",
              name: `empty_email`,
            },
          ],
        })
      );
    }

    return Auth.forgotPassword(email)
      .then(() => {
        dispatch(
          setCurrentUser({
            email: email,
            authenticated: false,
            errors: [],
          })
        );
      })
      .catch((e) => {
        dispatch(
          setCurrentUser({
            errors: [
              {
                // __typename: TypeNameEnums.AUTH_ERROR,
                code: "UNEXPECTED_ERROR",
                name: `${e.code || e.name}`,
              },
            ],
          })
        );
      });
  };
};

/** --- AUTH_RESPONSES ---
 * UNEXPECTED_ERROR = "UNEXPECTED_ERROR"
 * CODE_EXPIRED = "ExpiredCodeException"
 * RESET_REQUIRED = "PasswordResetRequiredException"
 * CODE_MISMATCH = "CodeMismatchException"
 * TOO_MANY_TRY = "LimitExceededException"
 * USER_NOT_FOUND = "UserNotFoundException"
 * USER_NOT_AUTHORIZED = "NotAuthorizedException"
 * INVALID_PASSWORD = "InvalidPasswordException"
 * INVALID_PARAMETER = "InvalidParameterException"
 * NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
 * NO_EMPTY = "EMPTY_STRING_NOT_ALLOWED"
 * EMAIL_REQUIRED = "STRING_MUST_BE_EMAIL"
 * PASSWORD_POLICY = "PASSWORD_MUST_FULFILL_POLICY"
 * PASSWORD_CONFIRM_MISMATCH = "PASSWORD_AND_REPEAT_MUST_BE_SAME"
 */
