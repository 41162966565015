import { TableHead, TableRow, Typography } from "@mui/material";
import { GlobalFilter } from "./helper";
import { StyledTableCell, StyledTableRow } from "./styled.tableComp";

const InvestorTableHead = (props) => {
  const { headerGroups, globalFilter, setGlobalFilter, preGlobalFilteredRows, visibleColumns } = props;

  return (
    <TableHead sx={{ width: "100%" }}>
      {headerGroups.map((headerGroup, i) => {
        const { key, ...restHeaderGroupProps } = headerGroup.getHeaderGroupProps();
        return (
          <StyledTableRow key={key + ":" + i} {...restHeaderGroupProps}>
            {headerGroup.headers.map((column) => (
              <StyledTableCell key={column.id} sx={{ width: "10%", maxWidth: "100px" }}>
                {column.render("Header")}
                <span>{column.isSorted ? (column.isSortedDesc ? "   🔽" : "   🔼") : ""}</span>
                <div style={{ maxWidth: "100%" }} onClick={(e) => e.stopPropagation()}>
                  <Typography noWrap>
                    {column.canFilter ? column.render("Filter") : null}
                  </Typography>
                </div>
              </StyledTableCell>
            ))}
            <StyledTableCell
              key={`empty-${i}`}
              sx={{
                width: "10%",
                maxWidth: "100px",
              }}
            />
          </StyledTableRow>
        );
      })}
      <TableRow>
        <TableHead
          colSpan={visibleColumns.length + 1}
          style={{
            textAlign: "left",
          }}
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        </TableHead>
      </TableRow>
    </TableHead>
  );
};

export default InvestorTableHead;
