import React, { useEffect, useMemo, useState } from "react";
import * as countryFlagIcons from "country-flag-icons";
import parsePhoneNumber, { CountryCode, isValidPhoneNumber } from "libphonenumber-js";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from "@mui/material/styles";
import FormHelperText from "@mui/material/FormHelperText";

import { getCountries } from "../../common";
import { useAppSelector } from "../../Store/hooks/useAppSelector";

import { Country } from "../../Store/api";
interface CountryFlagProps {
  country: string;
  code: string;
  abrv: any;
}
// to create the content of OPTION/MENUITEM in SELECT element with country flag
const CountryFlagCode = ({ country, code, abrv }: CountryFlagProps) => {
  let icon = abrv;
  if (countryFlagIcons.hasFlag(abrv)) {
    icon = (
      <img
        style={{ width: "28px", marginTop: "8px" }}
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${abrv}.svg`}
        alt={`${country} flag`}
      />
    );
  }
  return (
    <>
      <div style={{ display: "inline-block" }} data-country-code={`+${code}`} className={"flag"}>
        {icon}
        <div style={{ display: "inline-block", padding: "0px 5px" }}></div>
      </div>
    </>
  );
};
interface PhoneProps {
  telephone: string;
  label: string;
  phoneChange: (phoneNumber: string) => void;
  mobile: boolean;
  error: boolean;
  errorText: string;
  registering: boolean;
  required?: boolean;
  idLabel: string;
}
export const Phone = ({
  telephone,
  label,
  phoneChange,
  mobile,
  error,
  errorText,
  registering,
  required = true,
  idLabel,
}: PhoneProps) => {
  const theme = useTheme();
  const [countryOptions, setCountryOptions] = useState<Country[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<string>("DE");
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const isNumberValid = useMemo(() => {
    const phone = parsePhoneNumber(telephone);
    if (phone === undefined) {
      return false;
    } else if (phone.country) {
      const number = phone.number;
      const isPossiblePhoneNumber = isValidPhoneNumber(number, phone.country);

      return isPossiblePhoneNumber && true;
    } else if (!phone.country) {
      const country = countryOptions.find(
        (item) => String(item.code) === String(phone?.countryCallingCode)
      )?.alt;

      const number = phone.number;

      return country && isValidPhoneNumber(String(number), country as CountryCode);
    } else {
      return false;
    }
  }, [countryOptions, telephone]);

  // UseEffect for REGISTER FLOW to fetch countries
  useEffect(() => {
    if (!registering) return;
    getCountries().then((data) => {
      setCountryOptions(data);
    });
  }, [registering]);

  // UseEffect for DASHBOARD to read countries from REDUX datastore
  useEffect(() => {
    if (registering) return;
    setCountryOptions(currentUser.datastore.countries);
  }, [currentUser.datastore.countries, registering]);

  // if telephone has a valid value, after component mounting, it will show related country flag and phone number in input
  useEffect(() => {
    if (registering) return;
    if (!isNumberValid) return;

    const phone = parsePhoneNumber(telephone);
    setSelectedCountry(String(phone?.country));
  }, [currentUser.datastore.countries, isNumberValid, registering, telephone]);

  // to get the country phone code
  const getCountryCode = (alt: string) => countryOptions.find((item) => item.alt === alt)?.code;

  // change event for country code
  const handleCountryCodeChange = (
    e: React.ChangeEvent<{ value: string }> | SelectChangeEvent
  ): void => {
    const countryAbrv = e.target.value;
    const countryCode = getCountryCode(countryAbrv);

    phoneChange(`+${countryCode}`);
    setSelectedCountry(countryAbrv);
  };

  // change event for phone number
  const handlePhoneNumberChange = (e: React.ChangeEvent<{ value: string }>): void => {
    const value = e.target.value;
    phoneChange(value);
  };

  const menuItems =
    countryOptions &&
    countryOptions.map(({ value, code, alt, key }) => (
      <MenuItem
        value={alt}
        key={key}
        sx={{
          "& .flag::after": {
            content: "attr(data-country-code)",
          },
          "&:selected": {
            "& .flag::after": {
              content: "''",
            },
          },
        }}
      >
        <CountryFlagCode country={value} code={code} abrv={alt} />
      </MenuItem>
    ));

  return (
    <FormControl fullWidth variant="outlined" size={mobile ? "small" : "medium"}>
      <InputLabel htmlFor={`${idLabel}_phoneText`} required={required}>
        {label}
      </InputLabel>
      <OutlinedInput
        label="phoneText"
        id={`${idLabel}_phoneText`}
        value={telephone}
        onChange={handlePhoneNumberChange}
        error={error}
        inputProps={{
          id: `${idLabel}_phoneText`,
          autoComplete: "new-phone",
          form: {
            autoComplete: "off",
          },
          onInput: (e) => {
            e.currentTarget.value = e.currentTarget.value.replace(/[^0-9+]/g, "");
          },
        }}
        startAdornment={
          <InputAdornment position="start">
            <Select
              name="telephone"
              variant="standard"
              id={`${idLabel}_phone`}
              fullWidth
              disableUnderline={true}
              value={countryOptions.length > 0 ? selectedCountry : ""} // to fix the warning related MUI [Select] You have provided an out-of-range value
              onChange={handleCountryCodeChange}
              MenuProps={{
                PaperProps: {
                  style: {
                    marginTop: "0.4%",
                    maxHeight: "85.5vh",
                  },
                },
              }}
            >
              {countryOptions && menuItems}
            </Select>
          </InputAdornment>
        }
      />
      {error && (
        <FormHelperText sx={{ color: theme.palette.error.main }}>{errorText}</FormHelperText>
      )}
    </FormControl>
  );
};

export const getMainContent = (approved: boolean | undefined, product_id: string): string => {
  const hasBeenApproved = !!approved;
  if (hasBeenApproved && product_id === "investor") {
    return "investor_content";
  }

  if (hasBeenApproved && product_id === "advisor") {
    return "advisor_content";
  }

  return "initial_content";
};
