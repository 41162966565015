import { styled } from "@mui/material/styles";

const StyledIconLoader = styled("svg")(() => {
  return {
    animation: "svgAnimation 2s linear infinite",
    "& circle": {
      animation: "circleAnimation 1.4s ease-in-out infinite both",
      display: "block",
      fill: "transparent",
      stroke: "#193C6C",
      strokeLinecap: "round",
      strokeDasharray: 283,
      strokeDashoffset: 280,
      strokeWidth: "9px",
      transformOrigin: "50% 50%",
    },
    "@keyframes svgAnimation": {
      "0%": {
        transform: "rotateZ(0deg)",
      },
      "100%": {
        transform: "rotateZ(360deg)",
      },
    },
    "@keyframes circleAnimation": {
      "0%": {
        strokeDashoffset: 280,
        transform: "rotate(0)",
      },
      "25%": {
        strokeDashoffset: 280,
        transform: "rotate(0)",
      },
      "50%": {
        strokeDashoffset: 75,
        transform: "rotate(45deg)",
      },
      "75%": {
        strokeDashoffset: 75,
        transform: "rotate(45deg)",
      },
      "100%": {
        strokeDashoffset: 280,
        transform: "rotate(360deg)",
      },
    },
  };
});

export const IconLoader = () => {
  return (
    <StyledIconLoader viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <circle cx="50" cy="50" r="45" />
    </StyledIconLoader>
  );
};
