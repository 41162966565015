import { TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledForm = styled("form")((props) => {
  return {
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    height: "100%",
    justifyContent: "end",
  };
});

export const MessengerContainer = styled("div")((props) => ({ className }) => {
  return {
    borderRadius: "3px",
    border: className === "empty" ? "0px" : `2px solid #004e9280`,
    backgroundColor: "#004e9220",
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    overflowY: "scroll",
    maxHeight: "500px",
  };
});

export const MessageContainer = styled("div")<{ align: "flex-start" | "flex-end" }>(
  ({ align }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: align,
  })
);

export const MessageBubble = styled("div")(({ theme, className }) => {
  const borderColor = () => {
    switch (className) {
      case "advisor":
        return "green";
      case "platform":
        return "yellow";
      case "lawyer":
        return "red";
      case "company":
        return "orange";
      default:
        return "none";
    }
  };
  return {
    backgroundColor: theme.palette.grey.A200,
    border: `2px solid ${borderColor()}`,
    maxWidth: "70%",
    width: "45%",
    borderRadius: "8px",
    padding: "8px",
    boxShadow: "2px 2px 7px #00000080",
  };
});

export const MessageHeader = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
}));

export const MessageBottomContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "flex-end",
  maxWidth: "100%",
  lineBreak: "auto",
}));

export const MessageText = styled(TextField)(() => ({
  padding: "5px 5px 0px 5px",
  "& .MuiInputBase-input.Mui-disabled": {
    WebkitTextFillColor: "black",
  },
}));

export const Name = styled("div")(() => ({
  fontWeight: "bold",
  fontSize: "14px",
  marginRight: "20px",
  color: "#004e92",
}));

export const Hour = styled("div")(() => ({
  fontSize: "12px",
}));

export const Header = styled("div")(({ theme }) => ({
  textAlign: "left",
  fontWeight: "bold",
  padding: "0 5px 5px 0",
  fontSize: "1rem",
  color: theme.palette.info.dark,
  display: "flex",
}));
