import { useMemo } from "react";
import { styled, useTheme } from "@mui/material/styles";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    gridArea: "progress",
    marginBottom: "3.5rem",
    marginTop: "1rem",
    [theme.breakpoints.up("md")]: {
      marginTop: "0",
    },
  };
});

export const Step = styled("div")(({ stepId, currentStep, steps }) => {
  const theme = useTheme();
  const isActive = stepId === currentStep;
  const [fillColor, textColor] = useMemo(() => {
    if (stepId === currentStep) return [theme.palette.primary.main, theme.palette.primary.main];
    const activeIndex = steps.findIndex(({ stepId }) => stepId === currentStep);
    const currentIndex = steps.findIndex((step) => step.stepId === stepId);

    if (activeIndex > currentIndex) {
      return [theme.palette.greys.fourth.main, theme.palette.primary.main];
    }

    return [theme.palette.common.white, theme.palette.primary.main];
  }, [
    stepId,
    currentStep,
    theme.palette.primary.main,
    theme.palette.common.white,
    theme.palette.greys.fourth.main,
    steps,
  ]);

  return {
    position: "relative",
    transform: "translateY(100%)",
    flexGrow: 1,
    textAlign: "center",
    transition: "all 300ms",
    color: textColor,

    "&:before": {
      content: '""',
      display: "block",
      position: "absolute",
      top: "-100%",
      left: "50%",
      width: isActive ? "1.2rem" : "1rem",
      height: isActive ? "1.2rem" : "1rem",
      border: `1px solid ${textColor}`,
      borderRadius: "50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: fillColor,
      zIndex: 1,
    },

    "&:after": {
      content: '""',
      width: "100%",
      display: "block",
      position: "absolute",
      top: "-100%",
      backgroundColor: theme.palette.primary.main,
      height: "1px",
      zIndex: 0,
    },

    "&:first-of-type:after": {
      content: '""',
      width: "50%",
      left: "50%",
    },

    "&:last-of-type:after": {
      content: '""',
      width: "50%",
      right: "50%",
    },
  };
});
