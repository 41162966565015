import React from "react";
import { Alert } from "../Alert";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: props.error,
      errorInfo: props.errorInfo,
    };
  }

  static getDerivedStateFromError(error, errorInfo) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error, errorInfo };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service  ????
    // logErrorToMyService(error, errorInfo);

    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert type={"ERROR"}>
          <div>Something went wrong.</div>
          <div>{this.state.error.message || this.state.error.toString()}</div>
        </Alert>
      );
    }

    return <>{this.props.children}</>;
  }
}
