import { useCallback, useState } from "react";

import { useForm } from "react-hook-form";
import { useAppSelector } from "../../../Store/hooks/useAppSelector";

import { InvestorPeriod } from "../../../Store/api";
import { CustomInvestor } from "../../../Store/interfaces";
import { CategoryOption } from "../../CategoriesDropdown/interfaces";

type Field = "categories" | "period" | "phases" | "stake" | "types";

export default function useCustomInvestorForm(
  addCustomInvestor: (investor: CustomInvestor) => void
) {
  const initialFormValues: CustomInvestor = {
    name: "",
    email: "",
    website: "",
    line_1: "",
    postal: "",
    city: "",
    country: "",
    revenue: "",
    ebitda: "",
    invest: "",
    categories: [],
    sub_categories: [],
    period: [],
    phases: [],
    stake: [],
    types: [],
    contribution: {
      financial_investor: false,
      strategic_investor: false,
      wachstums_investor: false,
    },
  };

  const methods = useForm<CustomInvestor>({ mode: "all", defaultValues: initialFormValues });
  const { setValue, getValues, watch, reset } = methods;

  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const [open, setOpen] = useState<boolean>(false);
  const toggleOpen = () => setOpen(!open);

  const [initial, setInitial] = useState<boolean>(true);
  const contribution = watch("contribution");

  const convertToObjValue = useCallback(
    (value: string[], field: Field) => {
      if (value) {
        if (value.length === 0) {
          return [];
        }
        const options: any[] = value.map((item: string) => {
          return currentUser.datastore[field].find(
            (obj: { value: string; summary: string }) => obj.value === item
          );
        });
        if (options.length > 0) {
          return options;
        }
      }
      return [];
    },
    [currentUser.datastore]
  );

  const handleAutoCompleteSelect = (value: InvestorPeriod[], field: Field) => {
    if (value) {
      const newValue = value.map((item) => item.value);
      setValue(field, newValue);
    }
  };
  const handleCategorySelect = (value: CategoryOption[]) => {
    if (value) {
      const newValue = value.map((item) => item.value);
      setValue("categories", newValue);
    }
  };

  const handleContribution = (
    field: "financial_investor" | "strategic_investor" | "wachstums_investor"
  ) => {
    const newValue = {
      ...contribution,
      [field]: !contribution[field],
    };
    setValue("contribution", newValue);
  };

  const submitForm = () => {
    const values = getValues();
    if (!values.email || !values.name) {
      window.alert("Please enter a valid name and email address");
      return;
    }
    addCustomInvestor(values);
    toggleOpen();
    reset();
  };
  return {
    methods,
    submitForm,
    open,
    toggleOpen,
    convertToObjValue,
    handleAutoCompleteSelect,
    handleCategorySelect,
    initial,
    setInitial,
    handleContribution,
  };
}
