import { Card, Section } from "../../..";
import { useTheme } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { TableWrapperTwo } from "./styled.commercial.table2";
import { FinancialMetricsTable } from "./FinancialMetricsTable";

export const FinancialMetrics = ({
  financialinformation,
  setFinancialinformation,
  NotEditable,
}) => {
  const theme = useTheme();
  const currentYear = new Date().getFullYear() - 1;
  const lastSixYears = Array.from({ length: 6 }, (_, index) => currentYear - index);
  const { t } = useTranslation(["company/due_dilligence"]);

  const financialMetrics = [
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.sales`), label: "" },
    {
      name: t(`company/due_dilligence:dd_commercial.finantialmetrics.total_output`),
      label: t(`company/due_dilligence:dd_commercial.finantialmetrics.sales_label`),
    },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.gross_profit`), label: "" },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.ebitda`), label: "" },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.ebit`), label: "" },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.net_income`), label: "" },
    {
      name: t(`company/due_dilligence:dd_commercial.finantialmetrics.cash_and_equivalents`),
      label: "",
    },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.current_assets`), label: "" },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.total_assets`), label: "" },
    {
      name: t(`company/due_dilligence:dd_commercial.finantialmetrics.total_debt`),
      label: t(`company/due_dilligence:dd_commercial.finantialmetrics.total_debt_label`),
    },
    { name: t(`company/due_dilligence:dd_commercial.finantialmetrics.equity`), label: "" },
  ];

  const handelChange = (metricsName, year, value) => {
    if (NotEditable) return;
    setFinancialinformation((prev) => ({
      ...prev,
      [metricsName]: {
        ...prev[metricsName],
        [year]: value,
      },
    }));
  };

  useEffect(() => {
    if (!financialinformation || Object.keys(financialinformation).length === 0) {
      let initialMetrics = {};
      for (let metric of financialMetrics) {
        initialMetrics[metric.name] = {};
        for (let i = 0; i < 6; i++) {
          initialMetrics[metric.name][currentYear - i] = 0;
        }
      }
      setFinancialinformation(initialMetrics);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financialinformation, financialMetrics, currentYear]);

  return (
    <Section>
      <Card>
        <Stack direction="column" sx={{ mt: 3 }}>
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.info.dark,
              fontWeight: "bold",
              mb: 1,
            }}
          >
            {t(`company/due_dilligence:dd_commercial.finantial_information`)}
          </Typography>
          <TableWrapperTwo>
            <thead>
              <tr>
                <th>{t(`company/due_dilligence:dd_commercial.position`)}</th>
                {lastSixYears.map((year, index) => (
                  <th key={index}>{year}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <FinancialMetricsTable
                financialMetrics={financialMetrics}
                handelChange={handelChange}
                financialinformation={financialinformation}
                lastSixYears={lastSixYears}
              />
            </tbody>
          </TableWrapperTwo>
        </Stack>
      </Card>
    </Section>
  );
};
