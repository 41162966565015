import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded";
import LightbulbOutlinedIcon from "@mui/icons-material/LightbulbOutlined";

import { StyledAlert, StyledAlertIcon, StyledAlertText } from "./styled.alert";

export const Alert = ({ children, type = "BASIC" }) => {
  return (
    <>
      <StyledAlert type={type}>
        <StyledAlertIcon type={type}>
          {type === "SUCCESS" && <CheckCircleOutlineOutlinedIcon fontSize="medium" />}
          {type === "ERROR" && <ErrorOutlineRoundedIcon fontSize="medium" />}
          {type === "WARNING" && <ErrorOutlineRoundedIcon fontSize="medium" />}
          {type === "INFO" && <ErrorOutlineRoundedIcon fontSize="medium" />}
          {type === "BASIC" && <LightbulbOutlinedIcon fontSize="medium" />}
        </StyledAlertIcon>
        <StyledAlertText>{children}</StyledAlertText>
      </StyledAlert>
    </>
  );
};

/**
 * "BASIC"
 * "SUCCESS"
 * "ERROR"
 * "WARNING"
 * "INFO"
 */
