import { styled } from "@mui/material/styles";
import { rgba } from "polished";

const StyledTextPlaceholder = styled("div")(({ length = 1 }) => {
  const randomWidth = Math.floor(Math.random() * length) + 1;
  const randomDelay = Math.floor(Math.random() * 10) * 100;

  return {
    display: "inline-block",
    width: `${randomWidth * 15}px`,
    height: "15px",
    backgroundColor: "#636871",
    position: "relative",
    overflow: "hidden",
    float: "right",

    "&:after": {
      content: '""',
      height: "150%",
      width: "10px",
      position: "absolute",
      backgroundImage: `linear-gradient(90deg, ${rgba("#636871", 0)}, ${rgba(
        "#636871",
        0.25
      )}, ${rgba("#636871", 0)})`,
      animation: "slidingLine 2s infinite",
      animationDelay: `${randomDelay}ms`,
    },

    "@keyframes slidingLine": {
      "0%": {
        left: "-20%",
        top: "-25%",
        width: "50%",
      },
      "100%": {
        top: "-25%",
        left: "200%",
        width: "100%",
      },
    },
  };
});

export const InputLoader = ({ children, ...props }) => {
  return <StyledTextPlaceholder {...props} />;
};
