import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useTransactions = () => {
  // to get all transactions
  const getAllTransactions = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.TRANSACTION_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to get transaction by transaction_id
  const getTransactionById = (transactionId) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.TRANSACTION_API_ENDPOINT}/${transactionId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  // to create a new transaction
  const createTransaction = (data) => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.TRANSACTION_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: data,
    });
  };

  // to update transaction
  const updateTransaction = (transactionId, data) => {
    return API.patch(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.TRANSACTION_API_ENDPOINT}/${transactionId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
        body: data,
      }
    );
  };

  // to delete a transaction
  const deleteTransaction = (transactionId) => {
    return API.del(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.TRANSACTION_API_ENDPOINT}/${transactionId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  // to get all transaction files
  const getTransactionFiles = (transactionId) => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.FILE_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
        transaction_id: transactionId,
      },
    });
  };

  // to get transactions by advisor_id
  const getTransactionsByAdvisorId = (advisorId) => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.TRANSACTION_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
        advisor_id: advisorId,
      },
    });
  };

  // to get transactions by advisor client id
  const getTransactionsByAdvisorClientId = (advisorClientId) => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.TRANSACTION_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
        client_id: advisorClientId,
      },
    });
  };

  // to get file by file_id
  const getFileById = (fileId) => {
    return API.get(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.FILE_API_ENDPOINT}/${fileId}?download=true`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
      }
    );
  };

  // to delete a file
  const deleteFileById = (fileId) => {
    return API.del(TConfig.webapp.APP_API_ID, `${TConfig.webapp.FILE_API_ENDPOINT}/${fileId}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  return {
    getAllTransactions,
    getTransactionById,
    createTransaction,
    updateTransaction,
    deleteTransaction,
    getTransactionFiles,
    getTransactionsByAdvisorId,
    getTransactionsByAdvisorClientId,
    getFileById,
    deleteFileById,
  };
};
