export interface RegistrationMetadata {
  scope: "registration";
  data: {
    created_at: number;
  };
}

export interface SignupMetadata {
  scope: "signup";
  data: {
    sources: string[];
  };
}
export enum ProcessSteps {
  ONBOARDING = "ONBOARDING",
  DATEN_IMPORT = "DATEN_IMPORT",
  PERSONAL_TALK = "PERSONAL_TALK",
  CLIENT_AGREEMENT = "CLIENT_AGREEMENT",
  TRANSACTION_CHECK = "TRANSACTION_CHECK",

  OVERVIEW = "OVERVIEW",
  STATUS = "STATUS",
  DETAILS = "DETAILS",
  INVESTOR_MATCHING = "INVESTOR_MATCHING",
  DUE_DILIGENCE = "DUE_DILIGENCE",
  INVESTOR_STATUS = "INVESTOR_STATUS",
  DD_LEGAL = "DD_LEGAL",
  DD_FINANCIAL = "DD_FINANCIAL",
  INVESTMENT_DOCUMENTS = "INVESTMENT_DOCUMENTS",
  DD_TAX = "DD_TAX",
  DD_COMMERCIAL = "DD_COMMERCIAL",
  DD_DASHBOARD = "DD_DASHBOARD",
  DD_PLANUNG = "DD_PLANUNG",
  DD_BERICHTE = "DD_BERICHTE",
  LAWYER_MATCHING = "LAWYER_MATCHING",
}

export interface AdvisorMetadata {
  scope: "advisor";
  data: Partial<{
    categories: string[];
    sub_categories: string[];
    phases: string[];
    region: string[];
    investor_whitelist?: string[];
  }>;
}
export interface ProfileMetadata {
  scope: "profile";
  data: Partial<{
    categories: string[];
    sub_categories: string[];
    legal_form: string;
    fiscal_year: string;
    trade_registration: string;
    no_bankruptcy: boolean;
    no_project_business: boolean;
    website: string;
    dashboard_currentStep: ProcessSteps;
    dashboard_lastStep: ProcessSteps;
    dashboard_completedSteps: ProcessSteps[];
    products_data: Product[];
    customers_data: Customers[];
    nace_code: string[];
  }>;
}

export interface SessionMetadata {
  scope: "session";
  data: {
    selected_client: boolean;
  };
}

export interface StateMetadata {
  scope: "state";
  data: {
    selected_client?: boolean;
    registered?: boolean;
    avv_received?: boolean;
    nda_received?: boolean;
    invest_request?: boolean;
    supervisor?: boolean;
    susa?: boolean;
    onboard_call_sheduled?: boolean;
    onboard_call?: boolean;
    canei_check_1?: boolean;
    palturai_report?: boolean;
    transaction_approved?: boolean;
    contract_company_received?: boolean;
    contract_company_approved?: boolean;
    contract_company_signed?: boolean;
    invoice_1?: boolean;
    invoice_1_paid?: boolean;
    advisor_matching?: boolean;
    advisor_accepted?: boolean;
    contract_advisor_signed?: boolean;
    dd_company_data?: boolean;
    dd_financial_data?: boolean;
    dd_marketing_sales?: boolean;
    dd_organisation?: boolean;
    dd_operating_facilities?: boolean;
    dd_personal?: boolean;
    dd_taxes?: boolean;
    dd_approved?: boolean;
    factbook?: boolean;
    teaser?: boolean;
    spa?: boolean;
    sha?: boolean;
    investor_list?: boolean;
    investor_final?: boolean;
    matching_list?: boolean;
    invoice_2?: boolean;
    invoice_2_paid?: boolean;
    investor_mailing?: boolean;
    investor_interest?: boolean;
    investor_nda?: boolean;
    investor_nda_signed?: boolean;
    investor_access?: boolean;
    investor_decision?: boolean;
    invest_contract_prepared?: boolean;
    invest_contract_received?: boolean;
    invest_contract_scheduled?: boolean;
    invest_contract_signed?: boolean;
    invoice_success?: boolean;
    invoice_success_paid?: boolean;
    pr_news_release?: boolean;
    archived?: boolean;
    contract_company_envelope_id?: string;
  };
}

export interface TransactionInvestorsStateMetadata {
  scope: "state";
  data: {
    approved: boolean;
    confirmed: boolean;
    interested: boolean;
    invited: boolean;
    proposed: boolean;
    selected: boolean;
    signed: boolean;
  };
}

export interface SusaMetadata {
  scope: "susa";
  data: {
    number_employees_prev_year: string;
    number_employees_last_year: string;
    ownerships: Ownership[];
    sales_last_year: string;
    sales_prev_year: string;
    ebitda_prev_year: string;
    ebitda_last_year: string;
    ebit_prev_year: string;
    ebit_last_year: string;
  };
}
export interface Ownership {
  id: string;
  description: string;
  value: string;
  type: string;
  email: string;
}

export interface CommentsMetadata {
  scope: "comments";
  data: Comment[];
}

export interface Comment {
  id: string;
  timestamp: number;
  user: string;
  userName: string;
  role: "platform" | "advisor";
  private: boolean;
  msg: string;
}

export interface CalendlyMetadata {
  scope: "calendly";
  data: {
    appointments: CalendlyAppointment[];
  };
}

export interface CalendlyAppointment {
  cancelUrl: string;
  date: number;
  dateString: string;
  day: number;
  endTime: string;
  event: string;
  event_url: string;
  invitee_url: string;
  month: number;
  rescheduleUrl: string;
  rescheduled: string;
  startTime: string;
  status: string;
  year: number;
}

export interface SecondaryMetadata {
  scope: "secondary";
  data: {
    adresses: SecondaryData[];
  };
}

export interface SecondaryData {
  id: any;
  first_name: string;
  last_name: string;
  street: string;
  city: string;
  postal_code: number;
  country: string;
  form_state: {
    initial: boolean;
    valid: boolean;
  };
}

export interface Metadata {
  scope:
    | "calendly"
    | "registration"
    | "signup"
    | "profile"
    | "session"
    | "state"
    | "susa"
    | "comments"
    | "secondary";
  data: unknown;
}

export interface Product {
  product_name: string;
  revenue_type: string;
  sales_volume: number;
}

export interface Customers {
  customer_name: string;
  big_volume: number;
  revenue_volume: number;
}
