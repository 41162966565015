import { useState, useEffect } from "react";
import { useTransactions } from "../../hooks";
import { useParams } from "react-router-dom";
import { LoadingIndicator } from "../LoadingIndicator";
import axios from "axios";

const useFileUrl = () => {
  const { transactionId } = useParams();
  const { getFileById, getTransactionFiles } = useTransactions();
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handleResize = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchFiles = async () => {
      const files = await getTransactionFiles(transactionId);
      const filteredFiles = files.files.filter((file) => file.file_urn === "invest_factbook");
      const sortedFiles = filteredFiles.sort(function (a, b) {
        return b.uploaded_at >= a.uploaded_at ? 1 : -1;
      });
      const lastFile = sortedFiles[0];
      const file = await getFileById(lastFile.file_id);
      const results = await axios.get(file?.file.download_url, {
        responseType: "blob",
      });

      if (results?.data) {
        const res = new File([results?.data], file.file.file_name, {
          type: file.file.content_type,
        });
        const url = URL.createObjectURL(res);
        setFile(url);
        setIsLoading(false);
      }
    };
    fetchFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { file, isLoading, height };
};

export default function InvestmentDossierView() {
  const { file, isLoading, height } = useFileUrl();

  return (
    <>
      {isLoading && <LoadingIndicator type={"PROGRESS"} />}
      {!isLoading && (
        <div>
          <object
            data={file}
            type="application/pdf"
            title="PDF Viewer"
            width="100%"
            height={height}
          />
        </div>
      )}
    </>
  );
}
