import { styled } from "@mui/material/styles";

const StyledTextLoader = styled("span")(() => {
  const randomDelay = Math.floor(Math.random() * 10) * 100;
  return {
    color: "transparent",
    textShadow: "0 0 12px #495057",
    animation: "animateBlur 2s infinite",
    animationDelay: `${randomDelay}ms`,

    "@keyframes animateBlur": {
      "0%": {
        textShadow: "-2px 0 8px #495057",
      },
      "50%": {
        textShadow: "2px 0 6px #f5f5f5",
      },
      "100%": {
        textShadow: "-2px 0 8px #495057",
      },
    },
  };
});

export const TextLoader = ({ length = 1, placeholder }) => {
  const getRandomLetter = () => {
    const result = new Array(length).fill("");

    return result
      .map((l) => String.fromCharCode(Math.floor(Math.random() * (122 - 65)) + 65))
      .join("");
  };

  return placeholder ? (
    <StyledTextLoader>{placeholder}</StyledTextLoader>
  ) : (
    <StyledTextLoader>{getRandomLetter()}</StyledTextLoader>
  );
};
