import { useCallback, useEffect, useState } from "react";
import isEmail from "validator/lib/isEmail";
import { Auth } from "aws-amplify";

import { useLogin } from "./";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../Store/currentUser/currentUserSlice";

const initialParams = {
  code: "__INITIAL__",
  email: "__INITIAL__",
  password: "__INITIAL__",
  passwordRepeat: "__INITIAL__",
  loading: false,
};

export const useResetPassword = () => {
  const dispatch = useAppDispatch();
  const [params, setParams] = useState(initialParams);
  const [login, loginLoading] = useLogin();

  const authResetCallback = useCallback(
    (isMounted) => {
      const { email, code, password } = params;
      Auth.forgotPasswordSubmit(email, code, password)
        .then(() => {
          isMounted &&
            dispatch(
              setCurrentUser({
                authenticated: false,
                forceChange: false,
                errors: [],
              })
            );
          // Re-Login with new password
          login({ email, password });
        })
        .catch((e) => {
          // regarding issue 32, there is an error despite the resetpassword is working fine, probably some problem in aws, so we removed the dispatch not to show the error warning

          // //FIXME: Do not produce know error from AuthError
          const error = {
            // __typename: TypeNameEnums.AUTH_ERROR,
            ...e,
          };
          dispatch(setCurrentUser({ errors: [error] }));
          setParams(initialParams);
        });
    },
    [dispatch, login, params]
  );

  if (loginLoading === false && params.loading) {
    setParams({ ...params, loading: false });
  }

  useEffect(() => {
    let mounted = true;

    if (params.email === "__INITIAL__" || params.loading) return;
    authResetCallback(mounted);
    setParams({ ...params, loading: true });

    return () => {
      mounted = false;
    };
  }, [setParams, authResetCallback, params]);

  /** * [useLogin hook returns a callback and a isReady state] **/
  return [
    ({ email, code, password, passwordRepeat }) => {
      const errors = [];
      if (code.length === 0) {
        errors.push({
          // __typename: AUTH_ERROR,
          code: "EMPTY_STRING_NOT_ALLOWED",
          name: "empty_code",
        });
      }
      if (!isEmail(email)) {
        errors.push({
          // __typename: AUTH_ERROR,
          code: "STRING_MUST_BE_EMAIL",
          name: "invalid_email",
        });
      }
      if (password !== passwordRepeat) {
        errors.push({
          // __typename: AUTH_ERROR,
          code: "PASSWORD_AND_REPEAT_MUST_BE_SAME",
          name: "invalid_password_repeat",
        });
      }
      if (errors.length > 0) {
        dispatch(setCurrentUser({ errors }));
        setParams({ ...params, loading: false });
      }

      errors.length === 0 &&
        setParams({
          code,
          email,
          password,
          passwordRepeat,
          loading: false,
        });
    },
    params.loading,
  ];
};

/** --- AUTH_RESPONSES/ERROR ---
 * UNEXPECTED_ERROR = "UNEXPECTED_ERROR"
 * CODE_EXPIRED = "ExpiredCodeException"
 * RESET_REQUIRED = "PasswordResetRequiredException"
 * CODE_MISMATCH = "CodeMismatchException"
 * TOO_MANY_TRY = "LimitExceededException"
 * USER_NOT_FOUND = "UserNotFoundException"
 * USER_NOT_AUTHORIZED = "NotAuthorizedException"
 * INVALID_PASSWORD = "InvalidPasswordException"
 * INVALID_PARAMETER = "InvalidParameterException"
 * NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
 * NO_EMPTY = "EMPTY_STRING_NOT_ALLOWED"
 * EMAIL_REQUIRED = "STRING_MUST_BE_EMAIL"
 * PASSWORD_POLICY = "PASSWORD_MUST_FULFILL_POLICY"
 * PASSWORD_CONFIRM_MISMATCH = "PASSWORD_AND_REPEAT_MUST_BE_SAME"
 */
