import {
  InputLoader,
  TextLoader,
  ProgressIndicator,
  PageLoader,
  ComponentLoader,
  IconLoader,
} from "./";

export const LoadingIndicator = ({ type, ...props }) => {
  switch (type) {
    case "PAGE":
      return <PageLoader {...props} />;
    case "COMPONENT":
      return <ComponentLoader {...props} />;
    case "PROGRESS":
      return <ProgressIndicator {...props} />;
    case "INPUT_FIELD":
      return <InputLoader {...props} />;
    case "TEXT":
      return <TextLoader {...props} />;
    case "ICON":
      return <IconLoader {...props} />;
    default:
      return <ComponentLoader />;
  }
};

/**
 * PAGE
 * COMPONENT
 * PROGRESS
 * INPUT_FIELD
 * TEXT
 * ICON
 */
