import { styled } from "@mui/material/styles";

const StyledSlideIn = styled("div")(({ direction = "TO_TOP", out = false }) => {
  const animations = {
    TO_BOTTOM: { in: "animateToBottom", out: "animateToBottomOut" },
    TO_TOP: { in: "animateToTop", out: "animateToTopOut" },
    TO_RIGHT: { in: "animateToRight", out: "animateToRightOut" },
    TO_LEFT: { in: "animateToLeft", out: "animateToLeftOut" },
  };

  const slideInAnimation = out ? animations[direction].out : animations[direction].in;

  return {
    animation: `${slideInAnimation} 600ms forwards`,
    animationIterationCount: 1,
    position: "relative",
    left: 0,
    top: 0,
    width: "100%",
    height: "100%",

    "@keyframes animateToBottom": {
      "0%": {
        transform: "translateY(-120%)",
      },
      "100%": {
        transform: "translateY(0%)",
      },
    },

    "@keyframes animateToBottomOut": {
      "0%": {
        transform: "translateY(0%)",
      },
      "100%": {
        transform: "translateY(-120%)",
      },
    },

    "@keyframes animateToTop": {
      "0%": {
        transform: "translateY(120%)",
      },
      "100%": {
        transform: "translateY(0%)",
      },
    },

    "@keyframes animateToTopOut": {
      "0%": {
        transform: "translateY(0%)",
      },
      "100%": {
        transform: "translateY(120%)",
      },
    },

    "@keyframes animateToRight": {
      "0%": {
        transform: "translate(-120%, 0)",
      },
      "100%": {
        transform: "translate(0%, 0)",
      },
    },

    "@keyframes animateToRightOut": {
      "0%": {
        transform: "translateX(0%, 0)",
      },
      "100%": {
        transform: "translate(-120%, 0)",
      },
    },

    "@keyframes animateToLeft": {
      "0%": {
        transform: "translate(120%, 0)",
      },
      "100%": {
        transform: "translateX(0%, 0)",
      },
    },

    "@keyframes animateToLeftOut": {
      "0%": {
        transform: "translateX(0%, 0)",
      },
      "100%": {
        transform: "translate(-120%, 0)",
      },
    },
  };
});

export const SlideIn = ({ children, ...props }) => {
  return <StyledSlideIn {...props}>{children}</StyledSlideIn>;
};
