import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useUsers = () => {
  // to get all users
  const getUsers = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to get user by Id
  const getUserById = (userId) => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}/${userId}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to get company users in supervisor and advisor
  const getCompanyUsers = (customerId) => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}`, {
      queryStringParameters: {
        customer_id: customerId,
        cov_id: getCovid(),
      },
    });
  };

  // to create a new user
  const createUser = (customer_id, email) => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: {
        customer_id,
        email,
        access: {
          planning: "write",
          customer: "none",
        },
      },
    });
  };

  /*
  example of createClientUser parameter data
  data = {
    "customer_id": "1d98c5db-fdd5-49b0-b197-d499f67ed3be",
    "client_id": "a6c57c1b-75bc-455c-84a2-3fbcdf4eaef7",
    "email": "beteiligungsboerse+test_client@gmail.com",
    "first_name": "Max",
    "last_name": "Mustermann",
    "telephone": "+1-234-567-8901",
},
  */
  // to create a new user of CLIENT in A3
  const createClientUser = (data) => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}`, {
      queryStringParameters: {
        client_id: data.client_id,
        cov_id: getCovid(),
      },
      body: {
        ...data,
        access: {
          planning: "write",
          customer: "none",
        },
      },
    });
  };

  // to update user
  const updateUser = (userId, data) => {
    return API.patch(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}/${userId}`, {
      queryStringParameters: {
        client_id: data.client_id,
        cov_id: getCovid(),
      },
      body: data,
    });
  };

  // to delete a user
  const deleteUser = (userId) => {
    return API.del(TConfig.webapp.APP_API_ID, `${TConfig.webapp.USER_API_ENDPOINT}/${userId}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  return {
    getUsers,
    getUserById,
    getCompanyUsers,
    createUser,
    createClientUser,
    updateUser,
    deleteUser,
  };
};
