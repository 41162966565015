import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useInvestors } from "../../hooks";
import { useInvestmentTeaser } from "../../hooks/useInvestmentTeaser";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useDispatch } from "react-redux";
import BarChartIcon from "@mui/icons-material/BarChart";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const useInvestor = ({ investor }) => {
  const { getInvestors } = useInvestors();
  const { getInvite } = useInvestmentTeaser();
  const { transactionId } = useParams();
  const [investors, setInvestors] = useState();
  const [invite, setInvite] = useState();
  const dispatch = useDispatch();

  const getInvestor = async () => {
    if (investors) return;
    dispatch(setCurrentUser({ loading: true }));

    const investorsData = await getInvestors(transactionId);
    const FilterInvestor = investorsData?.investors?.find((inv) => inv.customer_id === investor.id);

    setInvestors(FilterInvestor);
    dispatch(setCurrentUser({ loading: false }));
  };

  const GetInvite = async () => {
    const invited = await getInvite(transactionId);
    const FilterInvite = invited?.invites.find((inv) => inv.customer_id === investor.id);
    setInvite(FilterInvite);
  };

  const invitedAt = new Date(invite?.invited_at);
  let formattedDate;

  if (!isNaN(invitedAt.getTime())) {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    formattedDate = invitedAt.toLocaleDateString("en-US", options);
  } else {
    formattedDate = "-";
  }

  return { getInvestor, GetInvite, investors, invite, formattedDate };
};

export default function InvestorAnalyticsPoupup({ investor, disabled }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { getInvestor, GetInvite, investors, invite, formattedDate } = useInvestor({ investor });

  return (
    <div>
      <BarChartIcon
        onClick={() => {
          if (!disabled) {
            handleOpen();
            GetInvite();
            getInvestor();
          }
        }}
        color={disabled ? "disabled" : "action"}
      />
      {(invite || investors) && (
        <>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left" style={{ width: "33.33%" }}>
                        Name
                      </TableCell>
                      <TableCell align="center" style={{ width: "33.33%" }}>
                        Ansichtsstatus
                      </TableCell>
                      <TableCell align="right" style={{ width: "33.33%" }}>
                        Einladungszeit
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left" style={{ width: "33.33%" }}>
                        {investors?.name}
                      </TableCell>
                      <TableCell align="center" style={{ width: "33.33%" }}>
                        {invite?.count}
                      </TableCell>
                      <TableCell align="right" style={{ width: "33.33%" }}>
                        {formattedDate}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Modal>
        </>
      )}
    </div>
  );
}
