import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useAppActivity = () => {
  // used for user login events (call the function)
  const userLoginActivity = () => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.EVENT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: {
        type: TConfig.app_event.TYPES.LOGIN,
        urn: TConfig.app_event.URN.WEBAPP,
      },
    });
  };

  return {
    userLoginActivity,
  };
};
