import { StyledSimpleContent, StyledSimpleFooter, StyledSimplePage } from "./styled.simple";
import { Footer } from "./Footer";

export const SimpleLayout = ({ children }) => {
  return (
    <>
      <StyledSimplePage>
        <StyledSimpleContent>{children}</StyledSimpleContent>
        <StyledSimpleFooter>
          <Footer />
        </StyledSimpleFooter>
      </StyledSimplePage>
    </>
  );
};
