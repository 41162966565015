import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Typography from "@mui/material/Typography";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useMediaQuery } from "@mui/material";
import { useTheme, styled } from "@mui/material/styles";
import { darken } from "@mui/system";

import { Wrapper } from "./styled.sidebar";

// custom Tooltip
const CustomTooltipWrapper = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: darken(theme.palette.primary.main, 0.4),
    color: theme.palette.common.white,
    boxShadow: theme.shadows[2],
    fontSize: 12,
    fontWeight: "bold",
    padding: "7px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: darken(theme.palette.primary.main, 0.4),
  },
}));

export const Sidebar = ({ contentData, changeCurrentStep }) => {
  const { t } = useTranslation(["company/common"]);

  const theme = useTheme();
  const isTablet = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  // to define the lastStep index
  const lastStepIndex = useMemo(() => {
    const lastStepInd = contentData?.steps.findIndex(
      ({ stepId }) => stepId === contentData?.lastStep
    );
    return lastStepInd;
  }, [contentData]);

  return (
    <Wrapper>
      <Box sx={{ maxWidth: 400 }}>
        <Typography
          variant="h5"
          align="left"
          sx={{
            fontWeight: "bold",
            mb: 1,
            paddingLeft: "40px",
            fontSize: { xs: "1rem", md: "1.3rem" },
            color: theme.palette.common.white,
          }}
        >
          {t("company/common:sidebar.headline")}
        </Typography>
        <Stepper activeStep={lastStepIndex} orientation="vertical" sx={{ cursor: "pointer" }}>
          {isTablet &&
            contentData?.steps.map((step, index) => (
              <CustomTooltipWrapper key={step.label} title={step.label} placement="right" arrow>
                <Step>
                  <StepLabel
                    onClick={() => index <= lastStepIndex && changeCurrentStep(step.stepId)}
                    className={index <= lastStepIndex ? "completed" : "notCompleted"}
                  >
                    <Typography
                      variant="subtitle1"
                      color="primary"
                      className="textLabel"
                      sx={{
                        fontWeight: index === lastStepIndex ? "bold" : "normal",
                        "&:hover": {
                          cursor: index <= lastStepIndex ? "pointer" : "initial",
                        },
                      }}
                    >
                      {step.label}
                    </Typography>
                  </StepLabel>
                </Step>
              </CustomTooltipWrapper>
            ))}
          {!isTablet &&
            contentData?.steps.map((step, index) => (
              <Step key={step.label}>
                <StepLabel
                  onClick={() => index <= lastStepIndex && changeCurrentStep(step.stepId)}
                  className={index <= lastStepIndex ? "completed" : "notCompleted"}
                >
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    className="textLabel"
                    sx={{
                      fontWeight: index === lastStepIndex ? "bold" : "normal",
                      "&:hover": {
                        cursor: index <= lastStepIndex ? "pointer" : "initial",
                      },
                    }}
                  >
                    {step.label}
                  </Typography>
                </StepLabel>
              </Step>
            ))}
        </Stepper>
      </Box>
    </Wrapper>
  );
};
