import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface LanguageState {
  [transactionId: string]: string;
}

const initialState: LanguageState = {};

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<{ transactionId: string, language: string }>) => {
      const { transactionId, language } = action.payload;
      state[transactionId] = language;
      localStorage.setItem(`selectedLanguage_${transactionId}`, language);
    },
    loadLanguage: (state, action: PayloadAction<{ transactionId: string }>) => {
      const { transactionId } = action.payload;
      const savedLanguage = localStorage.getItem(`selectedLanguage_${transactionId}`) || 'de';
      state[transactionId] = savedLanguage;
    },
  },
});

export const { setLanguage, loadLanguage } = languageSlice.actions;
export default languageSlice.reducer;