import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";

export const Wrapper = styled("div")(({ theme }) => ({
  "& table": {
    border: `1px solid ${theme.palette.grey[300]}`,

    "& th": {
      textAlign: "left",
      margin: 0,
      padding: "0.3rem",
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderRight: `1px solid ${theme.palette.grey[300]}`,
      "&:last-child": {
        borderRight: 0,
      },
    },
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "6.5px 10px",
    fontSize: "14px",
    "&:focus + fieldset": {
      borderWidth: "1px",
    },
  },
}));
export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "5px 10px",
    fontSize: "14px",
    "& fieldset": {
      borderWidth: "1px !important",
    },
  },
}));
export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 15,
  },
}));

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
