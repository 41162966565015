import { useState, useEffect, useMemo } from "react";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import {
  useGetCategoriesQuery,
  useGetCategoryTreesQuery,
} from "../../Store/api/datastore/categories/categoriesApi";
import {
  getCategoryOptions,
  getCategoryValues,
} from "../CategoriesDropdown/CategoriesDropdown.utils";
import {
  CategoriesColumnFilter,
  SubCategoriesColumnFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
} from "./helper";
import { useTranslation } from "react-i18next";
import { IconButton, Tooltip } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

export default function useInvestorTable(investors) {
  const { t } = useTranslation(["platform/common", "investor/common"]);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const [transactionData, setTransactionData] = useState([]);
  const categoriesQuery = useGetCategoriesQuery();
  const categories = categoriesQuery.data;
  const categoriesTreeQuery = useGetCategoryTreesQuery();
  const categoriesTree = categoriesTreeQuery.data;
  // update state transactionData with props data

  useEffect(() => {
    const getOptionSummary = (keyName, options) => {
      return options.map((option) => {
        const valuePair = currentUser.datastore[keyName].filter(
          (item) => item.value === option || item.summary === option
        );
        return valuePair[0].summary;
      });
    };

    if (investors && Array.isArray(investors)) {
      const translated = investors.map((investor) => {
        const categoryIds =
          investor.categories.map((item) => {
            return typeof item === "string" ? item : item.cat;
          }) || [];

        const subCategoryIds =
          investor.sub_categories?.map((item) => {
            return typeof item === "string" ? item : item.cat;
          }) || [];

        const contributionArr = Object.keys(investor.contribution)
          .filter((item) => investor.contribution[item] === true)
          .map((item) => t(`investor/common:content.investment.contribution.${item}`) || item);
        return {
          ...investor,
          period: getOptionSummary("period", investor.period),
          phases: getOptionSummary("phases", investor.phases),
          types: getOptionSummary("types", investor.types),
          categories: getCategoryValues(categoryIds, categories).map((cat) => cat.label),
          sub_categories: getCategoryValues(subCategoryIds, categories).map((cat) => cat.label),
          contribution: contributionArr ? contributionArr : ["keine"],
        };
      });
      setTransactionData(translated);
    }
  }, [t, currentUser.datastore, categories, investors]);

  const datastore = useMemo(() => {
    const dataArr = currentUser.datastore;
    return {
      period: dataArr.period.map((item) => item.summary),
      phases: dataArr.phases.map((item) => item.summary),
      stake: dataArr.stake.map((item) => item.value),
      revenue: dataArr.revenue.map((item) => item.value),
      invest_bracket: dataArr.invest_bracket.map((item) => item.value),
      ebitda_bracket: dataArr.ebitda_bracket.map((item) => item.value),
      types: dataArr.types.map((item) => item.summary),
      contribution: [
        t(`investor/common:content.investment.contribution.strategic_investor`),
        t(`investor/common:content.investment.contribution.financial_investor`),
        t(`investor/common:content.investment.contribution.wachstums_investor`),
      ],
    };
  }, [t, currentUser.datastore]);

  const categoryOptions = useMemo(() => {
    const options = getCategoryOptions(categories, []);
    return options;
  }, [categories]);

  const [options, setOptions] = useState({
    name: true,
    email: false,
    categories: true,
    sub_categories: true,
    revenue: true,
    invest: true,
    ebitda: true,
    period: true,
    phases: false,
    stake: true,
    types: false,
    contribution: false,
  });

  const handleOptions = (e) => {
    const value = e.target.value;
    setOptions((prev) => {
      return {
        ...prev,
        [value]: !prev[value],
      };
    });
  };

  const defaultColumn = useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = useMemo(
    () =>
      [
        {
          Header: t("platform/common:content.investor_matching.columns.name"),
          accessor: "name",
          Filter: ({ column }) => (
            <DefaultColumnFilter
              column={column}
              placeholder={t("de/common:document_table.search_by_name")}
            />
          ),
        },
        {
          Header: t("platform/common:content.investor_matching.columns.email"),
          accessor: "email",
          Filter: ({ column }) => <DefaultColumnFilter column={column} />,
        },

        {
          Header: () => (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <div>{t("platform/common:content.investor_matching.columns.categories")}</div>
              <Tooltip title={t("platform/common:content.investor_matching.columns.multiple_choices")} placement="top" arrow>
                <IconButton size="small" style={{ marginLeft: '5px', padding: '0' }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ),
          accessor: "categories",
          Filter: ({ column }) => (
            <div style={{ width: "100%" }}>
              <CategoriesColumnFilter column={{ ...column, categoryOptions: categoryOptions }} />
            </div>
          ),
          filter: "includesSome",
        },
        {
          Header: () => (
            <div style={{ display: 'inline-flex', alignItems: 'center' }}>
              <div>{t("platform/common:content.investor_matching.columns.sub_categories")}</div>
              <Tooltip title={t("platform/common:content.investor_matching.columns.multiple_choices")} placement="top" arrow>
                <IconButton size="small" style={{ marginLeft: '5px', padding: '0' }}>
                  <InfoIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </div>
          ),
          accessor: "sub_categories",
          Filter: (props) => {
            return (
              <SubCategoriesColumnFilter
                columns={props.columns}
                column={{
                  ...props.column,
                  categoriesTree: categoriesTree || [],
                  categories: categories || [],
                  categoryOptions: categoryOptions,
                }}
              />
            );
          },
          filter: "includesSome",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.revenue"),
          accessor: "revenue",

          Filter: (props) => {
            return (
              <SelectColumnFilter column={{ ...props.column, arrOptions: datastore.revenue }} />
            );
          },
          filter: "includesSome",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.invest"),
          accessor: "invest",
          Filter: (props) => {
            return (
              <SelectColumnFilter
                column={{ ...props.column, arrOptions: datastore.invest_bracket }}
              />
            );
          },
          filter: "between",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.ebitda"),
          accessor: "ebitda",
          Filter: ({ column }) => (
            <SelectColumnFilter
              column={{ ...column, name: "ebitda", arrOptions: datastore.ebitda_bracket }}
            />
          ),
          filter: "includesSome",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.period"),
          accessor: "period",
          Filter: ({ column }) => (
            <SelectColumnFilter column={{ ...column, arrOptions: datastore.period }} />
          ),
          filter: "includes",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.phases"),
          accessor: "phases",
          Filter: ({ column }) => (
            <SelectColumnFilter column={{ ...column, arrOptions: datastore.phases }} />
          ),
          filter: "includes",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.stake"),
          accessor: "stake",
          Filter: ({ column }) => (
            <SelectColumnFilter column={{ ...column, arrOptions: datastore.stake }} />
          ),
          filter: "includes",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.types"),
          accessor: "types",
          Filter: ({ column }) => (
            <SelectColumnFilter column={{ ...column, arrOptions: datastore.types }} />
          ),
          filter: "includes",
        },
        {
          Header: t("platform/common:content.investor_matching.columns.contribution"),
          accessor: "contribution",
          Filter: ({ column }) => (
            <SelectColumnFilter column={{ ...column, arrOptions: datastore.contribution }} />
          ),
          filter: "includes",
        },
      ].filter((option) => options[option.accessor]),
    [
      t,
      categoryOptions,
      categories,
      categoriesTree,
      options,
      datastore.contribution,
      datastore.stake,
      datastore.phases,
      datastore.period,
      datastore.revenue,
      datastore.invest_bracket,
      datastore.ebitda_bracket,
      datastore.types,
    ]
  );
  return [options, handleOptions, transactionData, columns, defaultColumn];
}
