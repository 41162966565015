import { StyledTextField, StyledSelect } from "./styled.tableComp";
import { Stack, Button, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";

const InvestorTablePagination = (props) => {
  const { t } = useTranslation(["platform/common"]);

  const {
    gotoPage,
    canPreviousPage,
    canNextPage,
    previousPage,
    nextPage,
    pageCount,
    pageIndex,
    pageSize,
    setPageSize,
    pageOptions,
  } = props;

  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      spacing={1}
      sx={{ marginTop: "10px" }}
    >
      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          gotoPage(0);
        }}
        disabled={!canPreviousPage}
        sx={{ minWidth: "35px", padding: "5px" }}
      >
        {"<<"}
      </Button>

      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          previousPage();
        }}
        disabled={!canPreviousPage}
        sx={{ minWidth: "35px", padding: "5px" }}
      >
        {"<"}
      </Button>

      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          nextPage();
        }}
        disabled={!canNextPage}
        sx={{ minWidth: "35px", padding: "5px" }}
      >
        {">"}
      </Button>

      <Button
        size="small"
        variant="outlined"
        onClick={(e) => {
          e.preventDefault();
          gotoPage(pageCount - 1);
        }}
        disabled={!canNextPage}
        sx={{ minWidth: "35px", padding: "5px" }}
      >
        {">>"}
      </Button>

      <span>
        {t("transaction_tables.pagination.page")}
        <strong>
          {pageIndex + 1} {t("transaction_tables.pagination.of")} {pageOptions.length}
        </strong>
      </span>
      <span>|  {t("transaction_tables.pagination.go_to_page")}</span>
      <StyledTextField
        defaultValue={pageIndex + 1}
        type="number"
        onChange={(e) => {
          e.preventDefault();
          const page = e.target.value ? Number(e.target.value) - 1 : 0;
          gotoPage(page);
        }}
        sx={{ width: "70px" }}
      />
      <StyledSelect
        size="small"
        value={pageSize}
        onChange={(e) => {
          e.preventDefault();
          setPageSize(Number(e.target.value));
        }}
      >
        {[10, 20, 30, 40, 50].map((pageSize, i) => (
          <MenuItem key={i} value={pageSize}>
            {t("transaction_tables.pagination.show")}  {pageSize}
          </MenuItem>
        ))}
      </StyledSelect>
    </Stack>
  );
};

export default InvestorTablePagination;
