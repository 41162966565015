import React, { useState } from "react";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Badge from "@mui/material/Badge";
import MailIcon from "@mui/icons-material/Mail";
import { useSpring, animated } from "@react-spring/web";

export const Notifications = ({ notificationsList, transactionId }) => {
  const [notifications, setNotifications] = useState(notificationsList); // [1]
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleFileView = (fileId, transactionId) => {
    window.open(`/${transactionId}/documentViewer/${fileId}`, "_blank");
  };

  const [props, api] = useSpring(
    () => ({
      from: { y: -10, x: 10 },
      to: { y: -25, x: 10 },
      loop: {
        reverse: true,
      },
    }),
    []
  );

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        style={{
          textTransform: "none",
          fontWeight: "bold",
          gap: "5px",
          display: "flex",
          paddingLeft: "0px",
          marginLeft: "-15px",
        }}
      >
        <animated.div style={props}>
          <Badge
            badgeContent={notifications.length}
            color="error"
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            style={{ marginRight: "5px", marginLeft: "3px" }}
          ></Badge>
        </animated.div>
        <MailIcon color="action" />
        Benachrichtigung
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {notifications?.map((notification) => (
          <MenuItem
            onClick={() => {
              handleFileView(notification.id, transactionId);
              handleClose();
              setNotifications((prev) => {
                return prev.filter((item) => item.id !== notification.id);
              });
            }}
          >
            {notification.section} - {notification.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
