import { styled } from "@mui/material/styles";

const StyledComponentLoader = styled("div")(() => {
  const color1 = "#193C6C";
  const color2 = "#61BCA7";
  return {
    display: "block",
    width: "100%",
    height: "4px",
    backgroundColor: "white",
    position: "absolute",
    left: "0px",
    top: "0px",
    overflow: "hidden",
    "&:before": {
      content: '""',
      width: "25%",
      height: "100%",
      animation: "loading 1.5s infinite",
      position: "absolute",
      left: "0px",
      top: "0px",
      backgroundImage: `linear-gradient(90deg, transparent, ${color1}, ${color2}, ${color2}, ${color2}, transparent)`,
    },

    "@keyframes loading": {
      "0%": {
        left: "0px",
        width: "25%",
      },
      "100%": {
        left: "100%",
        width: "100%",
      },
    },
  };
});

const StyledComponentLoaderWrapper = styled("div")(() => {
  return {
    position: "relative",
    transform: "translateX(4px)",
  };
});
export const ComponentLoader = () => {
  return (
    <StyledComponentLoaderWrapper>
      <StyledComponentLoader />
    </StyledComponentLoaderWrapper>
  );
};
