import React from "react";
import InvestorTable from "../../InvestorTable/InvestorTable";

const InvestorInvite = ({
  invitations,
  loadingInvitations,
  expandCategories,
  setExpandCategories,
  disabled,
  renderActions,
}: any) => {
  return (
    <>
      <InvestorTable
        renderActions={renderActions}
        disabled={disabled}
        investors={invitations}
        loading={loadingInvitations}
        expandCategories={expandCategories}
        setExpandCategories={setExpandCategories}
      />
    </>
  );
};

export default InvestorInvite;
