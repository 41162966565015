import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import Stack from "@mui/material/Stack";

import { LoginError } from "./LoginError";
import { ErrorBoundary } from "./ErrorBoundary";
import { FadeIn } from "./Transitions";
import { Alert } from "./Alert";
import { ContainerWrapper } from "./ContainerWrapper";
import { Logo } from "./Logo";
import { LoadingIndicator } from "./LoadingIndicator";

import { useForgotPassword } from "../hooks";
import { SimpleLayout } from "../../../../libs/Layout";
import { useAppSelector } from "../Store/hooks/useAppSelector";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isRequested, setIsRequested] = useState(false);
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const forgotPassword = useForgotPassword();

  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  function handleForgot(event) {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email");

    setLoading(true);

    forgotPassword(email).finally(() => {
      setLoading(false);
      setIsRequested(true);
    });
  }

  return (
    <SimpleLayout>
      <ErrorBoundary>
        <ContainerWrapper width={"sm"}>
          <FadeIn>
            {loading && <LoadingIndicator type={"COMPONENT"} />}
            <Box
              component="form"
              onSubmit={handleForgot}
              sx={{
                p: { xs: 2, sm: 3 },
                backgroundColor: theme.palette.common.white,
              }}
            >
              <Stack direction="row" justifyContent="center" sx={{ mb: 5 }}>
                <Logo />
              </Stack>
              <Typography variant="body1" align="justify">
                {t("forgot.info_text")}
              </Typography>
              <TextField
                margin="normal"
                label={t("forgot.email.label")}
                name="email"
                id="forgot_pass_email"
                type="email"
                size={isMobile ? "small" : "medium"}
                autoFocus
                required
                fullWidth
                sx={{ mt: 5, mb: 2 }}
              />
              {isRequested && currentUser.errors.length > 0 && <LoginError />}

              {isRequested && currentUser.errors.length === 0 && (
                <Alert type={"SUCCESS"}>
                  <Trans i18nKey={`forgot.confirm_text`} values={{ email: currentUser.email }}>
                    Wir haben Ihren Bestätigungscode an Ihre E-Mail-Adresse gesendet....
                    <Link href="/reset" color="error">
                      <Typography component="span">hier Ihren Bestätigungscode ein.</Typography>
                    </Link>
                  </Trans>
                </Alert>
              )}

              <Button
                type="submit"
                color="primary"
                fullWidth
                size={isMobile ? "small" : "medium"}
                variant="contained"
                sx={{
                  mt: 3,
                  mb: 2,
                  fontWeight: "bold",
                  color: theme.palette.secondary.main,
                }}
              >
                {t("forgot.submit")}
              </Button>

              <Stack alignItems="center">
                <Link href="/" variant="body2" color="error">
                  {t("misc.back")}
                </Link>
              </Stack>
            </Box>
          </FadeIn>
        </ContainerWrapper>
      </ErrorBoundary>
    </SimpleLayout>
  );
};

export default ForgotPassword;
