import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./rootReducer";
import { countriesApi } from "./api/datastore/countries/countriesApi";
import { categoriesApi } from "./api/datastore/categories/categoriesApi";
import { transactionsApi } from "./api/transactions/transactionsApi";
import { clientsApi } from "./api/clients/clientsApi";
import { dueDiligenceApi } from "./api/dueDiligence/dueDiligenceApi";

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      countriesApi.middleware,
      categoriesApi.middleware,
      transactionsApi.middleware,
      clientsApi.middleware,
      dueDiligenceApi.middleware
    ),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
