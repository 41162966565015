import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { NumberFormatValues } from "react-number-format/types/types";

interface CustomProps {
  onChange: (...event: any[]) => void;
  name: string;
  value: string;
}
export const PercentageFormatter = React.forwardRef<NumericFormatProps, CustomProps>(
  (props, ref) => {
    const { onChange, name, value, ...other } = props;
    const MAX = 100;
    const MIN = 0;

    const withValueCap = (values: NumberFormatValues) => {
      const { value } = values;

      if (value === "") {
        return true;
      }

      return parseInt(value) >= MIN && parseInt(value) <= MAX;
    };
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: name,
              value: values.value,
            },
          });
        }}
        value={value}
        type="text"
        suffix={` %`}
        allowNegative={false}
        isAllowed={withValueCap}
      />
    );
  }
);
