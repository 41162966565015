import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import { rgba } from "polished";

export const TableWrapper = styled("table")(({ theme }) => ({
  tableLayout: "fixed",
  borderCollapse: "collapse",
  minWidth: "100%",
  marginTop: "20px",

  "& caption": {
    textAlign: "left",
    fontWeight: "bold",
    padding: "0 5px 5px 0",
    fontSize: "1rem",
    color: theme.palette.info.dark,
  },
  "& th": {
    fontWeight: 600,
    width: "100%",
    maxWidth: "45%",
  },
  "&, th, td": {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  "& th, & td": {
    textAlign: "left",
    padding: "5px 10px",
    fontSize: "0.95rem",
  },
  "& td": {
    backgroundColor: rgba(theme.palette.grey[200], 0.6),
  },
  "& .no_bgColor": {
    backgroundColor: theme.palette.common.white,
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-root": {
    "&:before, :after, :hover:not(.Mui-disabled):before": {
      border: 0,
    },
  },
  "& .MuiInputBase-input": {
    padding: "3px 0",
    fontSize: "15px",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "0",
    fontSize: "15px",
  },
}));

export const TextFieldComponent = ({ val }) => {
  return (
    <StyledTextField
      name={val.name}
      size="small"
      value={val.value || ""}
      variant="standard"
      sx={{ width: "100%" }}
      inputProps={{
        autoComplete: "new-adviser",
        form: {
          autoComplete: "off",
        },
      }}
    ></StyledTextField>
  );
};

export const StyledTd = styled("td")`
  width: auto;
`;
