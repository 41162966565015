import React from "react";
import { StyledTextField } from "../../../DetailsCompanyInformationCard/styled.detailsCompanyInformationCard";
//import { CurrencyFormatter } from "../../../../formatter/CurrencyFormatter/CurrencyFormatter";

export const TextFieldComponent = ({ val, handleChange }) => {
  const handleInputChange = (e) => {
    const { value } = e.target;
    const regex = /^[0-9+-.,]*$/;  // Regular expression for allowing + and - in Finanzinformationen table DDCommercial

    if (regex.test(value)) {
      handleChange(e, val.index);
    }
  };

  return (
    <StyledTextField
      name={val.name}
      size="small"
      value={val.value || ""}
      variant="standard"
      sx={{ width: "100%" }}
      onChange={handleInputChange}
      
      inputProps={{
        autoComplete: "new-min_cap",
        form: {
          autoComplete: "off",
        },
        inputMode: "numeric",
      }}
    />
  );
};
