import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useAdvisors = () => {
  // to get all advisors
  const getAllAdvisors = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.ADVISOR_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to get all clients of advisor
  const getAllClientsAdvisor = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to create a new client in advisor
  // data = {
  //   "customer_id": "1d98c5db-fdd5-49b0-b197-d499f67ed3be",
  //   "name": "TestClient",
  //   "address": {
  //       "line_1": "Line1",
  //       "line_2": "Line2",
  //       "city": "Musterstadt",
  //       "postal": "12345",
  //       "country": "DEU"
  //   }
  // }
  const createClientAdvisor = (data) => {
    return API.post(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: data,
    });
  };

  // to update client in advisor
  const updateClientAdvisor = (clientId, data) => {
    return API.patch(
      TConfig.webapp.APP_API_ID,
      `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`,
      {
        queryStringParameters: {
          cov_id: getCovid(),
        },
        body: data,
      }
    );
  };

  // to delete client from advisor
  const deleteClientAdvisor = (clientId) => {
    return API.del(TConfig.webapp.APP_API_ID, `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  return {
    getAllAdvisors,
    getAllClientsAdvisor,
    createClientAdvisor,
    updateClientAdvisor,
    deleteClientAdvisor,
  };
};
