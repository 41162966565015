import React, { useState, useMemo, useCallback, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/de";
import i18n from "i18next";

import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import { useMediaQuery, Tooltip, IconButton } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";

import FolderCopyOutlinedIcon from "@mui/icons-material/FolderCopyOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { s3Upload, dateObjectConverter } from "../../common";
import { Alert, DialogComponent, FadeIn, LoadingIndicator, Section } from "../";

import TConfig from "../../../../../config";
import { DashbordContext } from "../../../../../views/Private/Home";
import { CatHeader, IndItems } from "./styled.fileUploadDialog";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useParams } from "react-router-dom";

const Content = ({
  handleDialogClose,
  fileToEdit,
  handlePrevNewFileUpdate,
  product,
  page,
  fileUrn,
  ddSection,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const params = useParams();

  const [file, setFile] = useState({});
  const [fileType, setFileType] = useState(undefined);
  const [date, setDate] = useState("");
  const [dateText, setDateText] = useState("");
  const [alertState, setAlertState] = useState({
    show: false,
    type: "",
    message: "",
  });

  const dashboardContext = useContext(DashbordContext);
  const transactionId = params.transactionId;
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();

  const transactionID = useMemo(() => {
    const transaction_Id =
      product === "advisor"
        ? dashboardContext?.advisor_content?.status_selected_transaction_id || transactionId
        : product === "platform"
        ? dashboardContext?.status_selected_transaction_id || transactionId
        : product === "company"
        ? transactionId
        : product === "advisor_client"
        ? dashboardContext?.client_content?.transaction_id || transactionId
        : transactionId || null;

    return transaction_Id;
  }, [dashboardContext, transactionId, product]);

  // stepsObject to assign text as subheading for Treeview from locales
  const fileTypeCategories = t("transaction_tables.deal_documents.fileCategories", {
    returnObjects: true,
  });

  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  // to get  names according to code
  // const getName = useCallback(
  //   (val) => {
  //     if (currentUser.datastore.fileTypes.length === 0) return;
  //     const cat = currentUser?.datastore?.fileTypes?.find((item) => item.value === val);
  //     return t(`transaction_tables.deal_documents.fileTypes.${cat.value}`)
  //   },
  //   [currentUser.datastore.fileTypes]
  // );
  // in order to create category and value properties for AutoComplete Component
  const categorySelectOptions = useCallback(() => {
    const items = [];
    if (page === "due_diligence") {
      return [
        {
          cat: fileTypeCategories["dd"],
          ind: t(`transaction_tables.deal_documents.fileTypes.dd_legal`),
          value: "dd_legal",
        },
      ];
    } else {
      for (const level1 of currentUser.datastore.fileTypesTree) {
        for (const level2 of level1.tree) {
          items.push({
            cat: fileTypeCategories[level1.value],
            ind: t(`transaction_tables.deal_documents.fileTypes.${level2.value}`),
            value: level2.value,
          });
        }
      }
      items.push({ cat: "", ind: "", value: "" });
    }

    return items;
  }, [currentUser.datastore.fileTypesTree, fileTypeCategories, t, page]);

  useEffect(() => {
    if (fileType === undefined && page === "due_diligence") {
      setFileType({
        cat: fileTypeCategories["dd"],
        ind: t(`transaction_tables.deal_documents.fileTypes.dd_legal`),
        value: "dd_legal",
      });
    }
  }, [page, fileType, fileTypeCategories, t, setFileType]);

  // useEffect to set fileType if the dialog was opened from edit file
  useEffect(() => {
    if (fileToEdit && !fileType) {
      // get all types
      const types = categorySelectOptions();
      // find correlating type from fileToEdit
      const type = types.find((item) => item.value === fileToEdit.file_urn);
      // set found type to fileType
      setFileType(type);
    }

    if (fileUrn) {
      const types = categorySelectOptions();
      const type = types.find((item) => item.value === fileUrn);
      setFileType(type);
    }
  }, [categorySelectOptions, file.file_urn, fileToEdit, fileType, fileUrn]);

  // returns value of last month to susa text field in file upload dialog / or if there is file to edit then the susadate of file
  useEffect(() => {
    if (fileToEdit && fileToEdit.susa_date) {
      setDate(dayjs(fileToEdit.susa_date, "MM/YYYY"));
      setDateText(fileToEdit.susa_date);
    } else {
      const dateValue = dayjs(`${dayjs().year()}-${dayjs().month()}`);
      const dateTextValue = dateObjectConverter(dayjs(`${dayjs().year()}-${dayjs().month()}`));
      setDate(dateValue);
      setDateText(dateTextValue);
    }
  }, [fileToEdit]);

  // handle change event in AutoComplete
  const handleAutoCompleteChange = (_, value) => {
    setFileType(value);
  };

  // to handle change event on document file selection
  const handledocumentFileChange = (e) => {
    // if an alert exist, first remove this alert on change
    if (alertState.show) {
      setAlertState({
        ...alertState,
        show: false,
        type: "",
        message: "",
      });
    }

    // the first/only file selected by user
    const fileObj = e.target.files.item(0);

    if (fileObj) {
      fileObj.date = dateText;
      setFile(fileObj);
    }
  };

  // to handle change event on date picker
  const handleDatePickerChange = (date) => {
    const convertedDateText = dateObjectConverter(date);
    setDateText(convertedDateText);
    setDate(date);
  };

  // to validate there is a file and file type selected
  const documentReadyToSave = useMemo(() => {
    if (fileType?.value === TConfig.file.URN.SUSA) {
      return !!(file?.name && dateText);
    }
    return !!file?.name;
  }, [dateText, file, fileType]);

  // to decide if submit button should be shown
  const showSubmitBtn = useMemo(() => {
    // if susa is choosen you need to choose file and susa date, if date is not choosen return false
    if (fileType?.value) {
      if (fileType?.value === TConfig.file.URN.SUSA) {
        return !!(file?.name && dateText);
      } else {
        return !!file?.name;
      }
    }
    return false;
  }, [dateText, file?.name, fileType?.value]);

  // to determine URN for file upload
  const FILE_URN = useMemo(() => {
    if (!fileType) return undefined;
    return fileType.value;
  }, [fileType]);

  // to determine attributes for file upload
  const FILE_ATTR = useMemo(() => {
    if (!fileType || !transactionID) return undefined;
    const file_attr = [
      {
        urn: TConfig.record.URN.TRANSACTION,
        value: transactionID,
      },
    ];
    if (fileType?.value === TConfig.file.URN.SUSA) {
      file_attr.push({
        urn: TConfig.file.ATTRIB.SUSA_DATE,
        value: dateText,
      });
    }
    if (fileToEdit) {
      file_attr.push({
        urn: TConfig.file.ATTRIB.FILE_ID,
        value: fileToEdit.file_id,
      });
    }
    if (ddSection) {
      file_attr.push({
        urn: "dd_section",
        value: ddSection,
      });
    }
    return file_attr;
  }, [fileType, transactionID, fileToEdit, dateText, ddSection]);

  // function to upload file to s3
  const handleUploadFile = async () => {
    try {
      dispatch(setCurrentUser({ loading: true }));
      if (documentReadyToSave) {
        if (documentReadyToSave) {
          const response = await s3Upload(FILE_URN, file, FILE_ATTR);
          fileToEdit
            ? handlePrevNewFileUpdate([fileToEdit, file], "update")
            : handlePrevNewFileUpdate([file, fileType, response], "upload");

          if (page !== "due_diligence") {
            setFileType({});
          }
          setFile({});
          setDate("");
          setDateText("");
          handleDialogClose();
          dispatch(setCurrentUser({ loading: false }));
        }
      }
    } catch (e) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return (
    <>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <DialogContent
        sx={{
          padding: "0 10px 10px",
          height: "100%",
          maxHeight: "55vh",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 20px",
          },
        }}
      >
        <Section title={""} headlineColor={"dark"} isDialog={true}>
          <FormControl fullWidth>
            <Autocomplete
              id="file_upload_fileTypes"
              value={
                (fileType?.value && fileType) || {
                  cat: "",
                  ind: "",
                  value: "",
                }
              }
              options={currentUser.datastore.fileTypesTree && categorySelectOptions()}
              groupBy={(option) => option.cat}
              getOptionLabel={(option) => option.ind}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    required
                    label={t("transaction_tables.deal_documents.filetypes")}
                  />
                );
              }}
              renderGroup={(params) => {
                return (
                  <li key={params.key}>
                    <CatHeader>{params.group}</CatHeader>
                    <IndItems>{params.children}</IndItems>
                  </li>
                );
              }}
              onChange={handleAutoCompleteChange}
              isOptionEqualToValue={(option, value) => option.ind === value.ind}
              // disable if we are editing file
              disabled={!!fileToEdit || !!fileUrn}
            />
          </FormControl>

          <TextField
            InputLabelProps={{ shrink: true }}
            fullWidth
            id="file_upload_document_chooser"
            label={t("transaction_tables.deal_documents.document_chooser")}
            value={file?.name || ""}
            sx={{
              "& label": { p: 0 },
            }}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <>
                  <Tooltip title={t("transaction_tables.deal_documents.")} arrow>
                    <IconButton aria-label="upload" component="label">
                      <FolderCopyOutlinedIcon fontSize="medium" color="primary" />
                      <input hidden type="file" onChange={handledocumentFileChange} />
                    </IconButton>
                  </Tooltip>
                </>
              ),
            }}
          />

          {
            // if fileType susa is choosen show date picker othewise hide it
            fileType?.value === TConfig.file.URN.SUSA && (
              <Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={i18n.language}>
                  <DatePicker
                    disableFuture
                    views={["year", "month"]}
                    openTo="month"
                    label={t("transaction_tables.deal_documents.susa_date")}
                    minDate={dayjs(`${dayjs().year() - 1}-01-01`)} // from last year, 1st of January
                    maxDate={dayjs(`${dayjs().year()}-${dayjs().month()}`)} // upto last month, including last month
                    value={date}
                    onChange={(newDate) => {
                      handleDatePickerChange(newDate);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        sx={{
                          svg: {
                            color: theme.palette.primary.main,
                          },
                        }}
                        id="your-textfield-id" // add an id to the TextField
                      />
                    )}
                    // disable if we are editing file
                    disabled={!!fileToEdit}
                  />
                </LocalizationProvider>
                <Stack sx={{ mt: 1 }}>
                  <Typography
                    variant="subtitle1"
                    color="primary"
                    sx={{
                      fontSize: "15px",
                      display: "inline-block",
                      textAlign: "justify",
                    }}
                  >
                    <Typography
                      variant="body1"
                      color="error"
                      sx={{
                        fontSize: "15px",
                        fontWeight: "bold",
                        display: "inline-block",
                        marginRight: "10px",
                      }}
                    >
                      Hinweis
                    </Typography>
                    Bitte beachten Sie .....
                  </Typography>
                </Stack>
              </Stack>
            )
          }
        </Section>
        {alertState.show && (
          <Stack sx={{ my: 2 }}>
            <FadeIn>
              <Alert type={alertState.type}>{alertState.message}</Alert>
            </FadeIn>
          </Stack>
        )}
      </DialogContent>
      <Stack
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
        spacing={2}
        sx={{
          width: "100%",
          padding: "0 10px 10px",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 24px",
          },
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          size={isMobile ? "small" : "medium"}
          sx={{
            px: { sx: 2, md: 4 },
            fontWeight: "bold",
            color: theme.palette.primary.main,
          }}
          onClick={() => {
            setFileType(undefined);
            handleDialogClose();
          }}
        >
          {t("misc.close")}
        </Button>
        {
          // to hide and show upload button according fields are filled or not

          <Button
            disabled={!showSubmitBtn}
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
            onClick={handleUploadFile}
          >
            {t("misc.upload")}
          </Button>
        }
      </Stack>
    </>
  );
};

export const FileUploadDialog = ({
  dialogOpen,
  handleDialogClose,
  fileToEdit,
  handlePrevNewFileUpdate,
  product,
  page,
  fileUrn,
  ddSection,
}) => {
  const { t } = useTranslation();
  return (
    <DialogComponent
      dialogKey={"file-upload-dialog"}
      title={t("transaction_tables.deal_documents.headline")}
      content={
        <Content
          handleDialogClose={handleDialogClose}
          fileToEdit={fileToEdit}
          handlePrevNewFileUpdate={handlePrevNewFileUpdate}
          product={product}
          page={page}
          fileUrn={fileUrn}
          ddSection={ddSection}
        />
      }
      dialogOpen={dialogOpen}
      handleDialogClose={handleDialogClose}
      maxWidth={"md"}
    />
  );
};
