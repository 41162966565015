import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import {
  useGetTransactionByIdQuery,
  useUpdateTransactionMutation,
} from "../../Store/api/transactions/transactionsApi";
import { useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import { Section } from "../Section/Section";
import Button from "@mui/material/Button";
import { StatusTableRow } from "./StatusTableRow";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { LoadingIndicator } from "../LoadingIndicator";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

const useInvestorStatus = () => {
  const [investors, setInvestors] = useState();
  const { transactionId } = useParams();
  const transactionByIdQuery = useGetTransactionByIdQuery({ transactionId });
  const [updateTransaction] = useUpdateTransactionMutation();
  const [loading, setLoading] = useState(true);
  const dispatch = useAppDispatch();

  const investorsMetadata = async () => {
    const transaction = await transactionByIdQuery.refetch();
    const investorsList = transaction.data.investors;
    const investment = transaction.data.metadata
      .filter((item) => item.scope === "investment")
      .flatMap((item) => item.data.investors);
    const investorsData = investment.map((invite) => {
      const investor = investorsList.find((item) => item.customer_id === invite.id);
      return {
        ...invite,
        ...investor,
      };
    });
    setInvestors(investorsData);
    setLoading(false);
  };

  useEffect(() => {
    investorsMetadata();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateInvestor = (fieldName, newValue, rowIndex) => {
    setInvestors((prev) => {
      const updatedData = [...prev];
      updatedData[rowIndex] = {
        ...updatedData[rowIndex],
        [fieldName]: newValue,
      };
      return updatedData;
    });
  };

  const saveInvestors = async () => {
    dispatch(setCurrentUser({ loading: true }));
    const objToUpdate = {
      metadata: [
        {
          scope: "investment",
          data: {
            investors: investors,
          },
        },
      ],
    };

    await updateTransaction({ transactionId: transactionId, body: objToUpdate });
    dispatch(setCurrentUser({ loading: false }));
  };

  return { investors, updateInvestor, saveInvestors, loading };
};

export const InvestorStatusTable = () => {
  const { investors, updateInvestor, saveInvestors, loading } = useInvestorStatus();
  const { t } = useTranslation(["platform/common"]);

  return (
    <Section>
      <Typography
        variant="h5"
        sx={{
          marginLeft: "25px",
          color: "white",
          fontWeight: "bold",
        }}
      >
        Status
      </Typography>

      <Card
        style={{
          maxWidth: "95%",
          marginLeft: "25px",
          height: "fit-content",
          paddingRight: "20px",
        }}
      >
        {loading && <LoadingIndicator type={"COMPONENT"} />}

        <TableContainer
          component={Paper}
          style={{
            marginRight: "10px",
            marginLeft: "10px",
            marginTop: "20px",
          }}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.name")}
                </TableCell>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.email")}
                </TableCell>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.invited")}
                </TableCell>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.viewed")}
                </TableCell>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.priority")}
                </TableCell>
                <TableCell>
                  {t(
                    "platform/common:content.investor_matching.investor_status.interested_in_teaser"
                  )}
                </TableCell>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.nda_sent")}
                </TableCell>
                <TableCell>
                  {t("platform/common:content.investor_matching.investor_status.nda_signed")}
                </TableCell>
                <TableCell>
                  {t(
                    "platform/common:content.investor_matching.investor_status.investmentdossier_sent"
                  )}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {investors?.map((row, index) => {
                return (
                  <StatusTableRow
                    row={row}
                    index={index}
                    investors={investors}
                    updateInvestor={updateInvestor}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {!loading && (
          <Button
            color="secondary"
            variant="contained"
            sx={{
              width: "fit-content",
              alignSelf: "left",
              marginTop: "30px",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
            type="submit"
            onClick={saveInvestors}
          >
            {t("platform/common:content.investor_matching.investor_status.save")}
          </Button>
        )}
      </Card>
    </Section>
  );
};
