import React from "react";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (...event: any[]) => void;
  currency: string;
  name: string;
  value: string;
  allowNegative: boolean;
  prefix: string;
}
export const CurrencyFormatter = React.forwardRef<NumericFormatProps, CustomProps>((props, ref) => {
  const { onChange, currency, name, value, prefix, ...other } = props;

  let negativeValue = false;

  if (
    name === "ebitda_prev_year" ||
    name === "ebitda_last_year" ||
    name === "ebit_prev_year" ||
    name === "ebit_last_year"
  ) {
    negativeValue = true;
  }

  return (
    <NumericFormat
      name={name}
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: name,
            value: values.value.split(".").join(""),
          },
        });
      }}
      value={value}
      type="text"
      decimalSeparator=","
      thousandSeparator="."
      suffix={` ${currency}`}
      prefix={`${prefix ? prefix : ""} `}
      allowNegative={negativeValue ? true : false}
    />
  );
});
