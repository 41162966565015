import { BaseQueryFn } from "@reduxjs/toolkit/dist/query/react";
import { HttpMethod } from "./interfaces";
import { API } from "aws-amplify";
import TConfig from "../../../../../config";

export const awsBaseQuery =
  (): BaseQueryFn<{
    path: string;
    method: HttpMethod;
    data?: {
      body?: { [key: string]: any };
      queryStringParameters?: { [key: string]: string };
    };
  }> =>
  async ({ path, method, data }) => {
    const initParams: { [key: string]: any } = { queryStringParameters: {} };

    if (data?.body && Object.keys(data.body).length > 0) {
      initParams.body = data.body;
    }

    initParams.queryStringParameters = {
      ...data?.queryStringParameters,
    };

    try {
      const response = await API[method](TConfig.webapp.APP_API_ID, path, initParams);

      return { data: response };
    } catch (error: unknown) {
      const errorResponse = error as any;
      return { error: { status: errorResponse?.status, data: errorResponse?.response?.data } };
    }
  };
