import React from "react";
import { rgba } from "polished";

import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";

export const ContainerWrapper = ({ children, width = "md" }) => {
  const theme = useTheme();

  return (
    <Container component="main" disableGutters maxWidth={width} sx={{ borderRadius: "1rem" }}>
      <Box
        sx={{
          boxShadow: `0.2rem 0.4rem 1rem ${rgba(theme.palette.greys.fourth.main, 0.4)}`,
          borderRadius: "1rem",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        {children}
      </Box>
    </Container>
  );
};
