import { ToggleButtonGroup, ToggleButton } from "@mui/material";
import { useTranslation } from "react-i18next";
const InvestorTableToggles = (props) => {
  const { t } = useTranslation(["platform/common"]);

  const { options, handleOptions } = props;

  return (
    <ToggleButtonGroup onChange={(e) => handleOptions(e)} aria-label="text formatting">
      {Object.keys(options).map((key) => {
        return (
          <ToggleButton
            key={key}
            value={key}
            aria-label={key}
            selected={options[key]}
            sx={{ minWidth: "80px", fontSize: "12px" }}
          >
            {t(`platform/common:content.investor_matching.columns.${key}`)}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

export default InvestorTableToggles;
