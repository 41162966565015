import { styled } from "@mui/material/styles";

export const Logo = ({ isDark = true }) => {
  const StyledLogo = styled("div")(({ theme }) => {
    return {
      width: "170px",
      height: "50px",

      [theme.breakpoints.up("md")]: {
        width: "240px",
        height: "70px",
      },
    };
  });

  const StyledResponsiveImage = styled("img")(() => {
    return {
      display: "block",
      width: "100%",
      height: "100%",
      objectFit: "cover",
    };
  });

  return (
    <StyledLogo>
      <StyledResponsiveImage
        src={isDark ? "/assets/logo-darkblue.svg" : "/assets/logo-white.png"}
        alt="logo"
      />
    </StyledLogo>
  );
};
