import React from "react";
import TextField from "@mui/material/TextField";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { useTranslation } from "react-i18next";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { CustomTooltip } from "../../..";
import { useAppSelector } from "../../../../Store/hooks/useAppSelector";

export const CompetitorInput = ({ handleDataChange, intialData, handleAddUser, NotEditable }) => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const { t } = useTranslation(["company/due_dilligence"]);
  const theme = useTheme();
  return (
    <Stack
      direction={{ xs: "column", md: "row" }}
      justifyContent="space-between"
      alignItems={{ xs: "stretch", md: "flex-start" }}
      spacing={{ xs: 2, md: 1 }}
      sx={{}}
    >
      <TextField
        label={t(`company/due_dilligence:dd_commercial.company_name`)}
        name="company_name"
        id="competitor-company-name"
        value={intialData.company_name}
        size="small"
        onChange={handleDataChange}
        disabled={NotEditable}
        inputProps={{
          autoComplete: "new-company_name",
          form: {
            autoComplete: "off",
          },
        }}
        sx={{ minWidth: "15%", width: "100%" }}
      />

      <TextField
        label={t(`company/due_dilligence:dd_commercial.country`)}
        name="country"
        id="competitor-country"
        value={intialData.country}
        size="small"
        onChange={handleDataChange}
        disabled={NotEditable}
        inputProps={{
          autoComplete: "new-country",
          form: {
            autoComplete: "off",
          },
        }}
        sx={{ minWidth: "15%", width: "100%" }}
      />

      <TextField
        label={t(`company/due_dilligence:dd_commercial.website`)}
        name="website"
        id="competitor-website"
        value={intialData.website}
        size="small"
        onChange={handleDataChange}
        disabled={NotEditable}
        inputProps={{
          autoComplete: "new-Website",
          form: {
            autoComplete: "off",
          },
        }}
        sx={{ minWidth: "15%", width: "100%" }}
      />
      <TextField
        label={t(`company/due_dilligence:dd_commercial.comment`)}
        name="comment"
        id="competitor-comment"
        value={intialData.comment}
        size="small"
        onChange={handleDataChange}
        disabled={NotEditable}
        inputProps={{
          autoComplete: "new-Website",
          form: {
            autoComplete: "off",
          },
        }}
        sx={{ minWidth: "15%", width: "100%" }}
      />
      <FormControl fullWidth size="small">
        <InputLabel id="competitor-type-select">Typ</InputLabel>
        <Select
          labelId="competitor-type-select"
          name="type"
          id="competitor-type-select"
          value={intialData.type}
          onChange={handleDataChange}
          disabled={NotEditable}
          autoComplete="new-company_name"
          label={t(`company/due_dilligence:dd_commercial.type`)}
        >
          {currentUser.datastore.competitorType &&
            currentUser.datastore.competitorType.map((item) => (
              <MenuItem key={item.value} value={item.summary}>
                {item.summary}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
      {!NotEditable && (
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey[400]}`,
            height: "40px",
            width: "40px",
            borderRadius: "4px",
            "&:hover": {
              borderColor: theme.palette.primary.main,
            },
          }}
        >
          <CustomTooltip title={t("misc.add")} placement="top" arrow>
            <IconButton color="primary" size="small" onClick={() => handleAddUser()}>
              <PersonAddOutlinedIcon />
            </IconButton>
          </CustomTooltip>
        </Box>
      )}
    </Stack>
  );
};
