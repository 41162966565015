import React, { useEffect, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { rgba } from "polished";
import { isEqual, cloneDeep } from "lodash";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import CloudUploadSharpIcon from "@mui/icons-material/CloudUploadSharp";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";

import { CustomTooltip, SecondaryDialog } from "../";
import { useTransactions } from "../../hooks";

import TConfig from "../../../../../config";
import { FileUploadDialog } from "../FileUploadDialog";
import { DocumentsListTable } from "./DocumentsListTable";
import { Wrapper } from "./styled.tableComp";
import { DefaultColumnFilter, SelectColumnFilter } from "./helper";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { FormControlLabel } from "@mui/material";
import { useAppSelector } from "../../Store/hooks/useAppSelector";

export const DocumentsTable = React.memo(
  ({
    originalFiles = [],
    product = "platform",
    page,
    hideButtons,
    name,
    keyName,
    handleDDUpdate,
    setFilesObj,
  }) => {
    const { t } = useTranslation(["platform/common"]);
    const theme = useTheme();

    const fileTypes = useAppSelector(
      ({
        currentUser: {
          datastore: { fileTypes },
        },
      }) => fileTypes
    );

    const { getFileById, deleteFileById } = useTransactions();

    const [filesData, setFilesData] = useState(undefined);
    const [fileToEdit, setFileToEdit] = useState(undefined);
    const [fileId, setFileId] = useState(undefined);
    const [fileName, setFileName] = useState(undefined);
    const [prevNewFileUpdate, setPrevNewFileUpdate] = useState({});
    const [disableButton, setDisableButton] = useState(false);
    const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
    const [secondaryDialogOpen, setSecondaryDialogOpen] = useState(false);

    const dispatch = useAppDispatch();

    const files = useMemo(() => cloneDeep(originalFiles), [originalFiles]);
    //const files = cloneDeep(originalFiles)

    useEffect(() => {
      if (filesData !== undefined) {
        // eslint-disable-next-line array-callback-return
        filesData.map((file) => {
          if (file.file_id === fileId) setFileName(file.file_name);
        });
      }
    }, [fileId, filesData]);

    useEffect(() => {
      if (prevNewFileUpdate.event === "update") {
        setFilesData((prevState) => {
          return prevState.map((item) => {
            if (item?.file_id === prevNewFileUpdate.data[0].file_id) {
              return {
                ...prevNewFileUpdate.data[0],
                file_name: prevNewFileUpdate.data[1].name,
                uploaded_date: dayjs(parseInt(new Date().getTime())).format("DD/MM/YYYY HH:mm"),
                file_format: TConfig.record.FILES.MIME_TYPES[prevNewFileUpdate.data[1].type],
              };
            } else {
              return item;
            }
          });
        });
        setPrevNewFileUpdate({});
      } else if (prevNewFileUpdate.event === "upload") {
        setFilesData((prevState) => {
          return [
            ...prevState,
            {
              file_id: prevNewFileUpdate.data[2],
              file_type: prevNewFileUpdate.data[1].ind,
              file_name: prevNewFileUpdate.data[0].name,
              uploaded_date: dayjs(parseInt(new Date().getTime())).format("DD/MM/YYYY HH:mm"),
              file_format: TConfig.record.FILES.MIME_TYPES[prevNewFileUpdate.data[0].type],
            },
          ];
        });
        setPrevNewFileUpdate({});
      }
    }, [prevNewFileUpdate.data, prevNewFileUpdate.event]);

    // parse date and filetype translations
    useEffect(() => {
      if (files.length && !filesData) {
        const parsedFilesData = files.map((file) => {
          // get filetype name from data store
          const fileType = fileTypes.find((ft) => ft.value === file.file_urn);
          file.file_type = t(`transaction_tables.deal_documents.fileTypes.${fileType.value}`) || "";
          // parse uploaded date and time
          const uploadedDate = dayjs(parseInt(file.uploaded_at)).format("DD/MM/YYYY HH:mm");
          file.uploaded_date = uploadedDate;
          return file;
        });

        setFilesData(parsedFilesData);
      }

      // to stop loading effect in DocumentListTable
      if (files.length === 0 && !filesData) {
        setFilesData([]);
      }
    }, [fileTypes, files, filesData, t]);

    useEffect(() => {
      if (page === "due_diligence") {
        if (files.length && filesData.length === 0) {
          const parsedFilesData = files.map((file) => {
            // get filetype name from data store
            const fileType = fileTypes.find((ft) => ft.value === file.file_urn);
            file.file_type =
              t(`transaction_tables.deal_documents.fileTypes.${fileType.value}`) || "";

            // parse uploaded date and time
            const uploadedDate = dayjs(parseInt(file.uploaded_at)).format("DD/MM/YYYY HH:mm");

            file.uploaded_date = uploadedDate;

            return file;
          });

          setFilesData(parsedFilesData);
        }
      }
    }, [fileTypes, files, filesData, page, t]);

    // define which keys from data object to use as columns (for parsed data use parse keys)
    const tableColumns = useMemo(
      () => [
        {
          Header: t("transaction_tables.columns.file_name"),
          accessor: "file_name",
          Filter: ({ column }) => (
            <DefaultColumnFilter
              column={column}
              placeholder={t("de/common:document_table.data_search")}
            />
          ),
        },
        {
          Header: t("transaction_tables.columns.file_urn"),
          accessor: "file_type",
          Filter: SelectColumnFilter,
          filter: "includes",
        },
        {
          Header: t("transaction_tables.columns.file_format"),
          accessor: "file_format",
          Filter: SelectColumnFilter,
          filter: "includes",
        },
        {
          Header: t("transaction_tables.columns.uploaded_at"),
          accessor: "uploaded_date",
          Filter: ({ column }) => (
            <DefaultColumnFilter
              column={column}
              placeholder={t("de/common:document_table.format")}
            />
          ),
        },
      ],
      [t]
    );

    const handleFileUploadDialogClose = () => {
      setFileToEdit(undefined);
      setIsFileUploadDialogOpen(false);
    };

    const handleFileUploadDialog = () => {
      setIsFileUploadDialogOpen(true);
    };

    // to show secondary delete dialog
    const handleSecondaryDeleteDialogOpen = (fileID) => {
      setFileId(fileID);
      setSecondaryDialogOpen(true);
    };

    // to close secondary delete dialog
    const handleSecondaryDeleteDialogClose = () => {
      setFileId(undefined);
      setSecondaryDialogOpen(false);
    };

    // function for editing files
    const handleUpdateFile = (file) => {
      // set filetoEdit with file metadata to be sent as props to upload dialog
      setFileToEdit(file);
      // open upload dialog
      setIsFileUploadDialogOpen(true);
    };

    // function for downloading files
    const handleDownloadFile = (fileID) => {
      dispatch(setCurrentUser({ loading: true }));
      // disable all button while backend call is working
      setDisableButton(true);
      // backend call to get file download link by fileId
      getFileById(fileID)
        .then((response) => {
          // check if download url is present in response
          if (response && response.file && response.file.download_url)
            return response.file.download_url;
        })
        .then((downloadUrl) => {
          // create anchor element & add download url as href
          const downloadLink = document.createElement("a");
          downloadLink.href = downloadUrl;
          // click on it
          downloadLink.download = "file";
          downloadLink.click();
          // reenable buttons
          setDisableButton(false);
          dispatch(setCurrentUser({ loading: false }));
        })
        .catch(() => {
          dispatch(setCurrentUser({ loading: false }));
          setDisableButton(false);
        });
    };

    // function to delete file by id
    const handleDeleteFile = (fileID) => {
      dispatch(setCurrentUser({ loading: true }));
      handleSecondaryDeleteDialogClose();
      // disable all button while backend call is working
      setDisableButton(true);
      // backend call delete file by fileId
      deleteFileById(fileID)
        .then(() => {
          // check if file is deleted
          const filteredFilesData = filesData.filter((file) => file.file_id !== fileID);

          // set new state for filesData
          setFilesData(filteredFilesData);
          if (page === "due_diligence") {
            handleDDUpdate(name, keyName, "files", filteredFilesData);
            setFilesObj((prev) => {
              return {
                ...prev,
                [keyName]: filteredFilesData,
              };
            });
          }

          // reenable buttons
          setDisableButton(false);
          dispatch(setCurrentUser({ loading: false }));
        })
        .catch(() => {
          dispatch(setCurrentUser({ loading: false }));
          setDisableButton(false);
        });
    };

    // to handle changes in filesData state with the the help of UseEffect above, after updating the file with a new one
    const handlePrevNewFileUpdate = (array, event) => {
      if (page === "due_diligence") {
        handleDDUpdate(name, keyName, "files", array[2]);
      }
      setPrevNewFileUpdate({ data: array, event: event });
    };

    const handleFileView = (fileId, transactionId) => {
      window.open(`/${transactionId}/documentViewer/${fileId}`, "_blank");
    };

    useEffect(() => {
      if (!filesData || !keyName) return;
      setFilesObj((prev) => {
        return {
          ...prev,
          [keyName]: filesData,
        };
      });
    }, [filesData, keyName, setFilesObj]);

    return (
      <>
        <Wrapper>
          <Grid container justifyContent={page === "due_diligence" ? "flex-start" : "flex-end"}>
            <Box sx={{ width: "20" }} className="upload-button">
              {page === "due_diligence" ? (
                <FormControlLabel
                  control={
                    <IconButton aria-label="upload" component="label">
                      <CloudUploadSharpIcon fontSize="medium" backgroundColor="#004e92" />
                    </IconButton>
                  }
                  onClick={handleFileUploadDialog}
                  label={
                    <Typography color="#004e92" className="textLabel" fontWeight={"bold"}>
                      {t("transaction_tables.deal_documents.document_chooser")}
                    </Typography>
                  }
                  labelPlacement="start"
                  sx={{
                    width: "fit-content",
                    marginLeft: "0px",
                    marginBottom: `${filesData && filesData.length > 0 ? "15px" : "0px"}`,
                  }}
                />
              ) : (
                <CustomTooltip
                  title={t("transaction_tables.deal_documents.upload_new_file")}
                  placement="top"
                  arrow
                >
                  <IconButton
                    aria-label="upload"
                    component="label"
                    sx={{
                      color: rgba(theme.palette.primary.main, 0.7),
                      padding: 1,
                      marginTop: "20px",
                    }}
                    size="small"
                    onClick={handleFileUploadDialog}
                    label={t("transaction_tables.deal_documents.document_chooser")}
                  >
                    <CloudUploadSharpIcon fontSize="medium" />
                  </IconButton>
                </CustomTooltip>
              )}
            </Box>
          </Grid>

          {page !== "due_diligence" && (
            <DocumentsListTable
              columns={tableColumns}
              data={filesData}
              handleUpdateFile={handleUpdateFile}
              handleDownloadFile={handleDownloadFile}
              handleDeleteFile={handleSecondaryDeleteDialogOpen}
              disableButton={disableButton}
              hideButtons={hideButtons}
            />
          )}
          {page === "due_diligence" && filesData && filesData.length ? (
            <DocumentsListTable
              columns={tableColumns}
              data={filesData}
              handleUpdateFile={handleUpdateFile}
              handleDownloadFile={handleDownloadFile}
              handleDeleteFile={handleSecondaryDeleteDialogOpen}
              disableButton={disableButton}
              hideButtons={hideButtons}
              dd={true}
              handleFileView={handleFileView}
            />
          ) : (
            ""
          )}

          {/* Secondary Dialog for customer email change confirmation */}
          <SecondaryDialog
            type="warning"
            dialogTitle={t("platform/common:transaction_tables.deal_documents.delete_file")}
            contentText={
              <Trans
                i18nKey="transaction_tables.deal_documents.delete_file_dialog_content_text"
                values={{ fileName }}
              />
            }
            secondaryDialogOpen={secondaryDialogOpen}
            secondaryDialogClose={handleSecondaryDeleteDialogClose}
            eventHandler={() => handleDeleteFile(fileId)}
            actionButtonText={t("platform/common:misc.delete")}
          />

          {/* Dialog for User Profile */}
          <FileUploadDialog
            dialogOpen={isFileUploadDialogOpen}
            handleDialogClose={handleFileUploadDialogClose}
            fileToEdit={fileToEdit}
            handlePrevNewFileUpdate={handlePrevNewFileUpdate}
            product={product}
            page={page}
            ddSection={keyName}
          />
        </Wrapper>
      </>
    );
  },
  isEqual
);
