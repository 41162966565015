import { styled } from "@mui/material/styles";

export const TableWrapper = styled("table")(({ theme }) => ({
  borderCollapse: "collapse",
  width: "100%",
  marginTop: "10px",

  "& caption": {
    textAlign: "left",
    fontWeight: "bold",
    padding: "0 5px 5px 0",
    fontSize: "1rem",
    color: theme.palette.info.dark,
  },
  "& tr": {
    width: "100%",
  },
  "& th": {
    fontWeight: 600,
    width: "100%",
    maxWidth: "45%",
  },
  "&, th, td": {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  "& th, & td": {
    textAlign: "left",
    padding: "5px 10px",
    fontSize: "0.95rem",
  },
}));
