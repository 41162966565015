import { styled } from "@mui/material/styles";
import { rgba } from "polished";

export const Wrapper = styled("div")(({ theme }) => {
  return {
    cursor: "pointer",
    gridArea: "sidebarCommon",
    width: "60px",
    overflow: "hidden",
    padding: "40px 10px",
    position: "fixed",
    top: "90px",
    left: 0,
    bottom: 0,

    [theme.breakpoints.up("md")]: {
      width: "250px",
      padding: "40px 10px 0 20px",
      top: "110px",
    },

    "& .completed": {
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "8px",
      [theme.breakpoints.up("md")]: {
        padding: "8px 15px",
      },
      color: theme.palette.primary.main,
      cursor: "pointer",
      marginBottom: "15px",
      borderRadius: "5px",
      backgroundColor: theme.palette.grey[50],
      transition: "transform 0.3s",
      "&:hover": {
        transform: "translateX(3px)",
      },
      "& .textLabel": {
        display: "none",
        [theme.breakpoints.up("md")]: {
          display: "inline-block",
          width: "100%",
          fontWeight: "bold",
          marginLeft: "20px",
        },
      },
      "&.hide": {
        pointerEvents: "none",
        backgroundColor: rgba(theme.palette.grey[400], 0.5),
        cursor: "initial",
        "&:hover": {
          transform: "none",
        },
      },
      "&.addBg": {
        backgroundColor: theme.palette.secondary.main,
      },
    },

    "& .disabled": {
      color: theme.palette.grey[400],
      borderRadius: "5px",
      backgroundColor: rgba(theme.palette.grey[400], 0.5),
      cursor: "pointer",
      "&:hover": {
        pointerEvents: "none",
        cursor: "none",
      },
    },
  };
});

export const SubMenuDetails = styled("div")(({ theme }) => {
  return {
    width: "230px",
    overflow: "hidden",
    padding: "0px 10px 0 20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",

    "& .subMenuItem": {
      width: "200px",
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "8px",
      backgroundColor: theme.palette.grey[300],
      borderRadius: "5px",
      marginBottom: "10px",
      fontSize: "13px",
      fontWeight: "bold",
      transition: "transform 0.3s",
      "&:hover": {
        transform: "translateX(3px)",
        cursor: "pointer",
      },

      "&.noBg": {
        backgroundColor: theme.palette.common.white,
      },
    },
  };
});

export const SubMenuFinancial = styled("div")(({ theme }) => {
  return {
    width: "230px",
    overflow: "hidden",
    padding: "0px 2.5px 0 55px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-end",

    "& .subMenuItem": {
      width: "200px",
      display: "inline-block",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      padding: "8px",
      backgroundColor: theme.palette.grey[300],
      borderRadius: "5px",
      marginBottom: "10px",
      fontSize: "13px",
      fontWeight: "bold",
      transition: "transform 0.3s",
      "&:hover": {
        transform: "translateX(3px)",
        cursor: "pointer",
      },

      "&.noBg": {
        backgroundColor: theme.palette.common.white,
      },
    },
  };
});
