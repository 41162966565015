import React, { forwardRef } from "react";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { useTheme } from "@mui/material/styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export const DialogComponent = ({
  dialogKey,
  title,
  content,
  dialogOpen,
  handleDialogClose,
  maxWidth = "sm",
}) => {
  const theme = useTheme();

  return (
    <Dialog
      open={dialogOpen}
      TransitionComponent={Transition}
      keepMounted
      maxWidth={maxWidth}
      fullWidth
      onClose={handleDialogClose}
      aria-describedby={dialogKey}
      sx={{
        marginTop: "5rem",
        "& .MuiPaper-root": {
          width: maxWidth,
          borderRadius: "10px",
        },
      }}
    >
      <DialogTitle
        align="center"
        color="primary"
        sx={{
          padding: "10px",
          fontSize: "22px !important",
          [theme.breakpoints.up("md")]: {
            padding: "24px 24px 10px",
          },
          fontWeight: "bold",
        }}
      >
        {title}
      </DialogTitle>
      {content}
    </Dialog>
  );
};
