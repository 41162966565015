import { API } from "aws-amplify";
import { getCovid } from "../common";

import TConfig from "../../../../config";

export const useAccounts = () => {
  // to get all accounts
  const getAccount = () => {
    return API.get(TConfig.webapp.APP_API_ID, `${TConfig.webapp.ACCOUNT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  // to update client
  const updateAccount = (data) => {
    return API.patch(TConfig.webapp.APP_API_ID, `${TConfig.webapp.ACCOUNT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
      body: data,
    });
  };

  // to delete account
  const deleteAccount = () => {
    return API.del(TConfig.webapp.APP_API_ID, `${TConfig.webapp.ACCOUNT_API_ENDPOINT}`, {
      queryStringParameters: {
        cov_id: getCovid(),
      },
    });
  };

  return {
    getAccount,
    updateAccount,
    deleteAccount,
  };
};
