/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef } from "react";
import { Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Card, Section } from "../";
import { DDForm } from "./components/DDDropdown";

export const DueDiligence = () => {
  const theme = useTheme();
  const ContentRef = useRef<HTMLDivElement | null>(null);

  return (
    <Box sx={{ width: "100%", maxWidth: theme.breakpoints.values.xl, margin: "0 auto" }} ref={ContentRef}>
      {/* @ts-ignore */}
      <Section title={"Due Diligence"}>
        <Card>
          <DDForm />
        </Card>
      </Section>
    </Box>
  );
};
