import React from "react";
import { rgba } from "polished";

import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";

export const Card = ({ children, style = [], sx = {} }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        boxShadow: `0.1rem 0.1rem 0.6rem ${rgba(theme.palette.greys.fourth.main, 0.4)}`,
        backgroundColor: "#fff",
        borderRadius: "10px",
        padding: sx.padding ? sx.padding : "25px 20px",
        [theme.breakpoints.up("md")]: {
          padding: sx.padding ? sx.padding : "30px 20px",
        },
        width: "100%",
        ...style,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
