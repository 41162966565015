/**
 *
 * @param date  date object from dayjs library
 * @returns date as string, like "03/2022"
 */

export const dateObjectConverter = (date) => {
  const month = String(+date.$M + 1).length === 1 ? `0${+date.$M + 1}` : `${Number(date.$M) + 1}`;
  const year = date.$y;
  const dateText = `${month}/${year}`;

  return dateText;
};
