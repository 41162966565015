export interface CategoryOption {
  label: string;
  value: string;
  cat?: string;
}

export enum CategoryType {
  CATEGORY = "category",
  SUB_CATEGORY = "subCategory",
}
