import React, { useMemo } from "react";
import { useTable, useFilters, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { Table } from "@mui/material";
import { Wrapper } from "./styled.tableComp";

import { LoadingIndicator } from "../";
import useInvestorTable from "./useInvestorTable";
import { fuzzyTextFilterFn } from "./helper";
import InvestorTableHead from "./InvestorTableHead";
import InvestorTablePagination from "./InvestorTablePagination";
import InvestorTableBody from "./InvestorTableBody";
import InvestorTableToggles from "./InvestorTableToggles";
const InvestorTable = ({
  investors,
  loading = false,
  expandCategories,
  setExpandCategories,
  disabled,
  renderActions,
}) => {
  const [options, handleOptions, transactionData, columns, defaultColumn] =
    useInvestorTable(investors);

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows,
    page, // Instead of using 'rows', we'll use page, which has only the rows for the active page
    prepareRow,
    // below new props related to 'usePagination' hook
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter },
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data: transactionData,
      initialState: { pageIndex: 0, pageSize: 10 },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!  We need to place useFilters before useSortBy.
    useGlobalFilter, // useGlobalFilter!
    useSortBy,
    usePagination
  );

  // supervisor, advisor select element change event handler

  const handleExpanded = (id) => {
    if (expandCategories === id) {
      setExpandCategories("");
    } else {
      setExpandCategories(id);
    }
  };

  return (
    <Wrapper
      style={{
        padding: "20px",
        fontSize: "12px",
      }}
    >
      {loading && <LoadingIndicator type={"COMPONENT"} />}

      {!loading && (
        <>
          <InvestorTableToggles options={options} handleOptions={handleOptions} />
          <Table checkboxSelection {...getTableProps()} size="small">
            {headerGroups && (
              <InvestorTableHead
                headerGroups={headerGroups}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                preGlobalFilteredRows={preGlobalFilteredRows}
                visibleColumns={visibleColumns}
              />
            )}
            {page && (
              <InvestorTableBody
                renderActions={renderActions}
                disabled={disabled}
                page={page}
                options={options}
                prepareRow={prepareRow}
                getTableBodyProps={getTableBodyProps}
                expandCategories={expandCategories}
                handleExpanded={handleExpanded}
              />
            )}
          </Table>
          {/* 
        Pagination can be built however you'd like. 
        This is just a very basic UI implementation:
      */}
          <InvestorTablePagination
            gotoPage={gotoPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            previousPage={previousPage}
            nextPage={nextPage}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageOptions={pageOptions}
          />
        </>
      )}
    </Wrapper>
  );
};

export default InvestorTable;
