import { createApi } from "@reduxjs/toolkit/query/react";
import { awsBaseQuery } from "../../config/awsBaseQuery";
import { HttpMethod } from "../../config/interfaces";
import { Client, ClientResponse } from "./interfaces";
import TConfig from "../../../../../../config";

export const clientsApi = createApi({
  reducerPath: "clients",
  baseQuery: awsBaseQuery(),
  tagTypes: ["Client"],
  endpoints: (builder) => ({
    getClientById: builder.query<Client, { clientId: string }>({
      query: ({ clientId }) => ({
        path: `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`,
        method: HttpMethod.GET,
      }),
      transformResponse: (response: ClientResponse): Client => response.client,
      providesTags: ["Client"],
    }),
    updateClient: builder.mutation<Client, { clientId: string; body: Partial<Client> }>({
      query: ({ clientId, body }) => ({
        path: `${TConfig.webapp.CLIENT_API_ENDPOINT}/${clientId}`,
        method: HttpMethod.PATCH,
        data: {
          body,
        },
      }),
      invalidatesTags: ["Client"],
    }),
  }),
});

export const { useGetClientByIdQuery, useUpdateClientMutation } = clientsApi;
