import { useDispatch, useSelector } from "react-redux";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";
import { useParams } from "react-router-dom";
import { useInvestmentTeaser } from "../../hooks/useInvestmentTeaser";
import { loadLanguage/* , setLanguage */ } from "../../Store/languageChange/languageSlice";
import { useEffect } from "react";
import { getExistingInvite } from "../../Store/invites/inviteState";

// Extracted function to find existing invite
export const findExistingInvite = async (transactionId, investorId, getInvite) => {
  const inviteList = await getInvite(transactionId);
  return inviteList.invites.find((invite) => invite.customer_id === investorId);
};

const useInvite = ({ setCopText, investor, copText }) => {
  const { getInvite, addInvite, updateInvite } = useInvestmentTeaser();
  const { transactionId } = useParams();
  const dispatch = useDispatch();

  const locale = useSelector((state) => state.language[transactionId]); // Get the locale from the store

  useEffect(() => {
    if (transactionId) {
      dispatch(loadLanguage({ transactionId }));
    }
  }, [dispatch, transactionId]);

  const InviteInvestor = async () => {
    try {
      if (copText) return;
      dispatch(setCurrentUser({ loading: true }));

      const url = new URL(window.location.origin);
      url.pathname += "investment-teaser";
      const newURL = url.origin.replace("app.advisor", "app.platform") + url.pathname;

      // Get the existing invite based on the locale
      let existingInvite = getExistingInvite(locale);
      if (!existingInvite) {
        existingInvite = await findExistingInvite(transactionId, investor.id, getInvite, locale);
      }

      if (existingInvite && existingInvite.locale === locale) {
        setCopText(existingInvite.short_url);
        dispatch(setCurrentUser({ loading: false }));

        // Update the existing invite with the new data
       /*  const updatedInvite = await updateInvite(
          transactionId,
          existingInvite.invite_id,
          investor.id,
          newURL,
          locale
        ); */
        return;
      }

      // Pass the locale to addInvite
      const newInvite = await addInvite(transactionId, investor.id, newURL, locale);
      setCopText(newInvite.invite.short_url);
      dispatch(setCurrentUser({ loading: false }));
    } catch (error) {
      // console.error("Error in InviteInvestor:", error);
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  return { InviteInvestor };
};

export default useInvite;
