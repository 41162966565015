import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import { useAppSelector } from "../../Store/hooks/useAppSelector";

export const StatusTableRow = ({ row, index, investors, updateInvestor }) => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);

  const invitedAt = new Date(row?.invited_at);
  let formattedDate;

  if (!isNaN(invitedAt.getTime())) {
    const options = { year: "numeric", month: "numeric", day: "numeric" };
    formattedDate = invitedAt.toLocaleDateString("en-US", options);
  } else {
    formattedDate = "-";
  }

  return (
    <TableRow key={index} sx={{ backgroundColor: index % 2 !== 0 ? "white" : "#f5f5f5" }}>
      <TableCell component="th" scope="row">
        {row.name}
      </TableCell>
      <TableCell style={{ width: "10%" }}>{row.email}</TableCell>
      <TableCell align="center">{formattedDate}</TableCell>
      <TableCell align="center">{row.count}</TableCell>
      <TableCell>
        <FormControl fullWidth size="small">
          <Select
            value={investors[index].priority}
            onChange={(newValue) => updateInvestor("priority", newValue.target.value, index)}
            autoComplete="new-company_name"
          >
            {currentUser.datastore.priority &&
              currentUser.datastore.priority.map((item) => (
                <MenuItem key={item.value} value={item.summary}>
                  {item.summary}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={investors[index].interestedInTeaser ? investors[index].interestedInTeaser : null}
            onChange={(newValue) => updateInvestor("interestedInTeaser", newValue, index)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={investors[index].sentNDA ? investors[index].sentNDA : null}
            onChange={(newValue) => updateInvestor("sentNDA", newValue, index)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={investors[index].signedNDA ? investors[index].signedNDA : null}
            onChange={(newValue) => updateInvestor("signedNDA", newValue, index)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
      <TableCell>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={
              investors[index].investmentDossierSent ? investors[index].investmentDossierSent : null
            }
            onChange={(newValue) => updateInvestor("investmentDossierSent", newValue, index)}
            renderInput={(params) => <TextField size="small" {...params} />}
          />
        </LocalizationProvider>
      </TableCell>
    </TableRow>
  );
};
