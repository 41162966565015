import { Auth } from "aws-amplify";
import { useCallback, useEffect, useState } from "react";
import { useAppActivity } from "./useAppActivity";
import { useAppSelector } from "../Store/hooks/useAppSelector";
import { useAppDispatch } from "../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../Store/currentUser/currentUserSlice";

const initialParams = {
  email: "__INITIAL__",
  password: "__INITIAL__",
};

export const useLogin = () => {
  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();
  const [params, setParams] = useState(initialParams);
  const { userLoginActivity } = useAppActivity();

  const authChangeCallback = useCallback(
    (isMounted) => {
      const { email, password } = params;

      Auth.signIn(email, password)
        .then((user) => {
          // __typename: AUTH_RESPONSES,
          const authenticated = user.challengeName !== "NEW_PASSWORD_REQUIRED";
          const forceChange = user.challengeName === "NEW_PASSWORD_REQUIRED";

          const loggedUser = {
            ...currentUser,
            username: user.username,
            email: email,
            temporaryPassword: forceChange ? password : "",
            authenticated,
            forceChange,
            errors: [],
          };

          if (user.attributes) {
            loggedUser.attributes = {
              customer_id: user.attributes["custom:customer-id"],
              user_id: user.attributes["custom:user-id"],
              name: user.attributes.name,
              sub: user.attributes.sub,
            };
          }

          setParams({
            email: "__INITIAL__",
            password: "__INITIAL__",
            loading: false,
          });

          isMounted && dispatch(setCurrentUser({ ...loggedUser, state: "UNKNOWN" }));
          return loggedUser;
        })
        .then((loggedUser) => {
          if (loggedUser.authenticated) {
            userLoginActivity();
          }
        })
        .catch((e) => {
          const err = {
            // __typename: AUTH_ERROR,
            code: e.code,
            name: e.code,
          };

          switch (e.code) {
            case "PasswordResetRequiredException":
              const userShouldReset = {
                email: email,
                temporaryPassword: password,
                authenticated: false,
                forceChange: true,
                errors: [],
              };
              setParams({
                email: "__INITIAL__",
                password: "__INITIAL__",
                loading: false,
              });
              isMounted && dispatch(setCurrentUser({ ...userShouldReset }));

              break;
            default:
              isMounted && dispatch(setCurrentUser({ errors: [err] }));
              setParams({
                email: "__INITIAL__",
                password: "__INITIAL__",
                loading: false,
              });
          }
        });
    },
    [currentUser, dispatch, params, userLoginActivity]
  );

  useEffect(() => {
    let mounted = true;

    if (params.email === "__INITIAL__" || params.loading) return;

    authChangeCallback(mounted);
    setParams({ ...params, loading: true });

    return () => {
      mounted = false;
    };
  }, [params, authChangeCallback]);

  /** * [useLogin hook returns a callback and a isReady state] **/
  return [
    ({ email, password }) => {
      if (params.email === "__INITIAL__" && params.password === "__INITIAL__") {
        setParams({
          email,
          password,
          loading: false,
        });
      }
    },
    params.loading,
  ];
};

/** --- AUTH_RESPONSES ---
 * UNEXPECTED_ERROR = "UNEXPECTED_ERROR"
 * CODE_EXPIRED = "ExpiredCodeException"
 * RESET_REQUIRED = "PasswordResetRequiredException"
 * CODE_MISMATCH = "CodeMismatchException"
 * TOO_MANY_TRY = "LimitExceededException"
 * USER_NOT_FOUND = "UserNotFoundException"
 * USER_NOT_AUTHORIZED = "NotAuthorizedException"
 * INVALID_PASSWORD = "InvalidPasswordException"
 * INVALID_PARAMETER = "InvalidParameterException"
 * NEW_PASSWORD_REQUIRED = "NEW_PASSWORD_REQUIRED"
 * NO_EMPTY = "EMPTY_STRING_NOT_ALLOWED"
 * EMAIL_REQUIRED = "STRING_MUST_BE_EMAIL"
 * PASSWORD_POLICY = "PASSWORD_MUST_FULFILL_POLICY"
 * PASSWORD_CONFIRM_MISMATCH = "PASSWORD_AND_REPEAT_MUST_BE_SAME"
 */

/**  __typename: AUTH_ERROR,
  {
    code: AuthEnums;
    name: string;
    message?: {
        [key: string]: string;
    };
  }
*/
