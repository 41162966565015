import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";

import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { useTheme } from "@mui/material/styles";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMediaQuery } from "@mui/material";

import {
  DialogComponent,
  LoadingIndicator,
  Alert,
  FadeIn,
  SecondaryDialog,
  CustomTooltip,
} from "../";

import { useUsers, useValidateEmail } from "../../hooks";

import TConfig from "../../../../../config";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";

const Content = ({ handleDialogClose }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { isEmail } = validator;

  const dispatch = useAppDispatch();
  const validateEmail = useValidateEmail();
  const { getUsers, createUser, deleteUser } = useUsers();

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const isMobile = useMediaQuery(`(max-width:${theme.breakpoints.values.md}px)`);

  // user roles are vsible only product_id is company
  const isCompany = TConfig.defaults.PRODUCT_ID === "company";

  // states for user_management
  const [users, setUsers] = useState([]);
  const [addNewUserForm, setAddNewUserForm] = useState(false);
  const [newUserEmail, setNewUserEmail] = useState("");
  const [newUserEmailValid, setNewUserEmailValid] = useState(true);
  const [emailExist, setEmailExist] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [alertState, setAlertState] = useState({
    show: false,
    type: "",
    message: "",
  });

  // to show/hide secondary dialog for user delete event
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

  // rolesObject to assign text from locales
  const rolesObject = t("top_bar.user_profile_dialog.user_roles", {
    returnObjects: true,
  });

  const contact = currentUser.customer.contact;
  const first_name = contact?.first_name ? contact.first_name : "";
  const last_name = contact?.last_name ? contact.last_name : "";

  const userName = `${first_name} ${last_name}`;

  // email validation
  const isValid = (email) => isEmail(email);

  useEffect(() => {
    getUsers().then(({ users }) => {
      const listedUsers = users.filter((user) => !user?.root && !user?.client_id);
      setUsers(listedUsers);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to hide alertState after 5 sec
  useEffect(() => {
    let timeOut;
    if (alertState.show) {
      timeOut = setTimeout(() => {
        setAlertState({
          ...alertState,
          show: false,
          type: "",
          message: "",
        });
      }, 5000);
    }

    return () => {
      if (timeOut) {
        clearTimeout(timeOut);
      }
    };
  }, [alertState]);

  const handleAddNewUserForm = () => {
    setAddNewUserForm(!addNewUserForm);
  };

  const handleAddNewUserChange = (e) => {
    if (emailExist) {
      setEmailExist(false);
    }
    setNewUserEmail(e.target.value);
  };

  // to delete user
  const handleDeleteUser = () => {
    if (userIdToDelete) {
      dispatch(setCurrentUser({ loading: true }));
      setDeleteUserDialogOpen(false);
      deleteUser(userIdToDelete).then(() => {
        if (TConfig.defaults.PRODUCT_ID === "platform") {
          window.location.reload();
        } else {
          setUsers((prevUsers) => prevUsers.filter((user) => user.user_id !== userIdToDelete));
          dispatch(setCurrentUser({ loading: false }));
          setAlertState({
            ...alertState,
            show: true,
            type: "SUCCESS",
            message: t("top_bar.user_management_dialog.delete_user_success"),
          });
          setUserIdToDelete(null);
        }
      });
    }
  };

  // to create a new User
  const handleSaveUser = () => {
    dispatch(setCurrentUser({ loading: true }));
    const customer_id = users[21]?.customer_id;

    // validate email whether a proper email it is
    const emailValid = isValid(newUserEmail);

    if (emailValid) {
      // validate email from DB
      validateEmail(newUserEmail).then((resp) => {
        if (resp) {
          createUser(customer_id, newUserEmail).then((resp) => {
            if (TConfig.defaults.PRODUCT_ID === "platform") {
              window.location.reload();
            } else {
              if (resp) {
                setUsers((prevUsers) => [...prevUsers, resp.user]);
              }
              setNewUserEmail("");
              dispatch(setCurrentUser({ loading: false }));
              setEmailExist(false);
              setAddNewUserForm(false);
              setAlertState({
                ...alertState,
                show: true,
                type: "SUCCESS",
                message: t("top_bar.user_management_dialog.change_success"),
              });
            }
          });
        } else {
          setEmailExist(true);
          dispatch(setCurrentUser({ loading: false }));
          setAlertState({
            ...alertState,
            show: true,
            type: "ERROR",
            message: t("top_bar.user_management_dialog.mail_exist"),
          });
        }
      });
    } else {
      setNewUserEmailValid(false);
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  // to show secondary dialog for user delete event
  const handleDeleteUserWarningDialogOpen = (userId) => {
    setDeleteUserDialogOpen(true);
    setUserIdToDelete(userId);
  };

  // to close secondary dialog for user delete event
  const handleDeleteUserDialogClose = () => {
    setDeleteUserDialogOpen(false);
    setUserIdToDelete(null);
  };

  return (
    <>
      {currentUser.loading && <LoadingIndicator type={"PROGRESS"} />}
      <DialogContent
        sx={{
          padding: "0 10px 10px",
          height: "100%",
          maxHeight: "55vh",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 20px",
          },
        }}
      >
        <Box>
          <Stack
            direction="row"
            sx={{
              borderBottom: `1px solid ${theme.palette.grey[400]}`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("top_bar.user_management_dialog.administrator")}
            </Typography>
          </Stack>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="body2" sx={{ width: "50%" }}>
              {userName}
            </Typography>
          </Stack>

          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              mt: 4,
              mb: 1,
              borderBottom: `1px solid ${theme.palette.grey[400]}`,
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
              {t("top_bar.user_management_dialog.title")}
            </Typography>
            <CustomTooltip title={t("misc.add")} placement="top" arrow>
              <IconButton color="primary" size="small" onClick={handleAddNewUserForm}>
                <PersonAddOutlinedIcon />
              </IconButton>
            </CustomTooltip>
          </Stack>

          {users && users.length === 0 && (
            <Stack>
              <Typography variant="body2">{t("top_bar.user_management_dialog.no_user")}</Typography>
            </Stack>
          )}

          {users && users.length >= 1 && (
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <Table sx={{ width: "100%" }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="left" sx={{ fontWeight: "bold", pl: 0 }}>
                      {t("top_bar.user_management_dialog.table.alias")}
                    </TableCell>
                    {isCompany && (
                      <TableCell
                        align="left"
                        sx={{
                          fontWeight: "bold",
                          pl: 0,
                        }}
                      >
                        {t("top_bar.user_management_dialog.table.role")}
                      </TableCell>
                    )}
                    <TableCell align="left" sx={{ fontWeight: "bold", pl: 0 }}>
                      {t("top_bar.user_management_dialog.table.first_name")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold", pl: 0 }}>
                      {t("top_bar.user_management_dialog.table.last_name")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold", pl: 0 }}>
                      {t("top_bar.user_management_dialog.table.phone")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold", pl: 0 }}>
                      {t("top_bar.user_management_dialog.table.email")}
                    </TableCell>
                    <TableCell align="left" sx={{ fontWeight: "bold", pl: 0 }}>
                      {" "}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {users.map((user) => {
                    const userRole = user.metadata?.find((item) => item.scope === "profile")?.data;
                    return (
                      <TableRow key={user.user_id} sx={{}}>
                        <TableCell align="left" sx={{ pl: 0 }}>
                          {user.alias}
                        </TableCell>
                        {isCompany && (
                          <TableCell align="left" sx={{ pl: 0 }}>
                            {userRole?.free_role_text
                              ? userRole?.free_role_text
                              : userRole?.role
                              ? rolesObject[userRole.role]
                              : "-"}
                          </TableCell>
                        )}
                        <TableCell align="left" sx={{ pl: 0 }}>
                          {user.first_name ? user.first_name : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ pl: 0 }}>
                          {user.last_name ? user.last_name : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ pl: 0 }}>
                          {user.telephone ? user.telephone : "-"}
                        </TableCell>
                        <TableCell align="left" sx={{ pl: 0 }}>
                          {user.email}
                        </TableCell>
                        <TableCell align="center" sx={{ pl: 0 }}>
                          <IconButton
                            aria-label="delete"
                            color="primary"
                            onClick={() => handleDeleteUserWarningDialogOpen(user.user_id)}
                          >
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Stack>
          )}

          {addNewUserForm && (
            <Stack sx={{ my: 2 }}>
              <TextField
                margin="dense"
                id="user_management_newUserEmail"
                type="email"
                size="small"
                label={t("top_bar.user_management_dialog.user_email")}
                value={newUserEmail}
                required
                autoFocus
                onChange={handleAddNewUserChange}
                error={emailExist || !newUserEmailValid}
                helperText={
                  emailExist
                    ? t("error.invalid_email")
                    : !newUserEmailValid
                    ? t("misc.required_field")
                    : null
                }
              />
            </Stack>
          )}
        </Box>
      </DialogContent>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={alertState.show ? "space-between" : "flex-end"}
        alignItems={isMobile ? "flex-end" : "center"}
        spacing={2}
        sx={{
          width: "100%",
          padding: "0 10px 10px",
          [theme.breakpoints.up("md")]: {
            padding: "0 24px 24px",
          },
        }}
      >
        <FadeIn>
          {alertState.show && <Alert type={alertState.type}>{alertState.message}</Alert>}
        </FadeIn>
        <DialogActions>
          <Button
            variant="outlined"
            color="primary"
            size={isMobile ? "small" : "medium"}
            sx={{
              mr: { sx: 1, md: 3 },
              fontWeight: "bold",
              color: theme.palette.primary.main,
            }}
            onClick={handleDialogClose}
          >
            {t("misc.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            size={isMobile ? "small" : "medium"}
            disabled={!isValid(newUserEmail) || !addNewUserForm || emailExist}
            sx={{
              px: { sx: 2, md: 4 },
              fontWeight: "bold",
              color: theme.palette.secondary.main,
            }}
            onClick={handleSaveUser}
          >
            {t("misc.save")}
          </Button>
        </DialogActions>
      </Stack>

      {/* Secondary Dialog for user delete confirmation */}
      <SecondaryDialog
        type="warning"
        dialogTitle={t("top_bar.user_management_dialog.warning_dialog_title")}
        contentText={t("top_bar.user_management_dialog.warning_dialog_text")}
        secondaryDialogOpen={deleteUserDialogOpen}
        secondaryDialogClose={handleDeleteUserDialogClose}
        eventHandler={handleDeleteUser}
        actionButtonText={t("misc.delete")}
      />
    </>
  );
};

export const UserManagementDialog = ({ dialogOpen, handleDialogClose }) => {
  const { t } = useTranslation();

  return (
    <DialogComponent
      dialogKey={"user-management-dialog"}
      title={t("top_bar.user_management_dialog.headline")}
      content={<Content handleDialogClose={handleDialogClose} />}
      dialogOpen={dialogOpen}
      handleDialogClose={handleDialogClose}
      maxWidth={"lg"}
    />
  );
};
