import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";

export const TableWrapperTwo = styled("table")(({ theme }) => ({
  borderCollapse: "collapse",
  width: "100%",

  "& caption": {
    textAlign: "left",
    fontWeight: "bold",
    padding: "0 5px 5px 0",
    fontSize: "1rem",
    color: theme.palette.info.dark,
  },
  "& th": {
    fontWeight: 600,
  },
  "&, th, td": {
    border: `1px solid ${theme.palette.grey[300]}`,
  },
  "& th, & td": {
    padding: "5px 10px",
    fontSize: "0.95rem",
  },
  "& td": {
    textAlign: "left",
  },
}));

export const StyledTextField = styled(TextField)(({ theme }) => ({
  "& .MuiInput-root": {
    "&:before, :after, :hover:not(.Mui-disabled):before": {
      border: 0,
    },
  },
  "& .MuiInputBase-input": {
    padding: "3px 0",
    fontSize: "15px",
    textAlign: "center",
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  "& .MuiInputBase-input": {
    padding: "0",
    fontSize: "15px",
  },
}));
