import { styled } from "@mui/material/styles";

const StyledPageLoader = styled("section")(() => {
  return {
    position: "absolute",
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "transparent",
  };
});

const StyledPageLoaderDot = styled("div")(({ dotColor = "#193C6C" }) => {
  return {
    width: "15px",
    height: "15px",
    margin: "0 3px",
    borderRadius: "50%",
    backgroundColor: dotColor,
    display: "inline-block",
    animation: "dotAnimation 1.5s infinite",
    animationDelay: "500ms",
    opacity: 0.5,
    "&:first-of-type": {
      opacity: 0,
      animationDelay: "0ms",
    },
    "&:last-of-type": {
      opacity: 0.7,
      animationDelay: "1s",
    },
    "@keyframes dotAnimation": {
      "0%": {
        opacity: 1,
      },
      "80%": {
        opacity: 0,
      },
    },
  };
});

export const PageLoader = ({ ...props }) => {
  return (
    <>
      <StyledPageLoader>
        <StyledPageLoaderDot {...props} />
        <StyledPageLoaderDot {...props} />
        <StyledPageLoaderDot {...props} />
      </StyledPageLoader>
    </>
  );
};
