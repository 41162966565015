// InvestorTablePopup.js or InvestorTablePopup.ts
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
// import { useDispatch } from 'react-redux';
// import { setCurrentUser } from '../../Store/currentUser/currentUserSlice';
import { useTranslation } from "react-i18next";
import { useTheme } from "@mui/material/styles";
import { findExistingInvite } from "./InviteInvestor"; // Import the extracted function
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import useInvite from "./InviteInvestor"; // Import the main hook
import { Box, Button, Modal, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { useInvestmentTeaser } from "../../hooks/useInvestmentTeaser";
import { setExistingInvite } from "../../Store/invites/inviteState";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  display: "flex",
  alignItems: "center",
  transform: "translate(-50%, -50%)",
  justifyContent: "space-between",
  width: 800,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: 1,
  padding: "15px",
};

const useCopyText = () => {
  const [copText, setCopText] = useState("");

  const handleCopy = () => {
    const textArea = document.createElement("textarea");
    textArea.value = copText;
    document.body.appendChild(textArea);
    textArea.select();
    textArea.focus();
    document.execCommand("copy");
    document.body.removeChild(textArea);
  };

  return { copText, setCopText, handleCopy };
};

export default function InvestorTablePopup({ investor, disabled }) {
  const { t } = useTranslation(["platform/common"]);
  const theme = useTheme();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { copText, setCopText, handleCopy } = useCopyText();
  const { InviteInvestor } = useInvite({ investor, setCopText, copText });
  const { transactionId } = useParams();
  const dispatch = useDispatch();
  const { getInvite } = useInvestmentTeaser();

  useEffect(() => {
    const checkExistingInvite = async () => {
      const existingInvite = await findExistingInvite(transactionId, investor.id, getInvite);
      if (existingInvite) {
        setExistingInvite(existingInvite);
        // console.log("Found existing invite:", existingInvite);
      } else {
        // console.log("No existing invite found");
      }
    };

    checkExistingInvite();
  }, [transactionId, investor.id, getInvite]);

  const handlePreview = async () => {
    window.open(`${window.location.origin}/investment-teaser/${transactionId}`, "_blank");
  };

  return (
    <>
      <ContentCopyIcon
        onClick={() => {
          if (!disabled) {
            handleOpen();
            InviteInvestor();
          }
        }}
        color={disabled ? "disabled" : "action"}
      />
      <>
        {copText && (
          <>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <TextField
                  type="text"
                  value={copText}
                  variant="outlined"
                  size="small"
                  style={{ margin: "0 8px", width: "68%" }}
                />
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    handleCopy();
                    handleClose();
                  }}
                  sx={{
                    marginRight: "8px",
                    px: 4,
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                  }}
                >
                  {t("misc.popup_copy_link")}
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                  sx={{
                    px: 4,
                    color: theme.palette.secondary.main,
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    handlePreview();
                    handleClose();
                  }}
                  component="span"
                >
                  {t("misc.preview")}
                </Button>
              </Box>
            </Modal>
          </>
        )}
      </>
    </>
  );
}
