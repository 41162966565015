import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import parsePhoneNumber from "libphonenumber-js";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import PersonAddOutlinedIcon from "@mui/icons-material/PersonAddOutlined";
import PersonRemoveOutlinedIcon from "@mui/icons-material/PersonRemoveOutlined";
import AutorenewOutlinedIcon from "@mui/icons-material/AutorenewOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import IconButton from "@mui/material/IconButton";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Typography from "@mui/material/Typography";
import { FormHelperText, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import { FadeIn, Alert, LoadingIndicator, SecondaryDialog, CustomTooltip } from "../";

import { Phone } from "../Phone/Phone";

import { isValidLengthString } from "../../common";
import { useValidateEmail, useUsers } from "../../hooks";

import { TableWrapper } from "./styled.userManagementInContact";
import { useAppSelector } from "../../Store/hooks/useAppSelector";
import { useAppDispatch } from "../../Store/hooks/useAppDispatch";
import { setCurrentUser } from "../../Store/currentUser/currentUserSlice";

export const UserManagementInContact = ({ product, customerId, clientId, transactionType }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const validateEmail = useValidateEmail();
  const { getCompanyUsers, getUsers, createClientUser, deleteUser, updateUser } = useUsers();

  const currentUser = useAppSelector(({ currentUser }) => currentUser);
  const dispatch = useAppDispatch();

  // state for user_profile
  // const [users, setUsers] = useState([]);
  const [userProfile, setUserProfile] = useState({
    user_id: "",
    first_name: "",
    last_name: "",
    telephone: "+49",
    email: "",
    role: "",
    free_role_text: "", // if the role selection is "Other"
    form_state: {
      initial: true,
      valid: false,
    },
  });
  const [customerUsers, setCustomerUsers] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [emailExistInDB, setEmailExistInDB] = useState(false);
  const [alertState, setAlertState] = useState({
    show: false,
    type: "",
    message: "",
  });
  const [loading, setLoading] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);

  // to show/hide secondary dialog for user delete event
  const [deleteUserDialogOpen, setDeleteUserDialogOpen] = useState(false);

  // rolesObject to assign text from locales
  const rolesObject = t("user_management_in_contact.user_roles", {
    returnObjects: true,
  });

  useEffect(() => {
    setLoading(true);
    if (product === "company") {
      getUsers()
        .then((response) => {
          const non_root_users = response.users.filter((user) => !user.root);
          setCustomerUsers(non_root_users);
          setLoading(false);
        })

        .catch(() => {
          setLoading(false);
        });
    } else {
      getCompanyUsers(customerId)
        .then((response) => {
          let listedUsers = [];

          if (transactionType === "company") {
            listedUsers = response.users.filter((user) => !user.root);
          } else if (transactionType === "advisor") {
            listedUsers = response.users.filter((user) => user?.client_id === clientId);
          }

          setCustomerUsers(listedUsers);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // to check whether user input fields are valid or not
  const isValidUserField = (name, initial = true) => {
    if (initial && userProfile.form_state.initial) return true;
    switch (name) {
      case "first_name":
      case "last_name":
      case "email":
      case "role":
      case "free_role_text":
        return isValidLengthString(userProfile[name], 1);
      case "telephone":
        return isValidLengthString(userProfile[name], 5);
      default:
        return false;
    }
  };

  // handle change event in input fields
  const handleUserDataChange = (e) => {
    const field = e.target.name;
    const value = e.target.value;

    setUserProfile({
      ...userProfile,
      [field]: value,
    });
  };

  // to validate phone number with libphonenumber-js
  const checkUserPhoneNumber = (value, initial = true) => {
    if (initial && userProfile.form_state.initial) return true;
    const phone = parsePhoneNumber(value);
    return phone.isValid();
  };

  // handle change event telephone
  const handleUserPhoneChange = (value) => {
    setUserProfile({
      ...userProfile,
      telephone: value,
    });
  };

  // handle create new User
  const handleSubmit = async (type) => {
    try {
      dispatch(setCurrentUser({ loading: true }));

      if (userProfile.form_state.initial) {
        setUserProfile({
          ...userProfile,
          form_state: {
            initial: false,
            valid: userProfile.form_state.valid,
          },
        });
      }

      const commonFieldsAreValid =
        isValidUserField("first_name", false) &&
        isValidUserField("last_name", false) &&
        isValidUserField("email", false) &&
        isValidUserField("role", false) &&
        isValidUserField("telephone", false);

      const otherRoleFieldIsValid = isValidUserField("free_role_text", false);

      const allFieldsAreValid =
        userProfile.role === "other"
          ? commonFieldsAreValid && otherRoleFieldIsValid
          : commonFieldsAreValid;

      if (!allFieldsAreValid) {
        dispatch(setCurrentUser({ loading: false }));
        return;
      }

      if (allFieldsAreValid !== userProfile.form_state.valid) {
        setUserProfile({
          ...userProfile,
          form_state: {
            initial: false,
            valid: allFieldsAreValid,
          },
        });
      }

      if (type === "create") {
        // validate email from DB
        const validateEmailResponse = await validateEmail(userProfile.email);

        // if email already in DB, then throw ERROR in UI
        if (!validateEmailResponse) {
          dispatch(setCurrentUser({ loading: false }));
          setAlertState({
            ...alertState,
            show: true,
            type: "ERROR",
            message: t("error.email_exist"),
          });
          setUserProfile({
            ...userProfile,
            form_state: {
              initial: true,
              valid: false,
            },
          });
          return;
        }
      }

      if (userProfile.telephone) {
        const phone = parsePhoneNumber(userProfile.telephone);
        const isValidPhoneNumber = phone.isValid();
        const formattedNumber = phone.formatInternational();

        if (isValidPhoneNumber) {
          setUserProfile({
            ...userProfile,
            telephone: formattedNumber,
          });
        } else {
          dispatch(
            setCurrentUser({
              loading: false,
            })
          );
          return;
        }
      }

      if (!emailExistInDB) {
        setAlertState({
          ...alertState,
          show: false,
          type: "",
          message: "",
        });

        // user information to update user in DB
        const objToCreate = {
          customer_id: customerId,
          client_id: clientId,
          email: userProfile.email,
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
          telephone: userProfile.telephone,
        };

        let createdUser;
        if (type === "create") {
          // create user
          createdUser = await createClientUser(objToCreate);
        }

        // user information to update user in DB
        const objToUpdate = {
          first_name: userProfile.first_name,
          last_name: userProfile.last_name,
          email: userProfile.email,
          telephone: userProfile.telephone,
          metadata: [
            {
              scope: "profile",
              data: {
                role: userProfile.role,
                free_role_text: userProfile.free_role_text,
              },
            },
          ],
        };

        // update user "profile" metadata
        const responseUpdate = await updateUser(
          type === "update" ? userProfile.user_id : createdUser.user.user_id,
          objToUpdate
        );

        if (responseUpdate) {
          // to reset userProfile state object
          setUserProfile({
            user_id: "",
            first_name: "",
            last_name: "",
            telephone: "+49",
            email: "",
            role: "",
            free_role_text: "",
            form_state: {
              initial: true,
              valid: false,
            },
          });

          if (product === "company") {
            const usersResponse = await getUsers();
            setCustomerUsers(usersResponse.users.filter((user) => !user.root));
          } else {
            const usersResponse = await getCompanyUsers(customerId);

            if (transactionType === "company") {
              setCustomerUsers(usersResponse.users.filter((user) => !user.root));
            } else if (transactionType === "advisor") {
              setCustomerUsers(usersResponse.users.filter((user) => user?.client_id === clientId));
            }
          }
        }

        dispatch(setCurrentUser({ loading: false }));
      }
    } catch (err) {
      dispatch(setCurrentUser({ loading: false }));
    }
  };

  // handle delete User
  const handleDeleteUser = () => {
    if (userIdToDelete) {
      dispatch(setCurrentUser({ loading: true }));
      setDeleteUserDialogOpen(false);

      deleteUser(userIdToDelete).then(() => {
        setCustomerUsers((prevUsers) =>
          prevUsers.filter((user) => user.user_id !== userIdToDelete)
        );
        dispatch(setCurrentUser({ loading: false }));
        setUserIdToDelete(null);
      });
    }
  };

  // to show secondary dialog for user delete event
  const handleDeleteUserWarningDialogOpen = (userId) => {
    setDeleteUserDialogOpen(true);
    setUserIdToDelete(userId);
  };

  const handleEditUser = (userId) => {
    const foundUser = customerUsers.find((user) => user.user_id === userId);

    if (foundUser) {
      const { user_id, first_name, last_name, email, telephone } = foundUser;
      const profile =
        foundUser?.metadata && foundUser.metadata.find((data) => data.scope === "profile");

      //userProfile.free_role_text
      setUserProfile({
        user_id,
        first_name,
        last_name,
        telephone,
        email,
        role: profile ? profile.data.role : "",
        free_role_text: profile?.data?.free_role_text ? profile?.data?.free_role_text : "",
        form_state: {
          initial: true,
          valid: true,
        },
      });
    }
  };

  // to close secondary dialog for user delete event
  const handleDeleteUserDialogClose = () => {
    setDeleteUserDialogOpen(false);
    setUserIdToDelete(null);
  };

  // to get the user role from "profile" scope in user metadata
  const getUserRole = (metadata) => {
    const profileScope = metadata?.find((item) => item.scope === "profile");
    if (profileScope) {
      const role = profileScope.data.role;
      const free_role_text = profileScope.data.free_role_text;
      return [role, free_role_text];
    } else {
      return ["", ""];
    }
  };

  return (
    <>
      {loading && <LoadingIndicator type={"COMPONENT"} />}
      {!loading && (
        <Stack direction="column">
          <Typography
            variant="subtitle1"
            sx={{
              color: theme.palette.info.dark,
              fontWeight: "bold",
              mb: 1,
            }}
          >
            {t("user_management_in_contact.create_user.headline")}
          </Typography>
          <Stack
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
            alignItems={{ xs: "stretch", md: "flex-start" }}
            spacing={{ xs: 2, md: 1 }}
            sx={{}}
          >
            <FormControl size="small" sx={{ minWidth: "15%" }}>
              <InputLabel id="role" required>
                {t("user_management_in_contact.create_user.role")}
              </InputLabel>
              <Select
                labelId="role"
                id="user_management_in_contact_role"
                name="role"
                value={userProfile.role || ""}
                label={t("user_management_in_contact.create_user.role")}
                onChange={handleUserDataChange}
                error={!isValidUserField("role")}
              >
                {currentUser?.datastore?.userRoles?.map((option, i) => (
                  <MenuItem key={i} value={option.value}>
                    {rolesObject[option.value]}
                  </MenuItem>
                ))}
              </Select>
              {!isValidUserField("role") && (
                <FormHelperText>{t("misc.required_field")}</FormHelperText>
              )}
            </FormControl>

            {userProfile.role === "other" && (
              <TextField
                label={t("user_management_in_contact.create_user.other_role")}
                name="free_role_text"
                id="user_management_in_contact_free_role_text"
                value={userProfile.free_role_text || ""}
                size="small"
                required
                onChange={handleUserDataChange}
                error={!isValidUserField("free_role_text")}
                helperText={!isValidUserField("free_role_text") && t("misc.required_field")}
                inputProps={{
                  autoComplete: "new-free_role_text",
                  form: {
                    autoComplete: "off",
                  },
                }}
                sx={{ minWidth: "15%" }}
              />
            )}
            <TextField
              label={t("user_management_in_contact.create_user.first_name")}
              name="first_name"
              id="user_management_in_contact_first_name"
              value={userProfile.first_name || ""}
              size="small"
              required
              onChange={handleUserDataChange}
              error={!isValidUserField("first_name")}
              helperText={!isValidUserField("first_name") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-first_name",
                form: {
                  autoComplete: "off",
                },
              }}
              sx={{ minWidth: "15%" }}
            />

            <TextField
              label={t("user_management_in_contact.create_user.last_name")}
              name="last_name"
              id="user_management_in_contact_last_name"
              value={userProfile.last_name || ""}
              size="small"
              required
              onChange={handleUserDataChange}
              error={!isValidUserField("last_name")}
              helperText={!isValidUserField("last_name") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-last_name",
                form: {
                  autoComplete: "off",
                },
              }}
              sx={{ minWidth: "15%" }}
            />
            <Box sx={{ width: "80%" }}>
              <Phone
                telephone={userProfile.telephone || "+49"}
                label={t("user_management_in_contact.create_user.phone")}
                phoneChange={handleUserPhoneChange}
                mobile={true}
                error={
                  !isValidUserField("telephone") || !checkUserPhoneNumber(userProfile.telephone)
                }
                errorText={
                  !isValidUserField("telephone")
                    ? t("misc.required_field")
                    : !checkUserPhoneNumber(userProfile.telephone)
                    ? t("error.invalid_phoneNumber")
                    : ""
                }
                registering={false}
                idLabel={"user_management_in_contact"}
              />
            </Box>

            <TextField
              label={t("user_management_in_contact.create_user.email")}
              name="email"
              id="user_management_in_contact_email"
              type="email"
              value={userProfile.email || ""}
              size="small"
              required
              disabled={!!userProfile.user_id}
              onChange={handleUserDataChange}
              error={!isValidUserField("email")}
              helperText={!isValidUserField("email") && t("misc.required_field")}
              inputProps={{
                autoComplete: "new-email",
                form: {
                  autoComplete: "off",
                },
              }}
              sx={{ minWidth: "15%", width: "100%" }}
            />
            <Box
              sx={{
                border: `1px solid ${theme.palette.grey[400]}`,
                height: "40px",
                width: "40px",
                borderRadius: "4px",
                "&:hover": {
                  borderColor: theme.palette.primary.main,
                },
              }}
            >
              <CustomTooltip
                title={userProfile.user_id ? t("misc.edit") : t("misc.add")}
                placement="top"
                arrow
              >
                <IconButton
                  color="primary"
                  size="small"
                  onClick={() => handleSubmit(userProfile.user_id ? "update" : "create")}
                >
                  {userProfile.user_id ? <AutorenewOutlinedIcon /> : <PersonAddOutlinedIcon />}
                </IconButton>
              </CustomTooltip>
            </Box>
          </Stack>

          {alertState.show && (
            <Stack sx={{ mt: 2 }}>
              <FadeIn>
                <Alert type={alertState.type}>{alertState.message}</Alert>
              </FadeIn>
            </Stack>
          )}

          <Stack direction={{ xs: "column", md: "row" }} sx={{ mt: 3 }}>
            <div style={{ width: "100%", overflowX: "auto" }}>
              <TableWrapper>
                <caption>{t("user_management_in_contact.list_users.headline")}</caption>
                <thead>
                  <tr>
                    <th>{t("user_management_in_contact.list_users.role")}</th>
                    <th>{t("user_management_in_contact.list_users.first_name")}</th>
                    <th>{t("user_management_in_contact.list_users.last_name")}</th>
                    <th>{t("user_management_in_contact.list_users.phone")}</th>
                    <th>{t("user_management_in_contact.list_users.email")}</th>
                    <th>{t("user_management_in_contact.list_users.action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {customerUsers.length > 0 &&
                    customerUsers.map(
                      ({ user_id, first_name, last_name, email, telephone, metadata }, ind) => {
                        return (
                          <tr key={ind}>
                            <td style={{ width: "15%" }}>
                              {getUserRole(metadata)[0] === "other"
                                ? getUserRole(metadata)[1]
                                : rolesObject[getUserRole(metadata)[0]]}
                            </td>
                            <td style={{ width: "15.7%" }}>{first_name}</td>
                            <td style={{ width: "15.5%" }}>{last_name}</td>
                            <td style={{ width: "22.7%" }}>{telephone}</td>
                            <td style={{ width: "24%" }}>{email}</td>
                            <td
                              style={{
                                textAlign: "center",
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <CustomTooltip
                                title={t("transaction_tables.deal_documents.delete_file")}
                                placement="top"
                                arrow
                              >
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleDeleteUserWarningDialogOpen(user_id)}
                                >
                                  <PersonRemoveOutlinedIcon />
                                </IconButton>
                              </CustomTooltip>
                              <CustomTooltip title={t("misc.edit")} placement="top" arrow>
                                <IconButton
                                  color="primary"
                                  size="small"
                                  onClick={() => handleEditUser(user_id)}
                                >
                                  <EditOutlinedIcon />
                                </IconButton>
                              </CustomTooltip>
                            </td>
                          </tr>
                        );
                      }
                    )}
                  {customerUsers.length === 0 && (
                    <tr>
                      <td style={{ textAlign: "left" }} colSpan={6}>
                        {t("user_management_in_contact.no_user")}
                      </td>
                    </tr>
                  )}
                </tbody>
              </TableWrapper>
            </div>
          </Stack>
        </Stack>
      )}

      {/* Secondary Dialog for user delete confirmation */}
      <SecondaryDialog
        type="warning"
        dialogTitle={t("top_bar.user_management_dialog.warning_dialog_title")}
        contentText={t("top_bar.user_management_dialog.warning_dialog_text")}
        secondaryDialogOpen={deleteUserDialogOpen}
        secondaryDialogClose={handleDeleteUserDialogClose}
        eventHandler={handleDeleteUser}
        actionButtonText={t("misc.delete")}
      />
    </>
  );
};
