import { Category, CategoryTree } from "../../Store/api";
import { CategoryOption } from "./interfaces";

export const getCategoryOptions = (
  categories: Category[] | undefined,
  categoryIds: string[]
): CategoryOption[] => {
  if (!categories) return [];

  return categories
    ?.map((category) => ({ value: category.value, label: category.summary }))
    .filter((category) => !categoryIds.includes(category.value))
    .filter((category) => category.value.startsWith("ICAT"));
};

export const getCategoryIds = (categoryValues: string[], categories: Category[]) => {
  return categoryValues.map((value) => {
    return getCategoryIdByValue(value, categories);
  });
};

export const getCategoryIdByValue = (value: string, categories: Category[]) => {
  const category = categories.find((category) => {
    return category.summary === value;
  });
  return category;
};

export const getCategoryValues = (
  categoryIds: string[],
  categories: Category[]
): CategoryOption[] => {
  return categoryIds.map((categoryId) => {
    return { value: categoryId, label: getCategoryNameByValue(categoryId, categories) };
  });
};

export const getCategoryNameByValue = (value: string, categories: Category[]): string => {
  const name = categories.find((category) => category.value === value)?.summary;
  return name ? name : "";
};

export const hasValidData = (categories: unknown[]): boolean => {
  let isValid = true;

  if (!categories) {
    return false;
  }

  categories.forEach((category: any) => {
    if (typeof category !== "string" && isValid) {
      isValid = false;
    }
  });
  return isValid;
};

export const getSubCategoryOptions = (
  categoryIds: string[],
  subCategoryIds: string[],
  categoriesTree: CategoryTree[],
  categories: Category[]
): CategoryOption[] => {
  const subCategoryOptions: CategoryOption[] = [];
  categoryIds.forEach((categoryId) => {
    categoriesTree.forEach((root) => {
      if (categoryId === root.value) {
        if (root.tree) {
          root.tree?.forEach((indLevel) => {
            if (indLevel.tree) {
              indLevel?.tree.forEach((desLevel) => {
                if (!subCategoryIds.includes(desLevel.value)) {
                  subCategoryOptions.push({
                    value: desLevel.value,
                    label: getCategoryNameByValue(desLevel.value, categories),
                    cat: getCategoryNameByValue(root.value, categories),
                  });
                }
              });
            } else {
              if (!subCategoryIds.includes(indLevel.value)) {
                subCategoryOptions.push({
                  value: indLevel.value,
                  label: getCategoryNameByValue(indLevel.value, categories),
                  cat: getCategoryNameByValue(root.value, categories),
                });
              }
            }
          });
        }
      }
    });
  });

  return subCategoryOptions.filter((category) => !categoryIds.includes(category.value));
};

export const removeAllChildSubCategoriesWithoutSelectedRootCategory = (
  categoryIds: string[],
  subCategoryIds: string[],
  categoriesTree: CategoryTree[]
): string[] => {
  const updatedSubCategories: string[] = [];

  categoryIds.forEach((categoryId) => {
    categoriesTree.forEach((root) => {
      if (categoryId === root.value) {
        if (root.tree) {
          root.tree?.forEach((indLevel) => {
            if (indLevel.tree) {
              indLevel?.tree.forEach((desLevel) => {
                if (subCategoryIds.includes(desLevel.value)) {
                  updatedSubCategories.push(desLevel.value);
                }
              });
            } else {
              if (subCategoryIds.includes(indLevel.value)) {
                updatedSubCategories.push(indLevel.value);
              }
            }
          });
        }
      }
    });
  });
  return updatedSubCategories;
};
